import config from '@/api/config';
import EventSource from 'eventsource';
import { joinToString } from '@/utils/helpers';
import { keycloak } from '@/AxiosClient';

const client = config.client;
const baseUrl = config.baseUrl;

export default {
  checkName(name) {
    const params = new URLSearchParams();
    params.append('name', name);

    return client
      .get(`${baseUrl}/test-decks/check-name`, { params })
      .then((res) => res.data);
  },
  subscribe(requestId, eventHandler, errorHandler) {
    let es = new EventSource(
      `${config.apiOriginUrl}/import/${requestId}/subscribe`,
      { headers: { Authorization: `Bearer ${keycloak.token}` } },
    );
    es.addEventListener(
      'file-import',
      (e) => {
        let event = JSON.parse(e.data);
        eventHandler(event);
      },
      false,
    );
    es.addEventListener('error', (e) => {
      errorHandler(e);
    });

    return es;
  },
  addTestDeck(taxEngineType, name, file, description = null) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('taxEngineType', taxEngineType);
    formData.append('file', file);
    if (!!description && description.length > 0) {
      formData.append('description', description.trim());
    }

    return client
      .post(`${baseUrl}/import/test-deck`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => data);
  },
  getAllRecentDecks() {
    return client.get(`${baseUrl}/test-decks/recent`).then((res) => res.data);
  },
  getAllTestDecks() {
    return client.get(`${baseUrl}/test-decks`).then((res) => res.data);
  },
  delete(id) {
    return client.delete(`${baseUrl}/test-decks/${id}`).then(() => true);
  },
  getTestDeck(id) {
    return client.get(`${baseUrl}/test-decks/${id}`).then((res) => res.data);
  },
  getTestDeckDetails(id, queryArgs = {}) {
    queryArgs.shipTo = joinToString(queryArgs?.shipTo);
    queryArgs.shipFrom = joinToString(queryArgs?.shipFrom);
    queryArgs.transactionType = joinToString(queryArgs?.transactionType);
    queryArgs.companyRole = joinToString(queryArgs?.companyRole);
    queryArgs.documentType = joinToString(queryArgs?.documentType);
    queryArgs.currencyCode = joinToString(queryArgs?.currencyCode);

    return client
      .get(`${baseUrl}/test-decks/${id}/details`, {
        params: {
          ...queryArgs,
        },
      })
      .then((res) => res.data);
  },
};
