import _ from 'lodash';
const md5 = require('./md5');
export { md5 };
function innerGetValueByPath(obj, path) {
  if (!obj) {
    return null;
  }
  return path.split('.').reduce((o, i) => o[i], obj);
}

/**
 * Get value of an object property/path even if it's nested
 */
export function getValueByPath(obj, path) {
  const pathParts = path.split('.');
  let key = '';
  let value = null;
  for (var i = 0; i < pathParts.length; i++) {
    key += `${i !== 0 ? '.' : ''}${pathParts[i]}`;
    value = innerGetValueByPath(obj, key);
    if (!value) {
      return null;
    }
  }

  return value;
}

/**
 * Extension of indexOf method by equality function if specified
 */
export function indexOf(array, obj, fn) {
  if (!array) return -1;

  if (!fn || typeof fn !== 'function') return array.indexOf(obj);

  for (let i = 0; i < array.length; i++) {
    if (fn(array[i], obj)) {
      return i;
    }
  }

  return -1;
}

/**
 * Mobile detection
 * https://www.abeautifulsite.net/detecting-mobile-devices-with-javascript
 */
export const isMobile = {
  Android: function () {
    return (
      typeof window !== 'undefined' &&
      window.navigator.userAgent.match(/Android/i)
    );
  },
  BlackBerry: function () {
    return (
      typeof window !== 'undefined' &&
      window.navigator.userAgent.match(/BlackBerry/i)
    );
  },
  iOS: function () {
    return (
      typeof window !== 'undefined' &&
      window.navigator.userAgent.match(/iPhone|iPad|iPod/i)
    );
  },
  Opera: function () {
    return (
      typeof window !== 'undefined' &&
      window.navigator.userAgent.match(/Opera Mini/i)
    );
  },
  Windows: function () {
    return (
      typeof window !== 'undefined' &&
      window.navigator.userAgent.match(/IEMobile/i)
    );
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

export function removeElement(el) {
  if (typeof el.remove !== 'undefined') {
    el.remove();
  } else {
    el.parentNode.removeChild(el);
  }
}

/**
 * Escape regex characters
 * http://stackoverflow.com/a/6969486
 */
export function escapeRegExpChars(value) {
  if (!value) return value;

  // eslint-disable-next-line
  return value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

export function isEmptyDateFilter(value) {
  if (!value || value === null) {
    return true;
  }

  if (typeof value === 'object') {
    return !value.startDate || !value.endDate;
  } else {
    return false;
  }
}

export function isEmptySelectValue(val) {
  return val === undefined || val === null || val === 'any' || val === '';
}

/**
 * Get the first item that pass the test
 * by second argument function
 *
 * @param {Array} list
 * @param {Function} f
 * @return {*}
 */
export function find(list, f) {
  return list.filter(f)[0];
}

/**
 * Deep copy the given object considering circular structure.
 * This function caches all nested objects and its copies.
 * If it detects circular structure, use cached copy to avoid infinite loop.
 *
 * @param {*} obj
 * @param {Array<Object>} cache
 * @return {*}
 */
export function deepCopy(obj, cache = []) {
  // just return if obj is immutable value
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  // if obj is hit, it is in circular structure
  const hit = find(cache, (c) => c.original === obj);
  if (hit) {
    return hit.copy;
  }

  const copy = Array.isArray(obj) ? [] : {};
  // put the copy into cache at first
  // because we want to refer it in recursive deepCopy
  cache.push({
    original: obj,
    copy,
  });

  Object.keys(obj).forEach((key) => {
    copy[key] = deepCopy(obj[key], cache);
  });

  return copy;
}

/**
 *
 * @param {Object} obj
 * @param {Function} fn
 */
export function forEachValue(obj, fn) {
  Object.keys(obj).forEach((key) => fn(obj[key], key));
}

/**
 *
 * @param {*} obj
 * @returns {Boolean}
 */
export function isObject(obj) {
  return obj !== null && typeof obj === 'object';
}

/**
 *
 * @param {*} val
 * @returns {Boolean}
 */
export function isPromise(val) {
  return val && typeof val.then === 'function';
}

/**
 *
 * @param {Boolean} condition
 * @param {String} msg
 * @throws {Error}
 */
export function assert(condition, msg) {
  if (!condition) throw new Error(`[vuex] ${msg}`);
}

/**
 *
 * @param {String} target
 * @param {String} search
 * @param {String} replacement
 * @returns {String}
 */
export function replaceAll(target, search, replacement) {
  if (!target || !target.length) {
    return target;
  }
  return target.replace(
    new RegExp(escapeRegExpChars(search), 'g'),
    replacement,
  );
}

/**
 *
 * @param {String} target
 * @returns {String}
 */
export function titleCase(target, force = false) {
  if (_.isNil(target) || !_.isString(target)) {
    return target;
  } else {
    if (force) {
      return _.startCase(target.toLowerCase());
    } else {
      return _.startCase(target);
    }
  }
}

/**
 *
 * @returns {String}
 */
export function guidGenerator() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  );
}

/**
 *
 * @param {String} name
 * @returns {String}
 */
export function getEnv(name) {
  var value = window?.configs?.[name];
  if (value || value === false) {
    return value;
  }

  return process.env[name];
}

/**
 *
 * @param {String} name
 * @param {Boolean} defaultValue
 * @returns {Boolean}
 */
export function getEnvBoolean(name, defaultValue) {
  var value = getEnv(name) || defaultValue;
  return value === true || value === 1 || value === 'true';
}

export function isBlank(value) {
  if (value === undefined || value === null || value === '') {
    return true;
  }

  if (_.isString(value)) {
    let cleaned = _.trim(`${value}`);
    return !cleaned || cleaned === '';
  }

  if (_.isArrayLike(value) || _.isObjectLike(value)) {
    return _.isEmpty(value);
  }

  return false;
}

export function isLastLetterUpperCased(word) {
  const lastLetter = `${word}`.slice(-1);
  const upperCased = _.upperCase(`${lastLetter}`);

  return upperCased === lastLetter;
}

export function isAlreadyPlural(word) {
  const lc = _.lowerCase(`${word}`);
  if (!lc.endsWith('s')) {
    return false;
  }

  return lc.endsWith('ies') || lc.endsWith('ses');
}

export function capitalize(word) {
  return _.upperFirst(word);
}

export function pluralize(word, force = false) {
  if (isBlank(word)) {
    return word;
  }

  if (force || !isAlreadyPlural(word)) {
    const isUpper = isLastLetterUpperCased(word);
    const lowercased = _.lowerCase(word);
    if (lowercased.endsWith('y')) {
      return `${word.slice(0, -1)}${isUpper ? 'IES' : 'ies'}`;
    } else if (lowercased.endsWith('s')) {
      return `${word}${isUpper ? 'ES' : 'es'}`;
    } else {
      return `${word}${isUpper ? 'S' : 's'}`;
    }
  } else {
    return word;
  }
}

export function isEmpty(value) {
  if (!value) {
    return true;
  }

  if (_.isString(value)) {
    return _.isBlank(value);
  }

  if (_.isArrayLike(value)) {
    return value.length > 0;
  }

  return false;
}

export function joinToString(value) {
  if (typeof value === undefined) {
    return undefined;
  }

  if (!value && value !== false) {
    return null;
  }

  let array = value;
  if (!Array.isArray(value)) {
    array = [value];
  }

  return array
    .filter((v) => !!v)
    .reduce((acc, str) => {
      if (!acc || acc.size === 0) {
        return str;
      } else {
        return `${acc},${str}`;
      }
    }, null);
}
