<template>
  <MSelect
    :id="id"
    :placeholder="placeholder"
    :label="label"
    filterable
    :clearable="true"
    :loading="loading"
    :options="divisionOptions"
    :value.sync="selectedValue"
    :invalid-feedback="invalidFeedback"
    :is-valid="isValid"
    :required="required"
    :readonly="readonly"
    :disabled="disabled"
  />
</template>
<script>
import api from '@/api';

export default {
  name: 'MDivisionRegistrationPicker',
  inheritAttrs: false,
  props: {
    country: {
      type: String,
      required: false,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    invalidFeedback: {
      type: String,
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    isValid: {
      type: Boolean,
      required: false,
      default: null,
    },
    value: {
      type: [String, Object],
      required: false,
      default() {
        return null;
      },
    },
    // When true, show the ISO code before the display name.
    showCode: {
      type: Boolean,
      required: false,
      default: false,
    },
    // When true, only return the geo key instead of the whole object.
    keyOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      default: 'Main Division',
    },
    placeholder: {
      type: String,
      default: 'Select a Main Division',
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      internalValue: this.getGeo(this.value),
      internalDivisions: [],
    };
  },
  computed: {
    divisionOptions() {
      return (this.internalDivisions || []).map((o) => {
        return Object.freeze({
          value: o,
          label: this.makeLabel(o),
        });
      });
    },
    selectedValue: {
      get() {
        return this.internalValue;
      },
      set(v) {
        if (
          !(
            this.loading ||
            !this.internalDivisions ||
            this.internalDivisions.length === 0
          )
        ) {
          if (!v) {
            this.internalValue = null;
            this.$emit('update:value', null);
          } else {
            this.internalValue = v;
            let emitValue = this.keyOnly ? v.key : v;
            this.$emit('update:value', emitValue);
          }
        }
      },
    },
  },
  watch: {
    value(v) {
      this.internalValue = this.getGeo(v);
    },
    country(v) {
      if (!v) {
        this.internalDivisions = [];
      } else {
        this.fetchDivisions(v).then((geos) => {
          if (this.value) {
            this.internalValue = this.getGeo(this.value, geos);
          }
        });
      }
    },
    options(v) {
      if (!v || v.length < 1) {
        this.internalDivisions = [];
        this.internalValue = null;
      } else {
        this.internalDivisions = (v || []).map((o) => {
          return Object.freeze(o);
        });

        if (this.value) {
          this.$nextTick(() => {
            this.internalValue = this.getGeo(
              this.value,
              this.internalDivisions,
            );
          });
        }
      }
    },
  },
  mounted() {
    if (this.country) {
      const geos = this.fetchDivisions(this.country);
      if (this.value && !this.internalValue) {
        this.getGeo(this.value, geos);
      }
    } else {
      if (this.value && !this.internalValue) {
        this.internalValue = this.getGeo(this.value);
      }
    }
  },
  beforeMount() {},
  methods: {
    fetchDivisions(countryKey) {
      if (this.options) {
        const frozen = (this.options || []).map((o) => {
          return Object.freeze(o);
        });
        this.internalDivisions = frozen;

        return Promise.resolve(frozen);
      }

      const self = this;
      self.loading = true;
      return api.registrations
        .getSupportedDivisions(countryKey)
        .then((res) => {
          self.internalDivisions = (res || []).map((o) => {
            return Object.freeze(o);
          });
        })
        .finally(() => {
          self.$nextTick(() => {
            self.loading = false;
          });
        });
    },
    getGeo(v, options = null) {
      if (!v) {
        return null;
      }
      const geoKey = this.getGeoKey(v);

      if (!geoKey) {
        return null;
      }

      let entries = !options ? this.divisionOptions : options;
      return (entries || []).find((c) => {
        return (
          c.id === geoKey || c.key === geoKey || c.value?.geoKey === geoKey
        );
      });
    },
    getGeoKey(v) {
      return this.$entities.idOrKey(v);
    },
    makeLabel(v) {
      if (this.showCode) {
        return `${v.primaryCode} - ${v.displayName}`;
      } else {
        return v.displayName;
      }
    },
  },
};
</script>
