<template>
  <CRow v-if="horizontal">
    <CCol :col="`${leftCols}`">
      <MCountryPicker
        :value.sync="countryValue"
        :show-code="showCode"
        :key-only="keyOnly"
      />
    </CCol>
    <CCol :col="`${rightCols}`">
      <MCountrySubPicker
        :country="countryValue"
        :value.sync="childValue"
        :show-code="showCode"
        :key-only="keyOnly"
      />
    </CCol>
  </CRow>
  <div v-else>
    <CRow>
      <CCol>
        <MCountryPicker
          class="pt-2"
          :value.sync="countryValue"
          :show-code="showCode"
          :key-only="keyOnly"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <MCountrySubPicker
          class="pt-2"
          :country="countryValue"
          :value.sync="childValue"
          :show-code="showCode"
          :key-only="keyOnly"
        />
      </CCol>
    </CRow>
  </div>
</template>
<script>
import MCountryPicker from './MCountryPicker';
import MCountrySubPicker from './MCountrySubPicker';
export default {
  name: 'MGeoPicker',
  components: {
    MCountryPicker,
    MCountrySubPicker,
  },
  props: {
    value: {
      type: [String, Object],
      required: false,
      default() {
        return null;
      },
    },
    // When true, show the ISO code before the display name.
    showCode: {
      type: Boolean,
      required: false,
      default: false,
    },
    // When true, only return the geo key instead of the whole object.
    keyOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    leftCols: {
      type: Number,
      required: false,
      default: 6,
    },
    rightCols: {
      type: Number,
      required: false,
      default: 6,
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      selectedCountryValue: this.getCountryGeoKey(this.value),
      selectedChildValue: this.getChildGeoKey(this.value),
      internalValue: this.getGeoKey(this.value),
    };
  },
  computed: {
    countryValue: {
      get() {
        return this.selectedCountryValue;
      },
      set(v) {
        this.selectedCountryValue = v;
        this.selectedChildValue = null;
        this.selectedChildValue = null;
        this.internalValue = v;
        this.$emit('update:country', v);
        this.$emit('update:value', v);
      },
    },
    childValue: {
      get() {
        return this.selectedChildValue;
      },
      set(v) {
        this.selectedChildValue = v;
        this.$emit('update:territory', v);
        if (v) {
          this.internalValue = v;
          this.$emit('update:value', v);
        } else {
          this.internalValue = this.selectedCountryValue;
          if (this.selectedCountryValue) {
            this.$emit('update:value', this.selectedCountryValue);
          } else {
            this.$emit('update:value', null);
          }
        }
      },
    },
  },
  watch: {
    value(v) {
      if (null == v) {
        this.internalValue = null;
        this.selectedCountryValue = null;
        this.selectedChildValue = null;
      } else {
        this.internalValue = v;
        this.selectedCountryValue = this.getCountryGeoKey(v);
        this.selectedChildValue = this.getChildGeoKey(v);
      }
    },
  },
  methods: {
    getGeoKey(v) {
      if (!v) {
        return null;
      }

      return v.key;
    },
    getCountryGeoKey(v) {
      if (!v) {
        return null;
      }

      if (this.$_.isString(v)) {
        const parts = v.split('-');
        return parts[0];
      } else {
        if (v.geoType === 'COUNTRY') {
          return v.key;
        } else if (v.parentGeoType === 'COUNTRY') {
          return v.parentKey;
        }
      }

      return null;
    },
    getChildGeoKey(v) {
      if (!v) {
        return null;
      }

      if (this.$_.isString(v)) {
        const parts = v.split('-');
        if (parts.length > 1) {
          return v;
        }
      } else if (v.geoType !== 'COUNTRY') {
        return v.key;
      }

      return null;
    },
  },
};
</script>
