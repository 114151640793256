<script>
import { mapGetters } from 'vuex';
import eventBus from '../utils/eventBus';
import store from '../store';

const HELP_CONFIG = store.getters['auth/uiConfig']?.menu?.help?.help;

export default {
  name: 'FreshDesk',
  computed: {
    ...mapGetters('auth', ['user']),
  },
  mounted() {
    if (HELP_CONFIG && HELP_CONFIG?.widgetID) {
      const widgetConfig = document.createElement('script');
      widgetConfig.innerText =
        "window.fwSettings={'widget_id':" +
        HELP_CONFIG.widgetID +
        '};' +
        '!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()';
      document.body.appendChild(widgetConfig);

      const widgetScript = document.createElement('script');
      widgetScript.src =
        'https://widget.freshworks.com/widgets/' + HELP_CONFIG.widgetID + '.js';
      widgetScript.async = true;
      widgetScript.defer = true;
      document.body.appendChild(widgetScript);

      window.FreshworksWidget('identify', 'ticketForm', {
        name: this.user.fullName,
        email: this.user.email,
        custom_fields: {
          cf_customer_reporter_time_zone:
            Intl.DateTimeFormat().resolvedOptions().timeZone,
          cf_company_name: this.user.tenantName,
        },
      });

      window.FreshworksWidget('disable', 'ticketForm', [
        'name',
        'email',
        'custom_fields.cf_customer_reporter_time_zone',
        'custom_fields.cf_company_name',
      ]);
      window.FreshworksWidget('hide', 'ticketForm', [
        'name',
        'custom_fields.cf_customer_reporter_time_zone',
        'custom_fields.cf_company_name',
      ]);
      window.FreshworksWidget('hide', 'launcher');

      eventBus.$on('freshDesk.close', this.close);
      eventBus.$on('freshDesk.open', this.open);
    }
  },
  beforeDestroy() {
    eventBus.$off('freshDesk.close');
    eventBus.$off('freshDesk.open');
  },
  methods: {
    close() {
      window.FreshworksWidget('close');
    },
    open() {
      window.FreshworksWidget('open');
    },
  },
  render() {
    return null;
  },
};
</script>
