<template>
  <div :class="wrapperClasses">
    <div class="step-header">
      <slot name="nav">
        <CProgress class="mb-1" color="info" :value="progress" />
        <ul class="step-pills">
          <li
            v-for="(tab, index) in unhiddenTabs"
            :key="`tab-${tab.index}`"
            class="step-item"
            :class="{
              active: tab.isActive,
              validated: tab.isValidated,
              disabled: tab.disabled,
              invalid: tab.isNotValid,
              valid: tab.isValid,
            }"
            @click.prevent.stop="selectTab(tab.index)"
          >
            <a
              v-if="
                !tab.disabled && !tab.isActive && tab.index <= activeTabIndex
              "
              class="step-link"
              href="#"
            >
              <span class="tab-status">{{ index + 1 }}</span>
              <span class="tab-label">{{ tab.title }}</span>
            </a>
            <div v-else class="step-link">
              <span class="tab-status">{{ index + 1 }}</span>
              <span class="tab-label">{{ tab.title }}</span>
            </div>
          </li>
        </ul>
      </slot>
    </div>
    <div class="step-body">
      <component :is="'div'">
        <slot></slot>
      </component>
    </div>
    <slot name="footer">
      <div class="step-footer">
        <div>
          <template v-if="!submitSuccess">
            <CButton
              color="secondary"
              :disabled="activeTabIndex === 0"
              class="mr-2"
              @click="previousTab"
            >
              Previous
            </CButton>
            <CButton
              v-if="activeTabIndex < totalTabs - 1"
              color="primary"
              @click="nextTab"
            >
              Next
            </CButton>
            <CButton
              v-if="activeTabIndex === totalTabs - 1"
              color="success"
              @click="onSubmit"
            >
              Submit
            </CButton>
          </template>
        </div>
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'TabWizard',
  provide() {
    return { fade: this.fade === true, initialTab: this.activeTabIndex };
  },
  props: {
    currentTab: {
      type: Number,
      required: false,
      default: null,
    },
    fade: {
      type: Boolean,
      default: true,
    },
    bordered: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tabs: [],
      totalTabs: 0,
      internalActiveTabIndex: 0,
      submitSuccess: false,
    };
  },
  computed: {
    wrapperClasses() {
      return {
        'tab-step-wizard': true,
        bordered: this.bordered,
      };
    },
    unhiddenTabs() {
      return this.tabs.filter((t) => {
        return !t.hidden;
      });
    },
    activeTabIndex: {
      get() {
        return this.internalActiveTabIndex;
      },
      set(v) {
        let newIndex = v;
        if (v < 0 || this.totalTabs === 0) {
          newIndex = 0;
        } else if (v >= this.totalTabs) {
          newIndex = this.totalTabs - 1;
        }

        this.internalActiveTabIndex = newIndex;
        this.$emit('update:currentTab', newIndex);
      },
    },
    nextIndex() {
      if (this.activeTab.validate() === false) {
        return;
      }

      const cur = this.activeTabIndex;
      const next = cur + 1;
      if (next >= this.totalTabs) {
        return null;
      } else if (!this.tabs[next].hidden && !this.tabs[next].disabled) {
        return next;
      }

      let nextTab = this.tabs.find((tab) => {
        return tab.index > next && !(tab.hidden || tab.disabled);
      });

      if (nextTab) {
        return nextTab.index;
      }

      return null;
    },
    prvIndex() {
      const cur = this.activeTabIndex;
      if (cur === 0) {
        return null;
      }

      const prv = cur - 1;
      if (!this.tabs[prv].hidden && !this.tabs[prv].disabled) {
        return prv;
      }

      if (prv === 0) {
        return null;
      }

      for (var i = prv - 1; i--; i >= 0) {
        if (!this.tabs[i].hidden && !this.tabs[i].disabled) {
          return i;
        }
      }

      return 0;
    },
    activeTab() {
      return this.tabs[this.activeTabIndex];
    },
    progress() {
      if (this.activeTabIndex <= 0 || this.totalTabs === 0) {
        return 0;
      }

      return ((this.activeTabIndex + 1) / this.totalTabs) * 100;
    },
  },
  watch: {
    currentTab(v) {
      let newIndex = v;
      if (v < 0 || this.totalTabs === 0) {
        newIndex = 0;
      } else if (v >= this.totalTabs) {
        newIndex = this.totalTabs - 1;
      }
      if (newIndex != this.internalActiveTabIndex) {
        this.internalActiveTabIndex = newIndex;
        this.selectTab(newIndex);
      }
    },
    $children(children) {
      let tabIndex = 0;
      this.tabs = children
        .filter((c) => {
          return c.$options && c.$options.name === 'TabStep';
        })
        .map((t) => {
          t.index = tabIndex++;
          return t;
        });
    },
  },
  mounted() {
    var tabIndex = 0;
    this.tabs = this.$children
      .filter((c) => {
        return c.$options && c.$options.name === 'TabStep';
      })
      .map((t) => {
        t.index = tabIndex++;
        return t;
      });
    this.totalTabs = this.tabs.length;

    let findCurrentTab = true;
    let setCurrentTab = false;
    if ((this.currentTab || this.currentTab === 0) && this.currentTab >= 0) {
      if (this.currentTab < this.tabs.length) {
        this.internalActiveTabIndex = this.currentTab;
        findCurrentTab = false;
        setCurrentTab = true;
      }
    }

    if (findCurrentTab) {
      this.internalActiveTabIndex = this.tabs.findIndex(
        (tab) => tab.isActive === true,
      );
    }

    // Select first tab if none is marked selected
    if (this.internalActiveTabIndex === -1 && this.totalTabs > 0) {
      this.tabs[0].isActive = true;
      this.internalActiveTabIndex = 0;
    } else if (setCurrentTab) {
      this.tabs.forEach((t) => {
        t.isActive = t.index === this.internalActiveTabIndex;
      });
    }
  },
  methods: {
    previousTab() {
      const cur = this.activeTabIndex;
      const prv = this.prvIndex;

      if (null != prv) {
        const index = this.switchTab(prv);
        if (index !== cur) {
          this.tabs.forEach((tab) => {
            if (tab.index >= index) {
              tab.clearValidations();
            }
          });

          this.$emit('onPreviousStep', index);
        }
      }
    },

    nextTab() {
      const cur = this.activeTabIndex;
      const next = this.nextIndex;
      if (!this.validateCurrentTab()) {
        this.$emit('invalidTab', cur);
        return;
      }

      if (null != next) {
        const index = this.switchTab(next);
        if (index !== cur) {
          this.tabs.forEach((tab) => {
            if (tab.index >= index) {
              tab.clearValidations();
            }
          });
          this.$emit('onNextStep', index);
        }
      }
    },
    selectTab(index) {
      if (index == this.activeTabIndex || index < 0 || index > this.totalTabs) {
        return this.activeTabIndex;
      }

      if (this.isDisabledTabIndex(index)) {
        this.$log.debug('Index is disabled', index);
        return this.activeTabIndex;
      }

      if (index < this.activeTabIndex) {
        return this.switchTab(index);
      }
    },
    onSubmit() {
      if (this.validateCurrentTab() === false) return;
      this.$emit('onComplete');
    },
    isDisabledTabIndex(index) {
      if (index < this.totalTabs && !!this.tabs[index]) {
        const isDisabled = this.tabs[index].disabled === true;
        if (isDisabled) {
          this.$log.debug(`Tab at index '${index}' is disabled`);
        }
        return isDisabled;
      }

      return false;
    },
    switchTab(index) {
      const currentIndex = this.activeTabIndex;
      if (index === currentIndex) {
        return currentIndex;
      }

      if (index >= this.totalTabs || index < 0) {
        return currentIndex;
      }

      const direction = index < currentIndex ? -1 : 1;
      let nextIndex = index + direction;

      if (this.isDisabledTabIndex(index)) {
        if (nextIndex < this.totalTabs && index >= 0) {
          return this.switchTab(nextIndex);
        } else {
          return currentIndex;
        }
      }

      this.tabs.forEach((tab, idx) => {
        if (idx !== index) {
          tab.isActive = false;
        } else {
          tab.isActive = true;
        }
      });

      this.activeTabIndex = index;
      return index;
    },

    validateCurrentTab() {
      return this.activeTab.validate() !== false;
    },
  },
};
</script>
<style lang="scss">
.tab-step-wizard {
  width: 100%;
  margin: auto;

  &.tab-step-wizard.bordered {
    box-shadow: 0 1px 1px 0 rgba(var(--elevation-base-color), 0.14),
      0 2px 1px -1px rgba(var(--elevation-base-color), 0.12),
      0 1px 3px 0 rgba(var(--elevation-base-color), 0.2);

    .step-footer {
      border-top: 1px solid rgb(216, 219, 224);
    }
  }

  .step-progress {
    height: 1rem;
    background: white;
    // border-radius: 1rem;
    margin: 1rem 0rem;
  }

  .step-header {
    width: 100%;
    padding: 0 1.5rem;
    margin-bottom: 1rem;
  }

  .step-pills {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
  }

  .step-pills .step-item {
    background-color: #f5f5f5;
    border-radius: 10px;
    // padding: 5px 20px;
    list-style-type: none;
    padding: 0.5rem 1.5rem;
  }

  .step-pills .step-item a {
    text-decoration: none;
    color: #7b7b7b;
  }

  .step-pills .step-item {
    &.active {
      border: 1px solid #4b8aeb;
    }
    &.validated {
      border: 1px solid;
      &.valid {
        border-color: rgba(13, 189, 81, 0.75);
        .tab-status {
          background: rgba(13, 189, 81, 0.75);
        }
      }
      &.invalid {
        border-color: rgba(255, 0, 0, 0.7);
        .tab-status {
          background: rgba(255, 0, 0, 0.7);
        }
      }
    }
    &.disabled {
      background-color: #ced2d8;
      color: #4f5d73;
      opacity: 65%;
      .tab-status {
        background: rgba(0, 0, 0, 0.1);
        color: #4f5d73;
      }
    }
  }

  .step-body {
    width: 100%;
    margin-left: auto;
    padding: 0 1.5rem;
  }

  .step-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem 1.5rem;
  }

  .tab-status {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    line-height: 1.5rem;
    color: #fff;
    text-align: center;
    background: rgba(0, 0, 0, 0.38);
    border-radius: 50%;
  }
}
</style>
