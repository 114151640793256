import config from './config';
import EventSource from 'eventsource';
const client = config.client;
const baseUrl = config.baseUrl;
import { keycloak } from '@/AxiosClient';

export default {
  download(taxEngineType, requestId) {
    return client.get(
      `${baseUrl}/${taxEngineType.toLowerCase()}/rapid-insight/${requestId}/download`,
      {
        responseType: 'blob',
      },
    );
  },
  subscribe(taxEngineType, requestId, eventHandler, errorHandler) {
    let es = new EventSource(
      `${
        config.apiOriginUrl
      }/${taxEngineType.toLowerCase()}/rapid-insight/${requestId}/subscribe`,
      { headers: { Authorization: `Bearer ${keycloak.token}` } },
    );
    es.addEventListener(
      'rapid-insight',
      (e) => {
        let event = JSON.parse(e.data);
        eventHandler(event);
      },
      false,
    );

    es.addEventListener('error', (e) => {
      errorHandler(e);
    });

    return es;
  },
  submit(taxEngineType, file, outputType, connectivityId) {
    let formData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    if (connectivityId) {
      formData.append('connectivityId', connectivityId);
    }

    formData.append('outputType', outputType);

    return client
      .post(
        `${baseUrl}/${taxEngineType.toLowerCase()}/rapid-insight`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then((res) => {
        return res.data;
      });
  },
};
