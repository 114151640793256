<template>
  <div
    class="file-input-container"
    :class="computedWrapperClasses"
    @dragover.prevent
    @drop.prevent
  >
    <div class="file-input-toolbar">
      <CButtonClose
        v-c-tooltip="'Clear File Input'"
        aria-label="clear file input"
        @click="clearFiles"
      />
    </div>
    <div
      :id="`${fileInputId}-panel`"
      class="row file-input"
      tabindex="0"
      @drop="dropFile"
      @click="clickInputFile"
      @keyup.enter="clickInputFile"
    >
      <div class="col-12">
        <label class="file-input-label" :for="fileInputId">{{ label }}</label>
        <input
          ref="fileInput"
          type="file"
          class="file-input-element"
          v-bind="inputProps"
          @change="selectFile"
        />
        <div class="row" aria-hidden="true">
          <div v-if="!hasFile" class="col-12">
            <h2 class="text-center">
              Drop {{ multiple ? 'files' : 'a file' }} to upload
            </h2>
          </div>
          <div v-else class="col-12">
            <h2 class="text-center">
              <CIcon :name="iconName" class="text-center" :width="iconWidth" />
            </h2>
          </div>
        </div>
        <div class="row" aria-hidden="true">
          <div v-if="!hasFile" class="col-12">
            <h6 class="text-center">or click here</h6>
          </div>
          <div v-else class="col-12">
            <h4 class="text-center">
              {{ currentFilename }}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!!invalidFeedback" class="row pt-2">
      <div class="col-12 invalid-feedback text-center">
        {{ invalidFeedback }}
      </div>
    </div>
  </div>
</template>
<script>
import { makeUid } from './form-mixins';
import { formGroupProps, props } from './form-props';

export default {
  name: 'MFileInput',
  inheritAttrs: false,
  props: {
    ...formGroupProps,
    ...props,
    label: {
      type: String,
      default: 'File',
    },
    disabled: Boolean,
    multiple: Boolean,
    accept: {
      type: [String, Array],
      required: false,
      default() {
        return null;
      },
    },
    value: {
      type: [FileList, File, Array],
      required: false,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      filesState: this.value,
    };
  },
  computed: {
    computedWrapperClasses() {
      return {
        sm: this.size === 'sm',
        'is-invalid': this.isValid === false,
        'is-valid': this.isValid === true,
        'has-input': this.hasFile,
        disabled: this.disabled === true,
      };
    },
    currentFilename() {
      if (this.hasFile) {
        return this.files[0].name;
      }
      return null;
    },
    hasFile() {
      return this.files && this.files.length > 0 && !!this.files[0];
    },
    fileInputId() {
      return this.$attrs.id || this.inputId || makeUid();
    },
    iconWidth() {
      if (this.size === 'sm') {
        return '50';
      } else {
        return '75';
      }
    },
    iconName() {
      if (!this.hasFile || this.files.length > 1) {
        return 'cil-file';
      }

      let filename = this.currentFilename;
      let parts = filename.split('.');
      let extension = parts[parts.length - 1].toLowerCase();
      switch (extension) {
        case 'xls':
        case 'xlsx':
        case 'xlt':
        case 'xltx':
          return 'cil-file-excel';
        case 'zip':
        case 'gz':
          return 'cil-file-archive';
        default:
          return 'cil-file';
      }
    },
    inputProps() {
      let p = {
        id: this.fileInputId,
        disabled: this.disabled,
        required: this.required,
      };

      if (this.$attrs.name || this.name) {
        p.name = this.$attrs.name || this.name;
      }

      if (this.accept) {
        if (this.$_.isString(this.accept)) {
          p.accept = this.accept;
        }
        if (this.$_.isArray(this.accept)) {
          p.accept = this.accept.join(' ');
        }
      }
      return p;
    },
    files: {
      get() {
        return this.filesState;
      },
      set(v) {
        let value = v;
        if (!v || v.length < 1 || !v[0]) {
          value = null;
        }
        this.filesState = v;
        this.$emit('update:value', value);
        this.$emit('change', value);
      },
    },
  },
  watch: {
    value(v) {
      if (!v) {
        this.filesState = null;
        this.$refs.fileInput.value = null;
      }

      if (typeof v === File) {
        this.filesState = [v];
      } else if (typeof v === FileList || this.$_.isArrayLike(v)) {
        this.filesState = v;
      } else {
        this.filesState = [v];
      }
    },
  },
  methods: {
    selectFile() {
      if (this.disabled) {
        return;
      }
      this.files = this.$refs.fileInput.files;
    },
    dropFile(e) {
      if (this.disabled) {
        return;
      }
      this.files = e.dataTransfer.files;
      this.$refs.fileInput.files = e.dataTransfer.files;
    },
    clickInputFile() {
      if (this.disabled) {
        return;
      }
      this.$refs.fileInput.click();
    },
    clearFiles() {
      this.files = null;
      this.$refs.fileInput.value = null;
    },
  },
};
</script>
<style lang="scss">
.file-input-container {
  width: 100%;
  height: 200px;
  margin: 2rem auto;
  padding: 2rem;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  background-color: rgba(238, 231, 234, 0.4);
  display: inline-flex;
  justify-content: center;
  flex-direction: column;

  .file-input-toolbar {
    display: none;
    width: 100%;
    height: 25px;
    margin-top: -25px;

    button.close {
      margin-right: -15px;
    }
  }

  .row {
    width: 100%;
  }

  &.has-input {
    .file-input-toolbar {
      display: inline-flex;
      justify-content: flex-end;
    }
  }

  &.is-invalid {
    border-color: #e55353;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e55353' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e55353' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    background-position: 10px 10px;
    .invalid-feedback {
      margin-top: auto;
      margin-bottom: 0;
      width: 100%;
      display: block;
    }
  }
  &.sm {
    height: 150px;
    h2,
    .h2 {
      font-size: 1.5rem;
      margin-bottom: 0.3rem;
    }
  }

  .file-input-element,
  label {
    display: none;
    visibility: hidden;
  }

  .file-input {
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    &:not(.disabled) {
      &:hover {
        color: #993366;
      }
    }
  }
}

.file-input-container.disabled {
  color: #768192;
  background-color: rgba(238, 231, 234, 0.2);
  .file-input {
    cursor: not-allowed;
    &:hover {
      color: #768192;
    }
  }
}
</style>
