<template>
  <div>
    <router-view />
    <FreshDesk />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FreshDesk from '@/components/FreshDesk.vue';
export default {
  name: 'App',
  components: {
    FreshDesk,
  },
  computed: {
    ...mapGetters('auth', ['loggedIn', 'isAdmin']),
  },
  watch: {
    loggedIn(value) {
      if (value) {
        this.loadInitialData();
      }
    },
  },
  methods: {
    loadInitialData() {},
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="svgmap/dist/svgMap.min.css"></style>
<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
</style>
