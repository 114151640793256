<template>
  <CButton
    class="btn-crd btn-delete"
    v-bind="cButtonProps"
    @click="
      (e) => {
        if (!disabled && !locked) {
          $emit('click', e);
        }
      }
    "
  >
    <slot><CIcon :name="iconName" /></slot>
  </CButton>
</template>
<script>
import { mButtonsMixin } from './mbuttons-mixin';
export default {
  name: 'MDeleteButton',
  mixins: [mButtonsMixin],
  props: {
    locked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    computedClasses() {
      return { 'btn-locked': this.locked };
    },
    defaultProps() {
      if (this.locked || this.disabled) {
        return {
          variant: 'ghost',
          color: 'secondary',
          alt: `Delete Button (${this.locked ? 'locked' : 'disabled'})`,
          title: `Delete (${this.locked ? 'locked' : 'disabled'})`,
          disabled: true,
        };
      } else {
        return {
          variant: 'ghost',
          color: 'danger',
          alt: 'Delete Button',
          title: 'Delete',
        };
      }
    },
    iconName() {
      if (!this.locked) {
        return 'cis-trash-alt';
      } else {
        return 'cis-lock-locked';
      }
    },
  },
};
</script>
