'use strict';
class GeoZone {
  constructor(dto = {}) {
    this.key = dto.key;
    this.primaryCode = dto.primaryCode;
    this.geoType = dto.geoType;
    this.displayName = dto.displayName;
    this.parentKey = dto.parentKey;
    this.parentPrimaryCode = dto.parentPrimaryCode;
    this.parentGeoType = dto.parentGeoType;
    this.parentDisplayName = dto.parentDisplayName;
  }

  get isParent() {
    return !this.parentKey || this.parentKey === 'WORLD';
  }

  get conditionalParentDisplayName() {
    if (this.isParent) {
      return this.displayName;
    } else {
      return this.parentDisplayName;
    }
  }

  get conditionalParentKey() {
    if (this.isParent) {
      return this.key;
    } else {
      return this.parentKey;
    }
  }

  get fullDisplayName() {
    if (this.isParent || !this.parentDisplayName) {
      return this.displayName;
    } else {
      return `${this.countryKey} - ${this.displayName}`;
    }
  }

  get countryKey() {
    if (this.geoType === 'COUNTRY') {
      return this.key;
    } else if (this.parentGeoType === 'COUNTRY') {
      return this.parentKey;
    } else {
      return null;
    }
  }

  get countryName() {
    if (this.geoType === 'COUNTRY') {
      return this.displayName;
    } else if (this.parentGeoType === 'COUNTRY') {
      return this.parentDisplayName;
    } else {
      return null;
    }
  }

  get provStateKey() {
    if (
      this.geoType === 'PROVINCE' ||
      this.geoType === 'STATE' ||
      this.geoType === 'TERRITORY'
    ) {
      return this.key;
    } else if (
      this.parentGeoType &&
      (this.parentGeoType === 'PROVINCE' ||
        this.parentGeoType === 'STATE' ||
        this.parentGeoType === 'TERRITORY')
    ) {
      return this.parentKey;
    } else {
      return null;
    }
  }

  get provStateName() {
    if (
      this.geoType === 'PROVINCE' ||
      this.geoType === 'STATE' ||
      this.geoType === 'TERRITORY'
    ) {
      return this.displayName;
    } else if (
      this.parentGeoType &&
      (this.parentGeoType === 'PROVINCE' ||
        this.parentGeoType === 'STATE' ||
        this.parentGeoType === 'TERRITORY')
    ) {
      return this.parentDisplayName;
    } else {
      return null;
    }
  }
}

export default GeoZone;
