import Vue from 'vue';
import Vuex from 'vuex';
import { VUE_APP_DEBUG, VUE_APP_UI_CONFIG } from '@/EnvVars';
import createLogger from './logger';
import coreui from './modules/coreui-module';
import auth from './modules/auth-module';
import loader from './modules/loader-module';
import testing from './modules/testing-module';
import registrations from './modules/registrations-module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    coreui,
    loader,
    testing,
    registrations,
  },
  getters: {
    sidebarMinimize(_state, _getters, _rootState, rootGetters) {
      return rootGetters['coreui/sidebarMinimize'];
    },
    hasSingleTaxEngine(_state, _getters, _rootState, rootGetters) {
      return rootGetters['auth/hasSingleTaxEngine'];
    },
    defaultTaxEngine(_state, _getters, _rootState, rootGetters) {
      return rootGetters['auth/defaultTaxEngine'];
    },
    taxEngines(_state, _getters, _rootState, rootGetters) {
      return rootGetters['auth/taxEngines'];
    },
    brandPath(_state, _getters, _rootState, rootGetters) {
      return rootGetters['auth/brandPath'] || '/brand';
    },
    uiConfig(_state, _getters, _rootState, rootGetters) {
      return rootGetters['auth/uiConfig'] || VUE_APP_UI_CONFIG;
    },
    helpConfig(_state, _getters, _rootState, rootGetters) {
      return (rootGetters['auth/uiConfig'] || VUE_APP_UI_CONFIG)?.menu?.help
        ?.help;
    },
    supportConfig(_state, _getters, _rootState, rootGetters) {
      return (rootGetters['auth/uiConfig'] || VUE_APP_UI_CONFIG)?.menu?.help
        ?.support;
    },
  },
  actions: {},
  strict: VUE_APP_DEBUG,
  plugins: VUE_APP_DEBUG ? [createLogger()] : [],
});
