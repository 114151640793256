<template>
  <CButton
    :id="buttonId"
    class="btn-crd btn-clipboard"
    v-bind="cButtonProps"
    @click="(e) => $emit('click', e)"
  >
    <slot><CIcon name="cil-clipboard" title="Copy" /></slot>
  </CButton>
</template>
<script>
import { mButtonsMixin } from './mbuttons-mixin';
export default {
  name: 'MClipboardButton',
  mixins: [mButtonsMixin],
  computed: {
    buttonId() {
      return this.id || this.$attrs.id || this.$props.id || this._uid;
    },
    defaultProps() {
      return {
        size: '',
        color: 'success',
        alt: 'Copy Button',
        title: 'Copy to Clipboard',
      };
    },
  },
  methods: {
    copied() {},
  },
};
</script>
<style lang="scss">
.btn-clipboard {
  &.animated {
    animation: tada 0.4s ease-out;
  }
}
.btn-clipboard:active {
  animation: tada 0.4s ease-out;
}
</style>
