import { get } from './state-mutators';
import api from '@/api';

const state = {
  connections: [],
  testDecks: [],
  connectionsLoading: false,
  testDecksLoading: false,
};

const getters = {
  canRunTests: (state) => {
    return state.connections.length > 0 && state.testDecks.length > 0;
  },
  hasConnections: (state) => {
    return state.connections.length > 0;
  },
  hasTestDecks: (state) => {
    return state.testDecks.length > 0;
  },
  connections: get('connections'),
  testDecks: get('testDecks'),
  connectionsLoading: get('connectionsLoading'),
  testDecksLoading: get('testDecksLoading'),
};

const mutations = {
  setTestDecksLoading(value) {
    state.testDecksLoading = value === true;
  },
  setConnectionsLoading(value) {
    state.connectionsLoading = value === true;
  },
  setTestDecks: (state, testDecks) => {
    if (testDecks) {
      state.testDecks = testDecks.map((o) => Object.freeze(o));
    } else {
      state.testDecks = [];
    }
  },
  setConnections: (state, connections) => {
    if (connections) {
      state.connections = connections.map((o) => Object.freeze(o));
    } else {
      state.connections = [];
    }
  },
  addOrUpdateConnection: (state, connection) => {
    const index = state.connections.findIndex((o) => {
      return o.id === connection.id;
    });

    if (index >= 0) {
      state.connections.splice(index, 1);
    }
    state.connections.push(connection);
    state.connections.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  },
  addOrUpdateTestDeck: (state, testDeck) => {
    const index = state.testDecks.findIndex((o) => {
      return o.id === testDeck.id;
    });

    if (index >= 0) {
      state.testDecks.splice(index, 1);
    }
    state.testDecks.push(testDeck);
    state.testDecks.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  },
  removeTestDeck: (state, testDeck) => {
    let testDeckId = testDeck?.id || testDeck;
    const index = state.testDecks.findIndex((o) => {
      return o.id === testDeckId;
    });

    if (index >= 0) {
      state.testDecks.splice(index, 1);
    }
  },
  removeConnection: (state, connection) => {
    let connectionId = connection?.id || connection;
    const index = state.connections.findIndex((o) => {
      return o.id === connectionId;
    });

    if (index >= 0) {
      state.connections.splice(index, 1);
    }
  },
};

const actions = {
  fetchTestDecks: ({ commit }) => {
    commit('setTestDecksLoading', true);
    return new Promise((resolve, reject) => {
      api.tests.decks
        .getAllTestDecks()
        .then((res) => {
          commit('setTestDecks', res || []);
          commit('setTestDecksLoading', false);
          resolve(res);
        })
        .catch((err) => {
          commit('setTestDecks', []);
          commit('setTestDecksLoading', false);
          reject(err);
        });
    });
  },
  deleteTestDeck: ({ commit }, testDeckId) => {
    return new Promise((resolve, reject) => {
      api.tests.decks
        .delete(testDeckId)
        .then((res) => {
          commit('removeTestDeck', testDeckId);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchConnections: ({ commit }) => {
    commit('setConnectionsLoading', true);
    return new Promise((resolve, reject) => {
      api.connectivity
        .getAllTaxEngineConnections()
        .then((res) => {
          commit('setConnections', res || []);
          commit('setConnectionsLoading', false);
          resolve(res);
        })
        .catch((err) => {
          commit('setConnections', []);
          commit('setConnectionsLoading', false);
          reject(err);
        });
    });
  },
  deleteConnection: ({ commit }, connectionId) => {
    return new Promise((resolve, reject) => {
      api.connectivity
        .delete(connectionId)
        .then((res) => {
          commit('removeConnection', connectionId);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createConnection: ({ commit }, request) => {
    return new Promise((resolve, reject) => {
      api.connectivity
        .create(request)
        .then((res) => {
          commit('addOrUpdateConnection', res);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
