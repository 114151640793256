import parseJSON from 'date-fns/parseJSON';
import parseISO from 'date-fns/parseISO';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import _ from 'lodash';
import moment from 'moment';
const locale2 = require('locale2');

const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';

function parseUS(dateString) {
  if (dateString.includes(':')) {
    return parse(dateString, 'M/d/yyyy HH:mm', new Date());
  } else {
    return parse(dateString, 'M/d/yyyy', new Date());
  }
}

export function formatTimestamp(value) {
  return formatDate(value, 'MMM dd, yyyy HH:mm');
}

export function formatDate(value, fmt) {
  if (_.isNil(value)) {
    return value;
  }
  if (_.isDate(value) || _.isNumber(value)) {
    return format(value, fmt || DEFAULT_DATE_FORMAT);
  }

  if (moment.isMoment(value)) {
    return format(moment(value).toDate(), fmt || DEFAULT_DATE_FORMAT);
  }

  if (_.isString(value)) {
    if (value.includes('/')) {
      return format(parseUS(value), fmt || DEFAULT_DATE_FORMAT);
    } else if (value.includes('T')) {
      return format(parseJSON(value), fmt || DEFAULT_DATE_FORMAT);
    } else {
      return format(parseISO(value), fmt || DEFAULT_DATE_FORMAT);
    }
  }
}

export function formatPhone(str) {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return str;
}

export function percent(value, maxDigits = 0) {
  let number = Number(value);
  if (number > 1) {
    number = number / 100;
  }

  let opts = {
    style: 'percent',
    minimumFractionDigits: maxDigits,
    minimumIntegerDigits: 1,
  };

  if (maxDigits > 0) {
    opts.maximumFractionDigits = maxDigits;
  }

  return new Intl.NumberFormat(locale2, opts).format(number);
}
export function upperCase(str) {
  if (!str) {
    return str;
  } else {
    return str.uppercase();
  }
}

/**
 * Formats a number for display with locale based groupings and an optional fixed amount of decimal points.
 * @param {(string|number)} num The number to format.
 * @param {number} decimalPlaces (Optional) The fixed number of decimal places to show.
 * If not provided, num will be formatted to however many decimal places it has.
 * @returns {string} Formatted number string.
 */
export function formatNumber(num, decimalPlaces) {
  if (typeof num === 'undefined' || num === null || num === false) {
    return '';
  } else if (isNaN(num)) {
    return `${num}`;
  }

  let parsedNum = null;
  let opts = {
    style: 'decimal',
  };

  if ((decimalPlaces && decimalPlaces > 0) || decimalPlaces === 0) {
    parsedNum = parseFloat(num).toFixed(decimalPlaces);
    opts.minimumFractionDigits = decimalPlaces;
    opts.maximumFractionDigits = decimalPlaces;
  } else {
    parsedNum = Number(num);
  }

  return new Intl.NumberFormat(locale2, opts).format(parsedNum);
}

/**
 * Formats a number for display as a currency, including groupings and currency symbol.
 * @param {string} currency The currency to format the number value for.
 * @param {(string|number)} num The number to format as a currency String.
 * @returns {string} Formatted currency string.
 */
export function formatCurrency(currency, amount) {
  if (!amount || isNaN(amount)) {
    return null;
  }

  //const locale = navigator.language || navigator.userLanguage || 'en-US';
  if (currency) {
    return Intl.NumberFormat(locale2, {
      style: 'currency',
      currency: currency.toUpperCase(),
      minimumFractionDigits: 2,
    }).format(amount);
  } else {
    return Intl.NumberFormat(locale2, {
      minimumFractionDigits: 2,
    }).format(amount);
  }
}
