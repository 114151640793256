import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import testings from './testings.js';
import rapidInsight from './rapid-insight.js';
import rapidVerify from './rapid-verify.js';
import { logger } from '../utils/logger.js';

// Containers
const TheContainer = () => import('@/containers/TheContainer');

// Views
const Dashboard = () => import('@/views/dashboard/Dashboard');
const NotFound = () => import('@/views/NotFound');
const NoPermission = () => import('@/views/NoPermission');
const LoggedOut = () => import('@/views/LoggedOut');

Vue.use(Router);
const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/logged-out',
      name: 'LoggedOut',
      component: LoggedOut,
      meta: {
        public: true,
      },
    },
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
        },
        {
          path: '/404',
          name: 'Page Not Found',
          component: NotFound,
        },
        {
          path: '/403',
          name: 'NoPermission',
          component: NoPermission,
        },
        ...testings.children,
        ...rapidInsight.children,
        ...rapidVerify.children,
      ],
    },
    { path: '*', redirect: '/dashboard' }, // this is a catch-all and should be the last route entry.
  ],
});

router.beforeEach((to, from, next) => {
  if (!to.meta.public) {
    if (store.getters['auth/getStatus'] !== 'SUCCESS') {
      if (to.query['logged_in']) {
        store
          .dispatch('auth/refreshToken')
          .then(() => {
            next({
              query: Object.fromEntries(
                Object.entries(to.query).filter((e) => {
                  return e[0] !== 'logged_in';
                }),
              ),
            });
          })
          .catch((err) => {
            logger.warn(
              'Error refreshing token, redirecting to logged out',
              err,
            );
            next({ name: 'LoggedOut' });
          });
      } else {
        store.dispatch('auth/doLogin');
      }
    } else {
      store.dispatch('auth/updateLastActivity');
      store.dispatch('auth/refreshToken');
      next();
    }
  } else {
    next();
  }
});

export default router;
