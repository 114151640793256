import axios from 'axios';
import Keycloak from 'keycloak-js';
import store from '@/store';
import Vue from 'vue';
import { logger } from '@/utils/logger';
import _ from 'lodash';
import {
  VUE_APP_JWT_AUTH_URL,
  VUE_APP_JWT_AUTH_REALM,
  VUE_APP_JWT_AUTH_CLIENT_ID,
  VUE_APP_USE_KEYCLOAK,
  VUE_APP_IDLE_PROMPT,
  VUE_APP_JWT_AUTH_MAX_IDLE_TIME,
} from '@/EnvVars';

const keycloakConfig = {
  url: VUE_APP_JWT_AUTH_URL,
  realm: VUE_APP_JWT_AUTH_REALM,
  clientId: VUE_APP_JWT_AUTH_CLIENT_ID,
  checkLoginIframe: false,
  options: {
    idpHint: null,
  },
};

class AxiosKeycloak extends Keycloak {
  constructor(config) {
    super(config);
    this.retryRequests = [];
    this.isRefreshing = false;
    this.configurationProperties = config;
  }

  hasToken() {
    return (
      this.token && this.idToken && this.token !== '' && this.idToken !== ''
    );
  }

  subscribeForRefresh(cb) {
    this.retryRequests.push(cb);
  }

  handleTokenRefreshed(token) {
    const count = this.retryRequests.length;

    if (count > 0) {
      for (var i = 0; i < count; i++) {
        const cb = this.retryRequests.pop();
        cb(token);
      }
    }
    this.isRefreshing = false;
  }
  /**
   *
   * @param {AxiosRequestConfig} config Axios config.
   * @returns {AxiosInstance} new Axios instance.
   */
  createAxiosInstance(config) {
    const instance = axios.create(config);
    instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    if (VUE_APP_USE_KEYCLOAK || process.env.NODE_ENV === 'production') {
      instance.interceptors.request.use((config) => {
        config.headers.common['Authorization'] = `Bearer ${this.token}`;
        store.dispatch('loader/startRequest');
        return config;
      });

      instance.interceptors.response.use(
        (response) => {
          store.dispatch('loader/finishRequest');
          return response;
        },
        (error) => {
          store.dispatch('loader/finishRequest', error);
          if (error.response.status === 401) {
            const originalRequest = error.config;
            const retryPromise = new Promise((resolve) => {
              this.subscribeForRefresh((token) => {
                logger.debug('Retrying request...');
                originalRequest.baseURL = undefined;
                originalRequest.headers['Authorization'] = `Bearer ${token}`;
                resolve(instance(originalRequest));
              });
            });

            if (!this.isRefreshing) {
              this.isRefreshing = true;
              const self = this;
              return new Promise((resolve, reject) => {
                self
                  .updateToken(20)
                  .then((refreshed) => {
                    if (!refreshed) {
                      // TODO: Raise alert/error or... self.logout();?
                      logger.error('Token was not refreshed...');
                      reject(error);
                      self.logout();
                    } else {
                      store.dispatch('auth/updateLastActivity');
                      self.handleTokenRefreshed(self.token);
                      resolve(retryPromise);
                    }
                  })
                  .catch((err) => {
                    // TODO: Logout?
                    logger.error('Token refresh error', err);
                    reject(err);
                  });
              });
            } else {
              return retryPromise;
            }
          } else {
            return Promise.reject(error);
          }
        },
      );
    }

    return instance;
  }

  static get axios() {
    return axios;
  }
}

const keycloak = new AxiosKeycloak(keycloakConfig);

keycloak.onTokenExpired = () => {
  const lastActivity = store.getters['auth/lastActivity'];
  const now = new Date().getTime();
  const delta = now - lastActivity;
  if (VUE_APP_JWT_AUTH_MAX_IDLE_TIME - delta < VUE_APP_IDLE_PROMPT) {
    logger.debug('Dispatching idle prompt...');
    store.dispatch('auth/idlePrompt');
  } else {
    keycloak.updateToken().catch((err) => {
      if (err) {
        logger.error(
          'Failed to refresh token',
          JSON.parse(JSON.stringify(err || {})),
        );
      }
      keycloak.logout();
    });
  }
};

const keycloakPlugin = {
  install(vue) {
    vue.$keycloak = keycloak;
    Object.defineProperty(Vue.prototype, '$keycloak', {
      value: keycloak,
      writable: false,
    });
  },
};

const handleApiError = function (err, swal, badRequestCb) {
  if (!err.response) {
    swal({
      title: 'Unknown Error',
      text: 'Errors occurred trying to submit the request',
      icon: 'error',
      showCancelButton: false,
      confirmButtonText: 'OK',
    });
  } else {
    const status = err.response.status || 999;
    let title = 'Error';
    let message = 'Errors occurred trying to submit the request';
    switch (status) {
      case 400:
        title = 'Bad Request';
        message = 'Please fix the marked errors and re-submit';
        if (badRequestCb) {
          badRequestCb(err.response);
        }
        break;
      case 401:
        title = 'Unauthorized';
        message = 'You are not authorized to perform this action';
        break;
      case 403:
        title = 'Forbidden';
        message =
          'You do not have sufficient permission to perform this action';
        break;
      case 500:
        title = 'Server Error';
        logger.error('Internal server error', _.get(err.response, 'data'));
        message = _.get(
          err.response,
          'data.message',
          'Unknown server error occurred, please contact support.',
        );
        break;
    }

    return swal({
      title,
      text: message,
      icon: 'error',
      showCancelButton: false,
      confirmButtonText: 'OK',
    }).then(() => status);
  }
};

export { keycloak, keycloakPlugin, keycloakConfig, handleApiError };

export function isAxiosError(err) {
  if (_.isNil(err)) {
    return false;
  }

  if (err.status && err.statusText) {
    return true;
  } else if (err.response) {
    return isAxiosError(err.response);
  }

  return false;
}

export function isBadRequest(err) {
  if (!isAxiosError(err)) {
    return false;
  }

  if (err.response) {
    return isBadRequest(err.response);
  }

  if (err.status === 400) {
    return true;
  }

  return false;
}
