import config from '@/api/config';

const client = config.client;
const baseUrl = config.baseUrl;
const baseConnectivityUrl = `${baseUrl}/connectivity`;

export default {
  getPublicKey() {
    return client
      .get(`${baseConnectivityUrl}/public-key`)
      .then((res) => res.data);
  },
  getAllTaxEngineConnections() {
    return client.get(baseConnectivityUrl).then((res) => {
      if (res.data) {
        return res.data.map((o) => {
          return {
            ...o,
            engineType: o.taxEngineType,
          };
        });
      } else {
        return res.data;
      }
    });
  },
  getTaxEngineConnection(id) {
    return client.get(`${baseConnectivityUrl}/${id}`).then((res) => {
      if (res.data) {
        return {
          ...res.data,
          engineType: res.data.taxEngineType,
        };
      } else {
        return res.data;
      }
    });
  },
  testConnectivity(
    engineType,
    url,
    username,
    password,
    trustedId,
    environment = null,
    encrypted = false,
  ) {
    const request = {
      engineType,
      url,
      username,
      password,
      trustedId,
      environment,
      encrypted: encrypted === true,
    };
    return client
      .post(`${baseUrl}/${engineType.toLowerCase()}/connectivity/test`, request)
      .then((res) => res.data);
  },
  create(request) {
    return client
      .post(
        `${baseUrl}/${request.engineType.toLowerCase()}/connectivity`,
        request,
      )
      .then((res) => res.data);
  },
  save(request) {
    return client
      .put(
        `${baseUrl}/${request.engineType.toLowerCase()}/connectivity/${
          request.id
        }`,
        request,
      )
      .then((res) => res.data);
  },
  delete(id) {
    return client
      .delete(`${baseConnectivityUrl}/${id}`)
      .then((res) => res.data);
  },
};
