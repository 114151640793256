import { getEnv, getEnvBoolean } from './utils/helpers';

export const VUE_APP_BUGSNAG_ENABLED = getEnvBoolean('VUE_APP_BUGSNAG_ENABLED');
export const VUE_APP_DEBUG = getEnvBoolean('VUE_APP_DEBUG');
// When false (for debug/testing locally) no Keycloak auth will be used.
export const VUE_APP_USE_KEYCLOAK = getEnvBoolean('VUE_APP_USE_KEYCLOAK', true);
export const VUE_APP_DISTRIBUTION_VERSION =
  getEnv('VUE_APP_DISTRIBUTION_VERSION') || 'unknown';
export const VUE_APP_JWT_AUTH_CLIENT_ID =
  getEnv('VUE_APP_JWT_AUTH_CLIENT_ID') || 'idta-frontend';
export const VUE_APP_JWT_AUTH_REALM =
  getEnv('VUE_APP_JWT_AUTH_REALM') || 'cordiance';
export const VUE_APP_JWT_AUTH_URL =
  getEnv('VUE_APP_JWT_AUTH_URL') || 'https://id.infra.cordiance.io/auth';
export const VUE_APP_RELEASE_STAGE = getEnv('VUE_APP_RELEASE_STAGE') || 'local';
export const VUE_APP_IDLE_PROMPT =
  Number.parseInt(getEnv('VUE_APP_IDLE_PROMPT')) || 120000;
export const VUE_APP_JWT_AUTH_MAX_IDLE_TIME =
  Number.parseInt(getEnv('VUE_APP_JWT_AUTH_MAX_IDLE_TIME')) || 900000;
export const VUE_APP_USE_TENANT_API = getEnvBoolean(
  'VUE_APP_USE_TENANT_API',
  true,
);
export const VUE_APP_TENANT_API_PATH =
  getEnv('VUE_APP_TENANT_API_PATH') || '/api/tenant/auth-provider';
export const VUE_APP_UI_CONFIG = getEnv('VUE_APP_UI_CONFIG') || {};
export const VUE_APP_KC_IDP_HINT = getEnv('VUE_APP_KC_IDP_HINT');
export const VUE_APP_MAX_RI_SIZE =
  Number.parseInt(getEnv('VUE_APP_MAX_RI_SIZE') || 0) || 5242880;
export const VUE_APP_BRAND_PATH = getEnv('VUE_APP_BRAND_PATH') || '/brand';
export const VUE_APP_JWT_BRAND_ENABLED = getEnvBoolean(
  'VUE_APP_JWT_BRAND_ENABLED',
  true,
);
export const VUE_APP_CHARGEBEE_PORTAL = getEnv('VUE_APP_CHARGEBEE_PORTAL');
