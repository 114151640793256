<template>
  <div :id="tableId" v-resize="handleResize" class="m-table-container">
    <slot v-if="loading" name="loading">
      <CElementCover class="m-table-loading-cover" />
    </slot>

    <!-- Table filter row -->
    <div
      v-if="haveFilterOption && tableFilter && !inlineTableFilter"
      class="row m-table-controls"
    >
      <div v-if="haveFilterOption" class="col-md-5 col-lg-4 form-inline">
        <div v-if="tableFilter" class="input-group">
          <slot name="table-filter">
            <input
              class="form-control"
              type="text"
              placeholder="filter table..."
              :value="tableFilterState"
              aria-label="table filter input"
              @input="tableFilterChange($event.target.value, 'input')"
              @change="tableFilterChange($event.target.value, 'change')"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <CIcon
                  name="cil-filter-x"
                  v-bind="{ ...cleanerProps }"
                  @click.native="clean"
                />
              </span>
            </div>
          </slot>
        </div>
      </div>
      <div
        v-if="hideControls || pagination !== false || inlineTableFilter"
        :class="{ 'col-md-7 col-lg-8': haveFilterOption }"
      >
        <slot name="table-controls"></slot>
      </div>
    </div>

    <!-- Density/Pagination Row -->
    <div v-if="!hideControls" class="row m-table-controls">
      <div v-if="showDensity || showColumnSelector" class="col-xs-12 col-md-6">
        <CButtonToolbar>
          <MTableDensityPicker
            v-if="showDensity"
            :density.sync="currentDensity"
          />

          <CDropdown
            v-if="showColumnSelector"
            color="outline-control"
            toggler-text="Columns"
            class="ml-1 m-table-column-selector"
          >
            <template v-for="(opt, index) in columnToggleDropdownOptions">
              <li
                v-if="opt.label"
                :key="`drop-chk-${opt.key}`"
                class="dropdown-item"
              >
                <CInputCheckbox
                  v-bind="{ ...opt }"
                  @update:checked="toggleColumn(opt.key, index)"
                />
              </li>
            </template>
          </CDropdown>
        </CButtonToolbar>
      </div>
      <div
        v-if="pagination === false && !inlineTableFilter"
        class="col-xs-12 col-md-6"
      >
        <slot name="table-controls"></slot>
      </div>
      <div
        v-if="inlineTableFilter"
        class="col-xs-12 col-md-6 col-lg-4 form-inline"
      >
        <slot name="table-filter" :inline="true">
          <div v-if="tableFilter" class="input-group">
            <input
              class="form-control"
              type="text"
              placeholder="filter table..."
              :value="tableFilterState"
              aria-label="table filter input"
              @input="tableFilterChange($event.target.value, 'input')"
              @change="tableFilterChange($event.target.value, 'change')"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <CIcon
                  name="cil-filter-x"
                  v-bind="{ ...cleanerProps }"
                  @click.native="clean"
                />
              </span>
            </div>
          </div>
        </slot>
      </div>
      <div
        v-if="pagination === false && inlineTableFilter"
        class="col-xs-8 col-sm-9 col-md-6 col-lg-8 inline-table-controls"
      >
        <slot name="table-controls"></slot>
      </div>
      <div v-if="pagination !== false" class="col-xs-12 col-md-6">
        <MTablePaginator
          :page.sync="currentPage"
          :per-page.sync="currentPageSize"
          :total-rows="
            totalRows && totalRows > 0 ? totalRows : passedItems.length
          "
          :total-pages="totalPages"
          :filtered-rows="sortedItems.length"
          :filtering="
            !!isFiltered && isFiltered !== '' && !isExternallyFiltered
          "
        />
      </div>
    </div>

    <div class="m-table-header-control-group">
      <slot name="over-table" />
    </div>

    <div class="m-table-wrapper" :class="tableWrapperClasses">
      <table ref="table" class="m-table table" :class="tableClasses">
        <thead>
          <tr v-if="header" class="m-table-header-row">
            <th v-if="hasDetails" style="width: 25px">&nbsp;</th>
            <template v-for="(name, index) in columnNames">
              <th
                :key="`${name}_${index}`"
                role="columnheader"
                scope="col"
                :class="[headerClass(index)]"
                :style="headerStyles(index)"
                @click="changeSort(rawColumnNames[index], index)"
              >
                <slot :name="`${rawColumnNames[index]}-header`">
                  <span class="m-column-header">{{ name }}</span>
                </slot>
                <slot
                  v-if="isSortable(index)"
                  name="sorting-icon"
                  :state="getIconState(index)"
                  :classes="iconClasses(index)"
                >
                  <CIcon
                    width="18"
                    name="cis-arrow-top"
                    :class="iconClasses(index)"
                    :aria-label="`change column: '${name}' sorting`"
                  />
                </slot>
              </th>
            </template>
          </tr>

          <tr v-if="columnFilter" class="table-sm m-table-filter-row">
            <th v-if="hasDetails" style="width: 25px">&nbsp;</th>
            <template v-for="(colName, index) in rawColumnNames">
              <th
                v-if="isFilterable(index)"
                :key="`${colName}_${index}`"
                :class="filterHeaderClass(index)"
              >
                <slot :name="`${rawColumnNames[index]}-filter`">
                  <input
                    v-if="
                      !activeColumns || activeColumns[index].filter !== false
                    "
                    class="form-control"
                    :value="columnFilterState[colName]"
                    :aria-label="`column name: '${colName}' filter input`"
                    @input="
                      columnFilterEvent(colName, $event.target.value, 'input')
                    "
                    @change="
                      columnFilterEvent(colName, $event.target.value, 'change')
                    "
                  />
                </slot>
              </th>
              <th v-else :key="`${colName}_${index}`">&nbsp;</th>
            </template>
          </tr>
        </thead>

        <tbody>
          <template v-for="(item, itemIndex) in currentItems">
            <tr
              :key="`${item._rowKey}-${itemIndex}`"
              :class="item._classes"
              :tabindex="clickableRows ? 0 : null"
            >
              <!--@click="rowClicked(item, itemIndex + firstItemIndex, $event)"-->
              <td v-if="hasDetails" style="width: 25px">
                <CIcon
                  v-if="itemHasDetails(item, itemIndex + firstItemIndex)"
                  :name="`cis-chevron-${
                    showDetailsRow(item, itemIndex + firstItemIndex)
                      ? 'bottom'
                      : 'right'
                  }`"
                  style="cursor: pointer"
                  @click.native="
                    toggleDetailsRow(item, itemIndex + firstItemIndex)
                  "
                />
              </td>
              <template v-for="(colName, index) in rawColumnNames">
                <td
                  :key="index"
                  :class="cellClass(item, colName, index)"
                  :style="cellStyle(item, colName, index)"
                >
                  <template v-if="$scopedSlots[colName]">
                    <slot
                      :name="colName"
                      :item="item"
                      :index="itemIndex + firstItemIndex"
                    />
                  </template>
                  <MCheckIcon
                    v-else-if="isBooleanColumn(colName, index)"
                    :value="getBooleanValue(item, colName, index)"
                    :show-false="shouldShowFalse(colName, index)"
                  />
                  <template v-else-if="isAddressColumn(colName, index)">
                    <span class="no-wrap">{{
                      getAddressValue(item, colName, index)
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="no-wrap">{{
                      getFormattedValue(item, colName, index)
                    }}</span>
                  </template>
                </td>
              </template>
            </tr>
            <!-- this retains the striped coloring -->
            <tr
              v-if="$scopedSlots.details && striped"
              :key="'details-striped' + itemIndex"
              class="invisible"
              aria-hidden="true"
            ></tr>
            <tr
              v-if="$scopedSlots.details"
              :key="`${item._rowKey}-${itemIndex}-details`"
              class="p-0 collapse"
              :class="{
                show: showDetailsRow(item, itemIndex + firstItemIndex),
              }"
              style="border: none !important; transition-duration: 1s"
            >
              <!--@click="
                rowClicked(item, itemIndex + firstItemIndex, $event, true)
              "-->
              <td
                :colspan="colspan"
                class="p-0"
                style="border: none !important"
              >
                <slot
                  name="details"
                  :item="item"
                  :index="itemIndex + firstItemIndex"
                />
              </td>
            </tr>
          </template>
          <tr
            v-if="!currentItems.length && !loading"
            class="m-table-empty-data-row"
          >
            <td :colspan="colspan">
              <slot name="no-items-view">
                <div class="text-center my-5">
                  <h2>
                    {{ noItemsText }}
                    <CIcon width="30" name="cil-ban" class="text-danger mb-2" />
                  </h2>
                </div>
              </slot>
            </td>
          </tr>
        </tbody>
        <!-- TODO: Footer -->
        <slot name="footer" :itemsAmount="currentItems.length" />
        <slot name="caption" />
      </table>
    </div>

    <slot name="under-table" />
  </div>
</template>
<script>
import { trimValue, valueAsBoolean, makeUid } from './utils';
import { tableProps as props } from './props';
import { mapRows, mapHiddenColumns, mapColumnFilterers } from './table-state';
import MTableDensityPicker from './MTableDensityPicker';
import MTablePaginator from './MTablePaginator';

export default {
  name: 'MDataTable',
  components: {
    MTableDensityPicker,
    MTablePaginator,
  },
  props,
  data() {
    return {
      activated: false,
      openedDetailRows: [],
      tableId: this.$attrs.id || makeUid(),
      densityState: this.density,
      tableFilterState: this.tableFilterValue,
      columnFilterState: {},
      sorterState: {
        column: null,
        asc: true,
      },
      pageState: this.page,
      perPageItems: this.perPage || 25,
      passedItems: mapRows(this.items || [], this.fields || []),
      hiddenColumnsState: mapHiddenColumns(this.fields || []),
      selectedRows: [],
      columnFilterers: mapColumnFilterers(this.fields || [], this.filterers),
    };
  },
  computed: {
    isExternallyFiltered() {
      if (this.tableFilter.external || this.columnFilter.external) {
        return true;
      }
      return false;
    },
    hasDetails() {
      return !!this.$scopedSlots.details;
    },
    inlineTableFilter() {
      if (this.hideControls) {
        return false;
      }
      if (this.showDensity || this.showColumnSelector) {
        return false;
      }
      return this.haveFilterOption;
    },
    showDensity() {
      return !this.hideControls && !this.hideDensity;
    },
    showColumnSelector() {
      return !this.hideControls && !this.hideColumnSelector;
    },
    columnToggleDropdownOptions() {
      const enabledCount = this.activeColumns.length;
      const hiddenColumns = this.hiddenColumnsState;
      let offset = 0;
      return this.fields
        .filter((f) => {
          if (f.toggleHidden !== false && !!f.label && !f.key.startsWith('_')) {
            return true;
          } else {
            offset = offset - 1;
            return true;
          }
        })
        .map((f) => {
          let hidden = hiddenColumns.includes(f.key);
          return {
            key: f.key,
            checked: !hidden,
            disabled:
              f.toggleable === false || (!hidden && enabledCount + offset < 2),
            label: f.label,
          };
        });
    },
    sidebarMinimize() {
      return this.$store.getters['sidebarMinimize'] === true;
    },
    currentPage: {
      get() {
        return this.pageState || 1;
      },
      set(v) {
        if (v !== this.pageState) {
          this.pageState = v || 1;
          this.$emit('update:page', this.pageState);
        }
      },
    },
    currentPageSize: {
      get() {
        return this.perPageItems;
      },
      set(v) {
        if (v !== this.perPageItems) {
          this.perPageItems = v || 25;
          this.$emit('update:per-page', this.perPageItems);
        }
      },
    },
    currentDensity: {
      get() {
        return this.densityState;
      },
      set(v) {
        if (v !== this.densityState) {
          this.densityState = v;
          this.$emit('update:density', v);
          if (this.sticky) {
            this.recalculateOffsets(this.$refs.table);
          }
        }
      },
    },
    tableWrapperClasses() {
      return {
        'table-responsive': this.sticky || this.responsive,
        'table-sticky': this.sticky,
        'table-fixed': this.fixed,
      };
    },
    columnFiltered() {
      const filters = Object.keys(this.columnFilterState).filter((k) => {
        if (this.itemsDataColumns.includes(k)) {
          let v = this.columnFilterState[k];
          return (!!v || v === 0 || v === false) && v !== '';
        } else {
          return false;
        }
      });

      return this.passedItems.filter((row) => {
        for (var key of filters) {
          let filterer = this.$_.get(this.columnFilterers, key);
          if (!filterer) {
            continue;
          } else {
            if (!filterer(this.columnFilterState[key], row, key)) {
              return false;
            }
          }
        }

        return true;
      });
    },
    itemsDataColumns() {
      return this.rawColumnNames.filter((c) => {
        return !this.hiddenColumnsState.includes(c);
      });
    },
    tableFiltered() {
      let items = this.columnFiltered;
      if (
        !this.tableFilterState ||
        (this.tableFilter && this.tableFilter.external)
      ) {
        return items;
      }
      const filter = this.tableFilterState.toLowerCase();
      items = items.filter((i) => i._searchKey.includes(filter));
      return items;
    },
    sortedItems() {
      const col = this.sorterState.column;
      if (!col || !this.rawColumnNames.includes(col) || this.sorter.external) {
        return this.tableFiltered;
      }

      // if values in column are to be sorted by numeric value they all have to be type number
      const flip = this.sorterState.asc ? 1 : -1;
      const column = this.fields.find((f) => f.key === col);

      return this.tableFiltered.slice().sort((item, item2) => {
        let fieldPath = column.fieldPath || col;
        if (column.fieldType === 'GEO') {
          fieldPath = `${fieldPath}.displayName`;
        }

        const value = trimValue(item, fieldPath);
        const value2 = trimValue(item2, fieldPath);
        const a = typeof value === 'number' ? value : this.$_.lowerCase(value);
        const b =
          typeof value2 === 'number' ? value2 : this.$_.lowerCase(value2);
        return a > b ? 1 * flip : b > a ? -1 * flip : 0;
      });
    },
    firstItemIndex() {
      return (this.computedPage - 1) * this.perPageItems || 0;
    },
    paginatedItems() {
      return this.sortedItems.slice(
        this.firstItemIndex,
        this.firstItemIndex + this.perPageItems,
      );
    },
    currentItems() {
      return this.computedPage ? this.paginatedItems : this.sortedItems;
    },
    totalPages() {
      if ((this.pageCount || this.pageCount === 0) && this.pageCount >= 0) {
        return this.pageCount;
      }

      let total = this.sortedItems.length;
      if (this.totalRows > 0) {
        total = this.totalRows;
      }
      return Math.ceil(total / this.perPageItems) || 1;
    },
    computedPage() {
      return this.pagination ? this.pageState : this.page;
    },
    rawColumnNames() {
      if (this.activeColumns) {
        return this.activeColumns.map((el) => el.key || el);
      }
      return [];
    },
    activeColumns() {
      if (this.fields) {
        if (this.hiddenColumnsState) {
          return this.fields.filter(
            (f) => !this.hiddenColumnsState.includes(f.key),
          );
        } else {
          return this.fields;
        }
      }
      return [];
    },
    columnNames() {
      if (this.activeColumns) {
        return this.activeColumns.map((f) => {
          return f.label !== undefined ? f.label : f.key || f;
        });
      }
      return [];
    },
    tableClasses() {
      return [
        this.addTableClasses,
        {
          activated: this.activated,
          'table-sm': this.small,
          'table-dark': this.dark,
          'table-striped': this.striped,
          'table-hover': this.hover || this.focusable,
          'table-bordered': this.border,
          'dt-density-comfortable': this.currentDensity === 'comfortable',
          'dt-density-dense': this.currentDensity === 'dense',
          'dt-density-standard':
            this.currentDensity !== 'comfortable' &&
            this.currentDensity !== 'dense',
          border: this.outlined,
        },
      ];
    },
    sortingIconStyles() {
      return { 'position-relative pr-4': this.sorter };
    },
    colspan() {
      let additions = 0;
      if (this.hasDetails) {
        additions += 1;
      }

      return this.rawColumnNames.length + additions;
    },
    noItemsText() {
      const customValues = this.noItemsView || {};
      if (this.passedItems.length) {
        return customValues.noResults || 'No filtering results';
      }
      return customValues.noItems || 'No items';
    },
    isFiltered() {
      return (
        this.tableFilterState ||
        Object.values(this.columnFilterState).join('') ||
        this.sorterState.column
      );
    },
    cleanerProps() {
      return {
        name: 'cil-filter-x',
        class: `${this.isFiltered ? 'text-danger' : 'transparent'}`,
        role: this.isFiltered ? 'button' : null,
        tabindex: this.isFiltered ? 0 : null,
      };
    },
    haveFilterOption() {
      return (
        this.tableFilter ||
        this.cleaner ||
        this.$scopedSlots.cleaner ||
        this.$scopedSlots['table-controls']
      );
    },
    hasTableControls() {
      return !!this.$scopedSlots['table-controls'];
    },
  },
  watch: {
    sidebarMinimize() {
      if (this.sticky && this.activated) {
        const self = this;

        // The timeout is required for the sidebar animation
        // to finish so the resize works correctly.
        window.setTimeout(() => {
          self.recalculateOffsets(self.$refs.table);
        }, 375);
      }
    },
    page(val) {
      if (val !== this.pageState) {
        this.openedDetailRows = [];
      }
      this.pageState = val;
    },
    perPage(val) {
      if (val !== this.perPageItems) {
        this.openedDetailRows = [];
      }
      this.perPageItems = val;
    },
    sorterValue: {
      immediate: true,
      handler(val) {
        if (val) {
          const asc = val.asc === false ? false : true;
          this.sorterState = Object.assign({}, { asc, column: val.column });
        } else {
          this.sorterState = { asc: true, column: null };
        }
      },
    },
    tableFilterValue(val) {
      this.tableFilterState = val;
    },
    columnFilterValue: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.columnFilterState = { ...val };
        } else {
          this.columnFilterState = {};
        }
      },
    },
    items(val) {
      this.openedDetailRows = [];
      this.passedItems = mapRows(val || [], this.fields || []);
    },
    fields: {
      immediate: true,
      deep: true,
      handler(val) {
        this.hiddenColumnsState = mapHiddenColumns(val || []);
        this.columnFilterers = mapColumnFilterers(val || [], this.filterers);
        this.passedItems = mapRows(this.items, val || []);
      },
    },
    // totalPages: {
    //   immediate: true,
    //   handler(val) {
    //     this.$emit('update:total-pages', val);
    //   },
    // },
    // computedPage(val) {
    //   this.$emit('update:page', val);
    // },
    // sortedItems: {
    //   immediate: true,
    //   handler(val) {
    //     // if (val && oldVal && this.objectsAreIdentical(val, oldVal)) {
    //     //   return;
    //     // }
    //     this.$emit('update:filtered-items', val);
    //   },
    // },
  },
  mounted() {
    // document.onkeydown = this.handleArrowKeys;
    this.activated = true;
    if (this.sticky) {
      this.recalculateOffsets(this.$refs.table);
    }
  },
  beforeDestroy() {
    this.activated = false;
  },
  activated() {
    // this.$log.debug(`Table '${this.tableId}' activated`);
    this.activated = true;
  },
  deactivated() {
    // this.$log.debug(`Table '${this.tableId}' deactivated`);
    this.activated = false;
  },
  methods: {
    itemHasDetails(item) {
      return item.showDetails !== false;
    },
    showDetailsRow(_row, index) {
      return this.openedDetailRows.includes(index);
    },
    changeSort(column, index) {
      if (this.loading) {
        return;
      }

      if (!this.isSortable(index)) {
        return;
      }
      //if column changed or sort was descending change asc to true
      const state = this.sorterState;
      const columnRepeated = state.column === column;
      if (!this.sorter || !this.sorter.resetable) {
        state.column = column;
      } else {
        state.column = columnRepeated && state.asc === false ? null : column;
      }
      state.asc = !(columnRepeated && state.asc);
      this.$emit('update:sorter-value', this.sorterState);
    },
    columnFilterEvent(colName, value, type) {
      const isLazy = this.columnFilter && this.columnFilter.lazy === true;
      if ((isLazy && type === 'input') || (!isLazy && type === 'change')) {
        return;
      }
      this.$set(this.columnFilterState, colName, value);
      this.$emit('update:column-filter-value', this.columnFilterState);
    },
    tableFilterChange(value, type) {
      const isLazy = this.tableFilter && this.tableFilter.lazy === true;
      if ((isLazy && type === 'input') || (!isLazy && type === 'change')) {
        return;
      }
      this.tableFilterState = value;
      this.$emit('update:table-filter-value', this.tableFilterState);
    },
    cellClass(item, colName, index) {
      const fields = this.activeColumns;
      let classes = [];
      if (this.fixed) {
        classes.push('no-wrap');
      }

      if (item._cellClasses && item._cellClasses[colName]) {
        classes.push(item._cellClasses[colName]);
      }
      if (fields && fields[index]._classes) {
        classes.push(fields[index]._classes);
      }
      return classes;
    },
    isSortable(index) {
      const fields = this.activeColumns;
      return (
        this.sorter &&
        this.$_.get(fields[index], 'sorter') !== false &&
        this.itemsDataColumns.includes(this.rawColumnNames[index])
      );
    },
    isFilterable(index) {
      const fields = this.activeColumns;
      return (
        this.$_.get(fields[index], 'filter') !== false &&
        this.itemsDataColumns.includes(this.rawColumnNames[index])
      );
    },
    headerClass(index) {
      const fields = this.activeColumns;
      let classes = [];
      if (this.fixed) {
        classes.push('no-wrap');
      }
      if (this.isSortable(index)) {
        classes.push('sorting');
      }
      if (fields && fields[index] && fields[index]._classes) {
        classes.push(fields[index]._classes);
      }
      return classes;
    },
    filterHeaderClass(index) {
      const fields = this.activeColumns;
      let classes = ['m-table-filter-col'];
      if (fields && fields[index] && fields[index]._classes) {
        classes.push(fields[index]._classes);
      }
      return classes;
    },
    cellStyle(index) {
      let fields = this.activeColumns;
      return this.$_.get(fields[index], '_style');
    },
    headerStyles(index) {
      let style = '';
      if (
        this.activeColumns &&
        this.activeColumns[index] &&
        this.activeColumns[index]._style
      ) {
        style += this.fields[index]._style;
      }
      return style;
    },
    toggleDetailsRow(item, index) {
      if (this.openedDetailRows.includes(index)) {
        this.openedDetailRows = this.openedDetailRows.filter(
          (i) => i !== index,
        );
      } else {
        this.openedDetailRows.push(index);
      }
    },
    rowClicked(item, index, e, detailsClick = false) {
      this.$emit(
        'row-clicked',
        item,
        index,
        this.getClickedColumnName(e, detailsClick),
        e,
      );
    },
    getClickedColumnName(e, detailsClick) {
      if (detailsClick) {
        return 'details';
      } else {
        const children = Array.from(e.target.closest('tr').children);
        const clickedCell = children.filter((child) =>
          child.contains(e.target),
        )[0];
        return this.rawColumnNames[children.indexOf(clickedCell)];
      }
    },
    getIconState(index) {
      const direction = this.sorterState.asc ? 'asc' : 'desc';
      return this.rawColumnNames[index] === this.sorterState.column
        ? direction
        : 0;
    },
    iconClasses(index) {
      const state = this.getIconState(index);
      return [
        'icon-transition position-absolute arrow-position',
        {
          transparent: !state,
          'rotate-icon': state === 'desc',
        },
      ];
    },
    // objectsAreIdentical(obj1, obj2) {
    //   return (
    //     obj1.length === obj2.length &&
    //     JSON.stringify(obj1) === JSON.stringify(obj2)
    //   );
    // },
    clean() {
      this.tableFilterState = '';
      this.columnFilterState = {};
      this.sorterState = { column: '', asc: true };
      this.$emit('clean');
      this.$emit('update:column-filter-value', {});
      this.$emit('update:table-filter-value', null);
      this.$emit('update:sorter', { column: '', asc: true });
    },
    toggleColumn(columnKey) {
      const idx = this.hiddenColumnsState.indexOf(columnKey);
      if (idx >= 0) {
        this.hiddenColumnsState.splice(idx, 1);
      } else {
        this.hiddenColumnsState.push(columnKey);
      }
      this.recalculateOffsets(this.$refs.table);
    },
    isAddressColumn(key, index) {
      const fields = this.activeColumns;
      const column = fields[index];
      if (!column) {
        return false;
      }

      if (
        'ZONEADDRESS' ===
        this.$_.upperCase(column.fieldType)
          .replaceAll(' ', '')
          .replaceAll('_', '')
      ) {
        return true;
      }

      return false;
    },
    shouldShowFalse(key, index) {
      const fields = this.activeColumns;
      const column = fields[index];
      if (!column) {
        return false;
      }
      return column.showFalse === true;
    },
    isBooleanColumn(key, index) {
      const fields = this.activeColumns;
      const column = fields[index];
      if (!column) {
        return false;
      }

      if ('BOOLEAN' === this.$_.upperCase(column.fieldType)) {
        return true;
      }

      return false;
    },
    getAddressValue(row, key, index) {
      const v = this.getFieldValue(row, key, index);
      if (v) {
        let division =
          v.division || v.mainDivision || v.province || v.state || null;
        if (v.country && division) {
          return `${division}, ${v.country}`;
        } else if (v.country) {
          return v.country;
        } else if (division) {
          return division;
        } else {
          return null;
        }
      }

      return null;
    },
    getFieldValue(row, key, index) {
      const fields = this.activeColumns;
      const column = fields[index];
      return this.$_.get(row, column.fieldPath || key);
    },
    getBooleanValue(row, key, index) {
      return valueAsBoolean(this.getFieldValue(row, key, index));
    },
    formatGeo(value) {
      const key = trimValue(value, 'key');
      const displayName = trimValue(value, 'displayName');
      if (!!key && !!displayName) {
        return `${key} - ${displayName}`;
      }
      return this.$_.toString(value);
    },
    getFormattedValue(row, key, index) {
      const fields = this.activeColumns;
      const column = fields[index];

      const value = this.getFieldValue(row, key, index);
      switch (this.$_.get(column, 'fieldType', 'text').toUpperCase()) {
        case 'DATE':
          return this.$format.date(value, column.format);
        case 'CURRENCY':
          return this.$format.currency(value);
        case 'NUMBER':
          return this.$format.number(value);
        case 'GEO':
          return this.formatGeo(value, column);
        default:
          return trimValue(row, column.fieldPath || key);
      }
    },
    // handleArrowKeys(event) {
    //   const e = event || window.event;
    //   const anythingSelected = this.selectedRows.length > 0;
    //   const keyCode = parseInt(e.keyCode);

    //   if (!anythingSelected) {
    //     return;
    //   }

    //   switch (keyCode) {
    //     case 38:
    //       this.pressedArrow(-1);
    //       break;
    //     case 40:
    //       this.pressedArrow(1);
    //       break;
    //   }
    // },
    handleResize() {
      if (!this.sticky || !this.activated) {
        return;
      }
      this.recalculateOffsets(this.$refs.table);
    },
    recalculateOffsets(stickyTable) {
      this.$nextTick(() => {
        if (!this.sticky || !stickyTable) {
          // this.$log.debug(`No element for '${this.tableId}, skipping refresh.`);
          return;
        }
        // if (!stickyTable.className.includes('activated')) {
        //   this.$log.debug(
        //     `Skipping refresh for '${this.tableId}, not activated`,
        //   );
        //   return;
        // }
        this.$log.debug(`Refreshing '${this.tableId}' sticky headers`);
        const thead = stickyTable.getElementsByTagName('thead')[0];

        if (!thead) {
          this.$log.warn(
            `No thead found in table '${this.tableId}' during sticky header resize`,
          );
          return;
        }

        // For each header row, keep adding height to this because we will need to set the 'top' value
        // to the correct height to pin it to the bottom of the row just above it.
        let cumulativeHeight = 0;
        const headerRows = thead.getElementsByTagName('tr');
        for (var row of headerRows) {
          const addlHeight = parseInt(row.offsetHeight, 10);
          // CSS takes care of the row pinned at zero, so only work on the rows after that.
          if (cumulativeHeight > 0) {
            const topStyle = `top: ${cumulativeHeight}px`;
            const cols = row.getElementsByTagName('th');
            for (var col of cols) {
              // Set the top style last so it'll override any other previous top values.
              col.style.cssText = `${col.style.cssText};${topStyle}`;
            }
          }
          cumulativeHeight += addlHeight;
        }
      });
    },
  },
};
</script>
