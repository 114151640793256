import _ from 'lodash';

export function getEntityIdOrKey(value) {
  if (!value) {
    return null;
  }

  if (_.isString(value)) {
    return value;
  }

  return _.get(value, 'key', _.get(value, 'id', _.get(value, 'value')));
}
