import config from '../config';
import { joinToString } from '@/utils/helpers';

const client = config.client;
const baseUrl = config.baseUrl;

export default {
  execute(request, taxEngineType) {
    return client
      .post(
        `${baseUrl}/${taxEngineType.toLowerCase()}/testing/execute`,
        request,
      )
      .then((res) => res.data);
  },
  cancelTestRun(id, taxEngineType) {
    return client
      .post(`${baseUrl}/${taxEngineType.toLowerCase()}/testing/cancel/${id}`)
      .then(() => true);
  },
  deleteTestRunResult(id, taxEngineType) {
    return client
      .delete(`${baseUrl}/${taxEngineType.toLowerCase()}/testing/results/${id}`)
      .then(() => true);
  },
  getAllRecentResults() {
    return client.get(`${baseUrl}/testing/recent`).then((res) => res.data);
  },
  getTestRun(id) {
    return client.get(`${baseUrl}/testing/${id}`).then((res) => res.data);
  },
  getTestRunResults(
    id,
    queryArgs = { page: 0, size: 50, sort: 'INVOICE.INVOICE_NUMBER' },
  ) {
    queryArgs.shipTo = joinToString(queryArgs?.shipTo);
    queryArgs.shipFrom = joinToString(queryArgs?.shipFrom);
    queryArgs.transactionTypes = joinToString(queryArgs?.transactionTypes);
    queryArgs.companyRole = joinToString(queryArgs?.companyRole);
    queryArgs.recordStatus = joinToString(queryArgs?.recordStatus);
    queryArgs.documentTypes = joinToString(queryArgs?.documentTypes);
    queryArgs.currencyCodes = joinToString(queryArgs?.currencyCodes);

    return client
      .get(`${baseUrl}/testing/results/${id}`, {
        params: {
          ...queryArgs,
        },
      })
      .then((res) => res.data);
  },
};
