<template>
  <MSelect
    :id="id"
    :placeholder="placeholder"
    :label="label"
    filterable
    :clearable="!required"
    :loading="loadingCountries"
    :options="countryOptions"
    :value.sync="selectedValue"
    :invalid-feedback="invalidFeedback"
    :is-valid="isValid"
    :required="required"
    :readonly="readonly"
    :disabled="disabled"
  />
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MCountryRegistrationPicker',
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    invalidFeedback: {
      type: String,
      required: false,
      default: null,
    },
    isValid: {
      type: Boolean,
      required: false,
      default: null,
    },
    value: {
      type: [String, Object],
      required: false,
      default() {
        return null;
      },
    },
    // When true, show the ISO code before the display name.
    showCode: {
      type: Boolean,
      required: false,
      default: false,
    },
    // When true, only return the geo key instead of the whole object.
    keyOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      default: 'Country',
    },
    placeholder: {
      type: String,
      default: 'Select a Country',
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      internalValue: this.getGeo(this.value),
    };
  },
  computed: {
    ...mapGetters('registrations', ['countries', 'loadingCountries']),
    // Prepare the countries collection for the select component.
    countryOptions() {
      if (!this.countries || this.countries.length === 0) {
        return [];
      }

      return Object.freeze(
        this.countries
          .map((c) => {
            return {
              id: c.key,
              value: c,
              label: this.makeLabel(c),
            };
          })
          .sort((a, b) => {
            if (a.label > b.label) {
              return 1;
            } else if (a.label < b.label) {
              return -1;
            } else {
              return 0;
            }
          }),
      );
    },
    selectedValue: {
      get() {
        return this.internalValue;
      },
      set(v) {
        if (!(this.loading || !this.countries || this.countries.length === 0)) {
          if (!v) {
            this.internalValue = null;
            this.$emit('update:value', null);
          } else {
            this.internalValue = v;
            let emitValue = this.keyOnly ? v.key : v;
            this.$emit('update:value', emitValue);
          }
        }
      },
    },
  },
  watch: {
    value(v) {
      this.internalValue = this.getGeo(v);
    },
  },
  mounted() {
    if (this.value && !this.internalValue) {
      this.internalValue = this.getGeo(this.value);
    }
  },
  beforeMount() {
    if (this.$_.isEmpty(this.countries)) {
      this.$store
        .dispatch('registrations/getOrFetchCountries')
        .then((fetched) => {
          this.internalValue = this.getGeo(
            this.value,
            (fetched || []).map((i) => {
              return {
                id: i.key,
                value: i,
                label: this.makeLabel(i),
              };
            }),
          );
        })
        .catch((err) => {
          this.$log.error(err);
        });
    }
  },
  methods: {
    getGeo(v, options = null) {
      if (!v) {
        return null;
      }
      const geoKey = this.getGeoKey(v);

      if (!geoKey) {
        return null;
      }

      let entries = !options ? this.countryOptions : options;
      return (entries || []).find((c) => {
        return c.id === geoKey || c.key === geoKey || c.value.geoKey === geoKey;
      });
    },
    getGeoKey(v) {
      return this.$entities.idOrKey(v);
    },
    makeLabel(v) {
      if (this.showCode) {
        return `${v.primaryCode} - ${v.displayName}`;
      } else {
        return v.displayName;
      }
    },
  },
};
</script>
