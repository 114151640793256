export const makeUid = () => {
  const key = Math.random().toString(36).substr(2);
  return 'uid-' + key;
};

export const sharedComputedProps = {
  computed: {
    computedIsValid() {
      if (typeof this.isValid === 'function') {
        return this.isValid(this.state);
      }
      return this.isValid;
    },
    validationClass() {
      if (typeof this.computedIsValid === 'boolean') {
        return this.computedIsValid ? 'is-valid' : 'is-invalid';
      }
    },
    safeId() {
      if (this.inputId || this.$attrs.inputId) {
        return this.inputId || this.$attrs.inputId;
      }

      if (this.id || this.$attrs.id) {
        return this.id || this.$attrs.id;
      }

      return makeUid();
    },
  },
};

export const wrapperComputedProps = {
  computed: {
    isHorizontal() {
      return Boolean(this.horizontal);
    },
    haveInputGroup() {
      return Boolean(
        this.tooltipFeedback ||
          this.append ||
          this.prepend ||
          this.$slots.append ||
          this.$slots.prepend ||
          this.$slots['append-content'] ||
          this.$slots['prepend-content'],
      );
    },
    haveWrapper() {
      return (
        this.haveInputGroup ||
        Boolean(this.addWrapperClasses || this.isHorizontal)
      );
    },
    wrapperClasses() {
      if (this.haveWrapper) {
        return [
          this.addWrapperClasses,
          {
            [this.horizontal.input || 'col-sm-9']: this.isHorizontal,
            'input-group': this.haveInputGroup,
            [`input-group-${this.size}`]: this.haveCustomSize,
          },
        ];
      }
    },
  },
};

export const watchValue = {
  watch: {
    value(val) {
      this.state = val;
    },
  },
};

export const classesComputedProps = {
  computed: {
    haveCustomSize() {
      return ['sm', 'lg'].includes(this.size);
    },
    computedClasses() {
      return [
        'form-group',
        {
          'was-validated': this.wasValidated,
          'form-row': this.isHorizontal,
        },
      ];
    },
    labelClasses() {
      return [
        this.addLabelClasses,
        {
          'col-form-label': this.isHorizontal,
          [this.horizontal.label || 'col-sm-3']: this.isHorizontal,
          [`col-form-label-${this.size}`]: this.haveCustomSize,
        },
      ];
    },
    customSizeClass() {
      if (this.haveCustomSize && !this.haveWrapper) {
        return `form-control-${this.size}`;
      }
    },
    inputClasses() {
      return [
        this.inputClass || `form-control${this.plaintext ? '-plaintext' : ''}`,
        this.validationClass,
        this.addInputClasses,
        this.customSizeClass,
      ];
    },
  },
};

export const pickerMixin = {
  computed: {
    mSelectProps() {
      return {
        id: this.$attrs.id || this.id || makeUid(),
        readonly: this.readonly,
        required: this.required,
        disabled: this.disabled || this.readonly,
        multiple: this.multiple,
        label: this.label,
        placeholder: this.placeholder,
        isValid: this.isValid,
        validFeedback: this.validFeedback,
        invalidFeedback: this.invalidFeedback,
        description: this.description,
        tooltipFeedback: this.tooltipFeedback,
        wasValidated: this.wasValidated,
        clearable: !(this.required || this.readonly) && this.clearable,
        loading: this.loading === true,
        filterable: this.filterable !== false,
        optionValueKey: this.keyOnly ? 'value' : undefined,
        keyOnly: this.keyOnly,
      };
    },
    selectedValue: {
      get() {
        return this.internalValue;
      },
      set(v) {
        this.internalValue = v;
        var selectedValue = v;
        if (this.keyOnly && this.$_.isObject(v)) {
          selectedValue = this.$entities.idOrKey(v) || v.value || v;
        }

        this.$emit('update:value', selectedValue);
      },
    },
  },
  data() {
    return {
      loading: false,
      internalValue: this.$entities.idOrKey(this.value),
    };
  },
  watch: {
    value(v) {
      this.internalValue = this.$entities.idOrKey(v);
    },
  },
  methods: {
    sort(items) {
      if (!items || !items.length) {
        return items;
      }

      let sorted = JSON.parse(JSON.stringify(items));
      sorted.sort((a, b) => {
        if (!a.label && !b.label) {
          return 0;
        } else if (!b.label || a.label > b.label) {
          return 1;
        } else if (!a.label || a.label < b.label) {
          return -1;
        } else {
          return 0;
        }
      });
      return sorted;
    },
  },
};

export const clientPickerMixin = {
  data() {
    return {
      loading: false,
      internalValue: this.$entities.idOrKey(this.value),
    };
  },
  computed: Object.assign({}, pickerMixin.computed, {
    clientId() {
      if (!this.client) {
        return null;
      }

      return this.getClientId(this.client);
    },
    isLoading() {
      return this.loading === true;
    },
    mSelectProps() {
      return {
        id: this.$attrs.id || this.id || makeUid(),
        readonly: this.readonly,
        required: this.required,
        disabled: !this.clientId || this.disabled || this.readonly,
        multiple: this.multiple,
        label: this.label,
        placeholder: this.placeholder,
        isValid: this.isValid,
        validFeedback: this.validFeedback,
        invalidFeedback: this.invalidFeedback,
        description: this.description,
        tooltipFeedback: this.tooltipFeedback,
        wasValidated: this.wasValidated,
        clearable: !(this.required || this.readonly) && this.clearable,
        loading: this.loading === true,
        filterable: this.filterable !== false,
        optionValueKey: this.keyOnly ? 'value' : undefined,
      };
    },
  }),
  methods: Object.assign({}, pickerMixin.methods, {
    fetchData(clientId) {
      this.loading = true;
      return this.fetchClientData(clientId).finally(
        () => (this.loading = false),
      );
    },
    fetchClientData() {
      return Promise.reject(
        new Error('Fetch client data method not implemented'),
      );
    },
    getClientId(client) {
      if (client) {
        if (this.$_.isObject(client)) {
          return (
            this.$_.get(client, 'id', this.$_.get(client, 'clientId')) || client
          );
        } else {
          return client;
        }
      }

      return null;
    },
  }),
  watch: Object.assign({}, pickerMixin.watch, {
    client(v) {
      const clientId = this.getClientId(v);
      if (clientId) {
        this.fetchData(clientId);
      }
    },
  }),
  beforeMount() {
    const clientId = this.getClientId(this.client);
    if (clientId) {
      this.fetchData(clientId);
    }
  },
};
