import { logger } from '@/utils/logger';

const state = {
  loadingCount: 0,
};

const getters = {
  loading(state) {
    return state.loadingCount > 0;
  },
};

const mutations = {
  incrementLoading(state) {
    state.loadingCount++;
  },
  decrementLoading(state) {
    state.loadingCount--;
  },
};

const actions = {
  startRequest({ commit }) {
    commit('incrementLoading');
  },
  finishRequest({ commit }, error) {
    commit('decrementLoading');
    if (error) {
      logger.debug('Request finished with error', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
