<template>
  <div class="dt-pagination-container" aria-hidden="true">
    <CSelect
      v-show="currentTotalPages > 1"
      class="dt-pagination-selector dt-pagination-control"
      :options="pageDropDownOptions"
      :value.sync="activePage"
      :filter="false"
    >
      <template #prepend-content>Page</template>
      <template #append>
        <div class="input-group-append dt-pagination-control-group">
          <a
            href="javascript:void(0);"
            class="input-group-text dt-pagination-control"
            role="button"
            aria-label="Previous"
            :class="{ disabled: isPrvDisabled }"
            :aria-disabled="isPrvDisabled"
            @click.prevent="previousPage()"
            ><CIcon name="cis-arrow-left"
          /></a>
          <a
            href="javascript:void(0);"
            class="input-group-text dt-pagination-control"
            :class="{ disabled: isNextDisabled }"
            :aria-disabled="isNextDisabled"
            role="button"
            aria-label="Next"
            @click.prevent="nextPage()"
            ><CIcon name="cis-arrow-right"
          /></a>
        </div>
      </template>
    </CSelect>

    <div
      v-show="totalRows > 0"
      class="dt-pagination-selector"
      aria-hidden="true"
    >
      <CSelect
        class="dt-pagination-control"
        :options="pageSizeOptions"
        :value.sync="currentPageSize"
        :filter="false"
        :disabled="pageSizeOptions.length < 2"
      >
        <template #prepend-content> Size </template>
      </CSelect>
    </div>
    <div
      v-show="showPaginationRange"
      class="dt-pagination-range dt-pagination-control"
      aria-hidden="true"
    >
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text dt-pagination-total-label">&#35;</span>
        </div>
        <div class="form-control dt-pagination-range-label">
          {{ rangeText }}
        </div>
        <div class="input-group-append">
          <span class="input-group-text dt-pagination-total-label"
            >of {{ currentTotalRows }}</span
          >
        </div>
      </div>
    </div>
    <div
      v-if="showPaginationRange"
      class="dt-pagination-control dt-pagination-total"
    >
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">&#35;</span>
        </div>
        <div class="form-control dt-pagination-total-label">
          {{ currentTotalRows }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { paginatorProps as props } from './props';
export default {
  name: 'MTablePaginator',
  props,
  data() {
    return {
      pageState: this.page,
      pageSizeState: this.perPage,
    };
  },
  computed: {
    isNextDisabled() {
      return this.activePage >= this.currentTotalPages;
    },
    isPrvDisabled() {
      return this.activePage <= 1;
    },
    pageDropDownOptions() {
      let opts = [];
      for (var i = 1; i <= this.currentTotalPages; i++) {
        opts.push(i);
      }

      return opts;
    },
    rangeText() {
      return `${this.paginationStart + 1} - ${this.paginationEnd}`;
    },
    showPaginationRange() {
      return this.totalRows > 0;
    },
    paginationStart() {
      return (this.activePage - 1) * this.currentPageSize;
    },
    paginationEnd() {
      let end = this.paginationStart + this.currentPageSize;
      if (this.filtering && end > this.filteredRows) {
        end = this.filteredRows;
      }

      if (end > this.totalRows) {
        end = this.totalRows;
      }

      return end;
    },
    pageSizeOptions() {
      return this.pageSizes;
    },
    currentTotalRows() {
      if (this.filtering) {
        return this.filteredRows;
      }

      return this.totalRows;
    },
    hasRecords() {
      return this.currentTotalRows > 0;
    },
    currentTotalPages() {
      return Math.ceil(this.currentTotalRows / this.currentPageSize);
    },
    currentPageSize: {
      get() {
        return this.pageSizeState;
      },
      set(v) {
        this.pageSizeState = v;
        this.$emit('update:per-page', v);
      },
    },
    activePage: {
      get() {
        return this.pageState;
      },
      set(v) {
        this.pageState = v;
        this.$emit('update:page', v);
      },
    },
    paginatorProps() {
      let p = {
        pages: this.currentTotalPages,
        align: 'end',
        dots: true,
        arrows: true,
        doubleArrows: this.currentTotalPages > 4,
      };

      return p;
    },
  },
  watch: {
    page: {
      immediate: true,
      handler(v) {
        this.pageState = v > 0 ? v : 1;
      },
    },
    perPage(v) {
      if (v < 5) {
        v = 5;
      }
      this.pageSizeState = v;
    },
    filteredRows(v) {
      if (this.activePage * this.currentPageSize > v) {
        if (this.filtering) {
          this.activePage = 1;
        }
      }
    },
  },
  methods: {
    previousPage() {
      if (this.isPrvDisabled) {
        return;
      }

      this.activePage = this.activePage - 1;
    },
    nextPage() {
      if (this.isNextDisabled) {
        return;
      }
      this.activePage = this.activePage + 1;
    },
  },
};
</script>
