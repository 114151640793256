import config from './config';

const client = config.client;
const baseUrl = config.baseUrl;

export default {
  download(path) {
    let cleanedPath = path;
    if (path.startsWith('/')) {
      cleanedPath = path.substring(1);
    }

    const fullPath = `${baseUrl}/${cleanedPath}`.replace('api/api', 'api');

    return new Promise((resolve, reject) => {
      try {
        client
          .get(fullPath, { responseType: 'blob' })
          .then((res) => {
            let filename =
              res.headers['content-disposition'].split('filename=')[1];
            let type = res.headers['content-type'];
            if (type === 'application/octet-stream') {
              if (filename.endsWith('.csv')) {
                type = 'text/csv';
              }
            }

            resolve({
              headers: res.headers,
              data: res.data,
              contentType:
                res.headers['content-type'] || 'application/octet-stream',
              filename: filename,
            });
          })
          .catch((err) => {
            reject(err);
          });
      } catch (err) {
        reject(err);
      }
    });
  },
};
