import {
  trimValue,
  booleanFilter,
  stringFilter,
  dateFilter,
  geoFilter,
} from './utils';
import { md5 } from '@/utils/helpers';
import _ from 'lodash';
function char(cp) {
  return String.fromCharCode(cp);
}

export function mapHiddenColumns(columns = []) {
  return columns
    .filter((c) => c.hidden === true)
    .reduce((acc, c) => {
      acc.push(c.key);
      return acc;
    }, []);
}

export function mapRows(rows = [], columns = []) {
  if (!rows || rows.length < 1) {
    return [];
  }

  // Map of column.key => column.fieldPath || column.key for filter data retrieval.
  const filterFieldPaths = {};

  const filterKeys = columns.reduce((acc, col) => {
    // Ignore 'hidden' columns and columns marked to not filter
    if (col.filter !== false && col.label && !col.key.startsWith('_')) {
      acc.push(col.key);
      if (col.fieldType === 'GEO') {
        if (col.fieldPath) {
          filterFieldPaths[col.key] = `${col.fieldPath}.displayName`;
        } else {
          filterFieldPaths[col.key] = `${col.key}.displayName`;
        }
      } else {
        filterFieldPaths[col.key] = col.fieldPath || col.key;
      }
    }
    return acc;
  }, []);

  return rows.map((row, index) => {
    // Combine all the searchable rows into one big string for fast table searching.
    const searchKey = filterKeys
      .reduce((acc, key) => {
        const keyValue = trimValue(row, filterFieldPaths[key]);
        if (!keyValue || keyValue === '') {
          return acc;
        } else {
          return `${acc}${char(2)}${keyValue}${char(3)}`;
        }
      }, '')
      .trim()
      .toLowerCase();

    return Object.freeze({
      ...row,
      _rawIndex: index,
      _rowKey: md5(JSON.stringify(row)),
      _searchKey: searchKey,
    });
  });
}

/**
 * Generate a map of functions to use when filtering columns.
 * @param {Array<Field>} fields Array of column definitions to map filter functions for.
 * @param {Map<String, Function>} filterers Map of custom filter functions to use, keyed by field key.
 * @returns Map<String, Function> Map of filter functions, by field.key.
 */
export function mapColumnFilterers(fields, filterers) {
  return Object.freeze(
    fields.reduce((obj, f) => {
      if (f.filter !== false) {
        if (_.has(filterers, f.key)) {
          obj[f.key] = filterers[f.key];
        } else {
          switch (f.fieldType) {
            case 'BOOLEAN':
              obj[f.key] = booleanFilter();
              break;
            case 'DATE':
              obj[f.key] = dateFilter(f);
              break;
            case 'GEO':
              obj[f.key] = geoFilter(f);
              break;
            default:
              obj[f.key] = stringFilter();
              break;
          }
        }
      }
      return obj;
    }, {}),
  );
}
