import { defaultPageSizes, tableSizes } from './utils';

const sharedPaginationProps = {
  page: {
    type: Number,
    default: 1,
  },
  perPage: {
    type: Number,
    default: 25,
  },
  pageSizes: {
    type: Array,
    default() {
      return defaultPageSizes;
    },
  },
};

const sharedDensityProps = {
  density: {
    type: String,
    default: 'standard',
    validation(v) {
      return tableSizes.indexOf(v) >= 0;
    },
  },
};

export const densityProps = Object.assign({}, sharedDensityProps, {
  disabled: Boolean,
});

export const paginatorProps = Object.assign({}, sharedPaginationProps, {
  totalPages: {
    type: Number,
    default: -1,
  },
  totalRows: {
    type: Number,
    default: 0,
  },
  filteredRows: {
    type: Number,
    default: 0,
  },
  filtering: Boolean,
  disabled: Boolean,
});

const tableFlagProps = {
  striped: Boolean,
  fixed: Boolean,
  hover: Boolean,
  border: Boolean,
  outlined: Boolean,
  small: Boolean,
  focusable: Boolean,
  hideControls: Boolean,
  footer: Boolean,
  loading: Boolean,
  clickableRows: Boolean, // TODO:
  cleaner: Boolean,
  actionsColumn: Boolean,
  hideColumnSelector: Boolean,
  hideDensity: Boolean,
  pagination: {
    type: Boolean,
    default: true,
  },
  header: {
    type: Boolean,
    default: true,
  },
  responsive: {
    type: Boolean,
    default: true,
  },

  sticky: {
    type: Boolean,
    default: true,
  },
  totalRows: {
    type: Number,
    required: false,
    default: null,
  },
  pageCount: {
    type: Number,
    required: false,
    default: null,
  },
  externallyPaginated: {
    type: Boolean,
    required: false,
  },
};
const tableDataProps = {
  items: {
    type: Array,
    required: false,
    default() {
      return [];
    },
  },
  fields: {
    type: Array,
    required: false,
    default() {
      return [];
    },
  },
  sorter: {
    type: [Boolean, Object],
    default() {
      return true;
    },
  },
  tableFilter: {
    type: [Boolean, Object],
    default() {
      return true;
    },
  },
  columnFilter: {
    type: [Boolean, Object],
    default() {
      return false;
    },
  },
  sorterValue: {
    type: Object,
    default() {
      return null;
    },
  },
  tableFilterValue: {
    type: String,
    default() {
      return null;
    },
  },
  columnFilterValue: {
    type: Object,
    default() {
      return null;
    },
  },
  filterers: {
    type: Object,
    default() {
      return null;
    },
  },
};

export const tableProps = Object.assign(
  {},
  sharedPaginationProps,
  sharedDensityProps,
  tableFlagProps,
  tableDataProps,
);
