'use strict';
import GeoZone from './GeoZone';

class TaxpayerRegistration {
  constructor(dto = {}) {
    this.id = dto?.id;
    this.tenantId = dto?.tenantId;
    this.importId = dto?.importId;
    this.registrationNumber = dto?.registrationNumber;
    this.sourceSystemName = dto?.sourceSystemName;
    this.sourceTaxpayerId = dto?.sourceTaxpayerId;
    this.taxpayerName = dto?.taxpayerName;
    this.taxpayerType = dto?.taxpayerType;
    if (dto?.geo) {
      this.geo = new GeoZone(dto.geo);
    } else {
      this.geo = null;
    }

    this.status = dto?.status;
    this.messages = dto?.messages || [];
    this.responseData = dto?.responseData || {};
    this.rawCountry = dto?.rawCountry;
    this.rawDivision = dto?.rawDivision;
    this.recordOwner = dto?.recordOwner;
    this.geoKey = this.geo?.key;
    this.geoName = this.geo?.fullDisplayName;
    this.isValid = this.status === 'VALID';
    this.isInvalid = !(
      this.status === 'VALID' ||
      this.status === 'PENDING' ||
      this.status === 'UNSUPPORTED'
    );

    this.country = this.geo?.countryName || this.rawCountry;
    if (!this.geo) {
      this.mainDivision = this.rawDivision;
    } else {
      if (this.geo.isParent) {
        this.mainDivision = null;
      } else {
        this.mainDivision = this.geo.displayName || this.rawDivision;
      }
    }

    const rdR = dto?.responseData?.response;
    if (rdR?.extendedValidationPerformed) {
      this.ev = {
        checked: true,
        systemName: rdR.extendedValidationSystemName,
        nameChecked: rdR.nameCheckPerformed,
      };
    } else {
      this.ev = {
        checked: false,
      };
    }

    if (dto?.responseData?.matchingFormat) {
      this.matchingFormat = dto.responseData.matchingFormat;
    } else {
      this.matchingFormat = null;
    }

    this.formats = dto?.responseData?.formats || [];
    this.hasExtendedData =
      this.messages.length > 0 ||
      this.ev.checked ||
      !!this.matchingFormat ||
      this.formats.length > 0;
  }
}

export default TaxpayerRegistration;
