import { keycloak } from '@/AxiosClient';
import { getEnv } from '@/utils/helpers';

const BASE_URL = getEnv('VUE_APP_API_PATH') || '/api';
const API_ORIGIN = getEnv('VUE_APP_API_ORIGIN') || window.location.origin;

const axiosInstance = keycloak.createAxiosInstance();
function createApiOriginUrl(origin, url) {
  let cO = origin;
  if (cO.endsWith('/')) {
    cO = cO.substring(0, cO.length - 1);
  }

  if (url.startsWith('/')) {
    return `${cO}${url}`;
  } else {
    return `${cO}/${url}`;
  }
}
export default {
  baseUrl: BASE_URL,
  apiOrigin: API_ORIGIN,
  apiOriginUrl: createApiOriginUrl(API_ORIGIN, BASE_URL),
  client: axiosInstance,
};
