<template>
  <VSelect
    :id="id"
    v-model="selectedValue"
    multiple
    :options="geoOptions"
    track-by="value"
    label="label"
    searchable
    :close-on-select="false"
    :limit="3"
    group-values="entries"
    group-label="label"
    group-select
    @open="
      () => {
        vselectOpen = true;
      }
    "
    @close="handleClose"
  >
    <template #tag="{ option, remove }">
      <span class="multiselect__tag">
        <span>{{ option.value.key }}</span>
        <i
          class="multiselect__tag-icon"
          aria-hidden="true"
          @click="remove(option)"
        />
      </span>
    </template>
  </VSelect>
</template>
<script>
import vSelect from 'vue-multiselect';
export default {
  name: 'MGeoFilter',
  components: {
    VSelect: vSelect,
  },
  inheritAttrs: false,
  props: {
    options: {
      required: true,
      type: Array,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    invalidFeedback: {
      type: String,
      required: false,
      default: null,
    },
    isValid: {
      type: Boolean,
      required: false,
      default: null,
    },
    value: {
      type: [String, Object, Array],
      required: false,
      default() {
        return null;
      },
    },
    // When true, show the ISO code before the display name.
    showCode: {
      type: Boolean,
      required: false,
      default: true,
    },
    // When true, only return the geo key instead of the whole object.
    keyOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Filter Geos',
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const g = this.buildGeoOptions(this.options || []);
    return {
      loading: false,
      internalValue: null,
      vselectOpen: false,
      geoOptions: g.geoOptions || [],
      geoMap: g.geoMap || {},
    };
  },
  computed: {
    selectedValue: {
      get() {
        return this.internalValue;
      },
      set(v) {
        this.internalValue = v;
      },
    },
  },
  watch: {
    options: {
      immediate: true,
      handler(options) {
        // TODO: Fixme
        if (this.geoOptions.length > 0) {
          return;
        }
        const { geoMap, geoOptions } = this.buildGeoOptions(options);
        this.geoOptions = geoOptions || [];
        this.geoMap = geoMap || {};
      },
    },
    value: {
      immediate: true,
      handler(v) {
        if (!v) {
          this.internalValue = [];
        }
        this.internalValue = this.mapValueOptions(v);
      },
    },
  },
  methods: {
    handleClose() {
      let v = this.internalValue || [];
      this.$nextTick(() => {
        this.$emit(
          'update:value',
          v.map((i) => i.id),
        );
      });
    },
    mapValueOptions(value, geoMap = null) {
      if (!value || value.length < 1) {
        return null;
      }

      let v = value;
      if (!Array.isArray(v)) {
        v = [value];
      }

      return v
        .map((i) => {
          if (geoMap) {
            return geoMap[i];
          } else {
            return this.geoMap[i];
          }
        })
        .filter((i) => !!i);
    },
    buildGeoOptions(options) {
      if (!options || options.length < 1) {
        return {
          geoMap: {},
          geoOptions: [],
        };
      }

      const geos = options
        .map((c) => {
          return Object.freeze({
            id: c.key,
            value: c,
            label: this.makeLabel(c),
          });
        })
        .sort((a, b) => {
          if (a.label > b.label) {
            return 1;
          } else if (a.label < b.label) {
            return -1;
          } else {
            return 0;
          }
        });

      const geoMap = geos.reduce((prv, entry) => {
        prv[entry.id] = entry;
        return prv;
      }, {});

      const groups = geos.reduce(
        (prv, entry) => {
          const parent = {
            key: entry.value.parentKey,
            label: entry.value.parentDisplayName,
          };
          if (parent.key && parent.key !== 'WORLD') {
            if (!prv[parent.key]) {
              prv[parent.key] = {
                label: parent.label,
                entries: [],
              };
            }
            prv[parent.key].entries.push(entry);
          } else {
            prv['WORLD'].entries.push(entry);
          }
          return prv;
        },
        { WORLD: { label: 'Countries', entries: [] } },
      );

      // Remove countries from the list that have child entries
      groups.WORLD.entries = groups.WORLD.entries.filter((country) => {
        if (!!groups[country.id] && groups[country.id].entries.length > 0) {
          return false;
        } else {
          return true;
        }
      });

      const geoOptions = Object.keys(groups)
        .flatMap((group) => groups[group])
        .sort((a, b) => {
          if (a.label === 'Countries') {
            return -1;
          }

          if (a.label > b.label) {
            return 1;
          } else if (a.label < b.label) {
            return -1;
          } else {
            return 0;
          }
        });

      return {
        geoMap,
        geoOptions,
      };
    },
    makeLabel(v) {
      if (this.showCode) {
        if (v.parentGeoType === 'COUNTRY') {
          return `${v.primaryCode} - ${v.displayName}`;
        } else {
          return `${v.key} - ${v.displayName}`;
        }
      } else {
        return v.displayName;
      }
    },
  },
};
</script>
