<template>
  <CButton
    class="btn-crd btn-add"
    v-bind="cButtonProps"
    @click="(e) => $emit('click', e)"
  >
    <slot><CIcon name="cis-arrow-circle-right" /></slot>
  </CButton>
</template>
<script>
import { mButtonsMixin } from './mbuttons-mixin';
export default {
  name: 'MRunButton',
  mixins: [mButtonsMixin],
  computed: {
    defaultProps() {
      return {
        variant: 'ghost',
        color: 'success',
        alt: 'Run Button',
        title: 'Run',
      };
    },
  },
};
</script>
