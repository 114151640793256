import 'regenerator-runtime/runtime';
import 'core-js/stable';
import _ from 'lodash';
import Vue from 'vue';
import CoreuiVuePro from '@coreui/vue-pro';
import App from './App';
import router from './router/index';
import { iconsSet as icons } from './assets/icons/icons.js';
import store from './store';
import i18n from './i18n.js';
import { keycloak, keycloakPlugin, keycloakConfig } from './AxiosClient';
import {
  formatDate,
  formatNumber,
  formatPhone,
  percent,
  formatCurrency,
  formatTimestamp,
} from './utils/format';
import { getTenantAuthProviders } from '@/utils/auth';
import { titleCase } from './utils/helpers';
import VueSweetalert2 from 'vue-sweetalert2';
import { loggerPlugin } from './utils/logger';
import { getEntityIdOrKey } from './utils/entities';
import MCheckIcon from './components/MCheckIcon';
import TaxEngineBadge from './components/TaxEngineBadge';
import vSelect from 'vue-select';
import { TabWizard, TabStep } from './components/TabWizard';
import DownloadLink from './components/DownloadLink';
import { CChartBar } from '@coreui/vue-chartjs';
import LabeledValue from './components/LabeledValue';
import 'highlight.js/styles/github.css';
import hljs from 'highlight.js/lib/core';
import xml from 'highlight.js/lib/languages/xml';
import json from 'highlight.js/lib/languages/json';
import {
  VUE_APP_USE_TENANT_API,
  VUE_APP_BRAND_PATH,
  VUE_APP_JWT_BRAND_ENABLED,
} from './EnvVars';

hljs.registerLanguage('xml', xml);
hljs.registerLanguage('json', json);

import {
  MAddButton,
  MDeleteButton,
  MEditButton,
  MImportButton,
  MExportButton,
  MDetailsButton,
} from './components/Buttons';
import StatusIcon from './components/StatusIcon';
import ModalToast from './components/form/ModalToast';
import { MDataTable } from './components/MDataTable';

import {
  MInputCheckbox,
  MSelect,
  MCountryPicker,
  MCountrySubPicker,
} from './components/form';

import MTaxableCountryPicker from './components/form/MGeoPicker/MTaxableCountryPicker';
import MTaxableDivisionPicker from './components/form/MGeoPicker/MTaxableDivisionPicker';
import EntityDataTableCard from './components/EntityDataTableCard';
import MCollapseToggler from './components/MCollapseToggler';
import FreshDesk from './components/FreshDesk';

Vue.config.productionTip = false;
Vue.config.performance = process.env.NODE_ENV !== 'production';
Vue.config.devtools = process.env.NODE_ENV !== 'production';

Vue.use(CoreuiVuePro);
Vue.use(VueSweetalert2);
Vue.use(loggerPlugin);

// Add lodash _ to the global Vue object for access as vm.$_
Object.defineProperty(Vue.prototype, '$_', { value: _, writable: false });

// Add standard formatting functions as vm.$format.[function]
Object.defineProperty(Vue.prototype, '$format', {
  get() {
    return {
      number: formatNumber,
      date: formatDate,
      phone: formatPhone,
      percent: percent,
      currency: formatCurrency,
      timestamp: formatTimestamp,
    };
  },
});

Object.defineProperty(
  Vue.prototype,
  '$alert',
  {
    get() {
      return {
        success: (title, text) => {
          return this.$swal.fire({
            icon: 'success',
            title,
            text,
            showCancelButton: false,
          });
        },
        error: (title, text) => {
          return this.$swal.fire({
            icon: 'error',
            title,
            text,
            showCancelButton: false,
          });
        },
      };
    },
  },
  { writable: false },
);
Object.defineProperty(
  Vue.prototype,
  '$plural',
  {
    get() {
      return (key, choice = null, values) => {
        return this.$tc(key, choice, 2, values);
      };
    },
  },
  { writable: false },
);

Object.defineProperty(
  Vue.prototype,
  '$enum',
  {
    get() {
      return (key, value) => {
        if (!value) {
          return null;
        }
        return this.$t(`${key}.${value}`);
      };
    },
  },
  { writable: false },
);

// Add standard helper functions as vm.$helpers.[function]
Object.defineProperty(Vue.prototype, '$helpers', {
  get() {
    return {
      titleCase: titleCase,
    };
  },
});

Object.defineProperty(Vue.prototype, '$entities', {
  get() {
    return {
      idOrKey: getEntityIdOrKey,
    };
  },
});

getTenantAuthProviders(VUE_APP_USE_TENANT_API)
  .then((tenantAuth) => {
    if (tenantAuth && tenantAuth.length > 0) {
      const ssoAuthConfig = tenantAuth.find((t) => t.authType === 'OAUTH2');
      if (ssoAuthConfig) {
        keycloakConfig.realm =
          ssoAuthConfig.authData['realm'] || keycloakConfig.realm;
        if (ssoAuthConfig.authType === 'OAUTH2') {
          const idpHint = ssoAuthConfig.authData?.params?.kc_idp_hint;
          if (idpHint) {
            keycloakConfig.options = {
              idpHint: idpHint,
            };
            return idpHint;
          }
        }
      }
    }
    return null;
  })
  .then((idpHint) => {
    Vue.use(keycloakPlugin);
    keycloakConfig.options.idpHint = idpHint || keycloakConfig.options.idpHint;
    Vue.$keycloak.init(keycloakConfig).then((authenticated) => {
      if (authenticated) {
        store.commit('auth/setLoggedIn', keycloak.tokenParsed);
      } else if (keycloak.hasToken()) {
        store.dispatch('auth/refreshToken');
      }

      // eslint-disable-next-line no-new
      new Vue({
        el: '#app',
        router,
        store,
        icons,
        i18n,
        components: {
          App,
        },
        template: '<App/>',
      });

      Vue.component('TaxEngineBadge', TaxEngineBadge);
      Vue.component('MCheckIcon', MCheckIcon);
      Vue.component('MAddButton', MAddButton);
      Vue.component('MDeleteButton', MDeleteButton);
      Vue.component('MEditButton', MEditButton);
      Vue.component('MExportButton', MExportButton);
      Vue.component('MImportButton', MImportButton);
      Vue.component('MDetailsButton', MDetailsButton);
      Vue.component('CChartBar', CChartBar);
      Vue.component('VSelect', vSelect);
      Vue.component('TabWizard', TabWizard);
      Vue.component('TabStep', TabStep);
      Vue.component('MInputCheckbox', MInputCheckbox);
      Vue.component('MSelect', MSelect);
      Vue.component('MCountryPicker', MCountryPicker);
      Vue.component('MTaxableCountryPicker', MTaxableCountryPicker);
      Vue.component('MCountrySubPicker', MCountrySubPicker);
      Vue.component('MTaxableDivisionPicker', MTaxableDivisionPicker);
      Vue.component('ModalToast', ModalToast);
      Vue.component('MDataTable', MDataTable);
      Vue.component('DownloadLink', DownloadLink);
      Vue.component('StatusIcon', StatusIcon);
      Vue.component('EntityDataTableCard', EntityDataTableCard);
      Vue.component('LabeledValue', LabeledValue);
      Vue.component('MCollapseToggler', MCollapseToggler);
      Vue.component('FreshDesk', FreshDesk);

      Vue.directive('resize', {
        inserted: function (el, binding) {
          const onResizeCallback = binding.value;
          window.addEventListener('resize', () => {
            const width = document.documentElement.clientWidth;
            const height = document.documentElement.clientHeight;
            onResizeCallback({ width, height });
          });
        },
      });

      Object.defineProperty(
        Vue.prototype,
        '$brand',
        {
          get() {
            return (path, prefix = null) => {
              let brandedPath;
              if (VUE_APP_JWT_BRAND_ENABLED) {
                brandedPath = `${store.getters.brandPath}/${path}`;
              } else {
                brandedPath = `${VUE_APP_BRAND_PATH}/${path}`;
              }

              if (prefix) {
                brandedPath = `${prefix}/${brandedPath}`;
              }

              if (!brandedPath.startsWith('http')) {
                return brandedPath.replaceAll('//', '/');
              } else {
                let parts = brandedPath.split('://');
                if (parts.length == 2) {
                  return `${parts[0]}://${parts[1].replaceAll('//', '/')}`;
                } else {
                  return brandedPath;
                }
              }
            };
          },
        },
        { writable: false },
      );
      Vue.directive('taxengine', {
        bind(el) {
          if (store.state.auth.taxEngines.length < 2) {
            el.style.display = 'none';
            el.style.visibility = 'hidden';
          }

          const unwatch = store.watch(
            (state) => state.auth.taxEngines,
            (taxEngines) => {
              if (taxEngines.length < 2) {
                el.style.display = 'none';
                el.style.visibility = 'hidden';
              } else {
                el.style.display = 'block';
                el.style.visibility = 'inherit';
              }
            },
          );
          el.__role_unwatch__ = unwatch;
        },
        unbind(el) {
          el.__role_unwatch__ && el.__role_unwatch__();
        },
      });
    });
  });
