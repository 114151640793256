var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DownloadLink',{staticClass:"btn btn-crd btn-edit",class:_vm.buttonClasses,attrs:{"id":("btn-download-test-result-" + (_vm.id || _vm.testRunId)),"href":("/api/testing/results/" + _vm.testRunId + "/download"),"disabled":_vm.downloadSuccess,"title":_vm.downloadError ? 'Retry Download' : 'Download Test Results'},on:{"downloaded":_vm.handleDownloaded,"error":function () {
      _vm.downloadError = true;
      _vm.downloadSuccess = false;
    }},scopedSlots:_vm._u([{key:"content-prepend",fn:function(ref){
    var downloading = ref.downloading;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(downloading),expression:"downloading"}],staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}})]}},{key:"default",fn:function(ref){
    var downloading = ref.downloading;
return [_c('CIcon',{directives:[{name:"show",rawName:"v-show",value:(!downloading),expression:"!downloading"}],attrs:{"name":_vm.iconName}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }