/* eslint-disable no-console */
/**
 * Logger implementation for colorizing and formatting of log messages, allowing for different log levels.
 * When in production only warnings and errors will be logged.
 */

import Vue from 'vue';
import _ from 'lodash';

import { VUE_APP_RELEASE_STAGE, VUE_APP_DEBUG } from '@/EnvVars';

const ENABLE_DEBUG =
  VUE_APP_DEBUG === true && VUE_APP_RELEASE_STAGE !== 'production';
const ENABLE_INFO = ENABLE_DEBUG || VUE_APP_RELEASE_STAGE !== 'production';

const LEVEL_COLORS = {
  debug: { bg: '#FFF', fg: '#000' },
  info: { bg: '#3665D2', fg: '#B4C2E1' },
  warn: { bg: 'FFF0AA', fg: '#554600' },
  error: { bg: 'FFAAAA', fg: '#550000' },
};

function doLog(level, cb) {
  switch (level) {
    case 'error':
      cb(console.error);
      break;
    case 'warn':
      cb(console.warn);
      break;
    case 'info':
      cb(console.info);
      break;
    case 'debug':
      cb(console.debug);
      break;
    default:
      cb(console.log);
      break;
  }
}

function logForLevel(level, messageParam, optionalParams) {
  const color = LEVEL_COLORS[level] || { bg: '#FFF', fg: '#000' };
  const hasOptData = !_.isNil(optionalParams);
  let message = messageParam;
  if (_.isFunction(messageParam)) {
    message = messageParam();
  }

  if (hasOptData) {
    // Group the log message if we have data to show.
    try {
      console.group;
    } catch (e) {
      console.log('--- group start ---');
    }
  }

  if (_.isString(message)) {
    try {
      doLog(level, (l) => {
        l(
          `%c[${_.upperCase(level)}]%c ${message}`,
          `background:${color.bg} ; padding: 2px; border-radius: 3px; color: ${color.fg}; font-weight: bold`,
          `background: transparent ; border-radius: 0 ; font-weight: normal ; color: #000`,
        );
      });
    } catch (e) {
      // Fall back to basic console logging.
      console.log(`[${_.upperCase(level)}] ${message}`);
    }
  } else {
    try {
      doLog(level, (l) => {
        l(
          `%c[${_.upperCase(level)}]%c`,
          `background:${color.bg} ; padding: 2px; border-radius: 3px; color: ${color.fg}; font-weight: bold`,
          `background: transparent ; border-radius: 0 ; font-weight: normal ; color: #000`,
          message,
        );
      });
    } catch (e) {
      // Fall back to basic console logging.
      console.log(`[${_.upperCase(level)}]`, message);
    }
  }

  if (hasOptData) {
    let optData = optionalParams;
    if (_.isFunction(optionalParams)) {
      optData = optionalParams();
    }
    if (_.isObjectLike(optData) && _.has(optData, 'response')) {
      let response = _.omit(JSON.parse(JSON.stringify(optData.response)), [
        'config',
      ]);
      console.dir(response);
    } else if (_.isError(optData)) {
      console.log(optData);
    } else if (_.isString(optData)) {
      console.log(optData);
    } else if (_.isObjectLike(optData) && _.has(optData, 'fields')) {
      console.dir(JSON.parse(JSON.stringify(optData)));
    } else {
      // Output the optional data
      console.dir(JSON.parse(JSON.stringify(optData)));
    }
  }

  if (hasOptData) {
    // End the grouping
    try {
      console.groupEnd();
    } catch (e) {
      console.log('--- group end ---');
    }
  }
}

export const logger = {
  log: (message, optionalParams) => {
    if (ENABLE_INFO) {
      if (!_.isNil(optionalParams)) {
        logForLevel('info', message, optionalParams);
      } else {
        logForLevel('info', message);
      }
    }
  },
  debug: (message, optionalParams) => {
    if (ENABLE_DEBUG) {
      if (!_.isNil(optionalParams)) {
        logForLevel('debug', message, optionalParams);
      } else {
        logForLevel('debug', message);
      }
    }
  },
  info: (message, optionalParams) => {
    if (ENABLE_INFO) {
      if (!_.isNil(optionalParams)) {
        logForLevel('info', message, optionalParams);
      } else {
        logForLevel('info', message);
      }
    }
  },
  warn: (message, optionalParams) => {
    if (!_.isNil(optionalParams)) {
      logForLevel('warn', message, optionalParams);
    } else {
      logForLevel('warn', message);
    }
  },
  error: (message, optionalParams) => {
    if (!_.isNil(optionalParams)) {
      logForLevel('error', message, optionalParams);
    } else {
      logForLevel('error', message);
    }
  },
};

export const loggerPlugin = {
  install(vue) {
    vue.$log = logger;
    Object.defineProperty(Vue.prototype, '$log', {
      value: logger,
      writable: false,
    });
  },
};
