<template>
  <CIcon v-bind="cIconProps" />
</template>
<script>
export default {
  name: 'StatusIcon',
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    cIconProps() {
      var value = `${this.value || 'UNKNOWN'}`.toUpperCase();
      const props = {
        name: 'cis-lock-locked',
        class: 'text-secondary',
      };
      switch (value) {
        case 'INVALID':
          props.name = 'cis-report';
          props.class = 'text-danger';
          break;
        case 'WARN':
          props.name = 'cis-warning';
          props.class = 'text-warning';
          break;
        case 'VALID':
          props.name = 'cis-check-alt';
          props.class = 'text-success';
          break;
      }
      return props;
    },
  },
};
</script>
