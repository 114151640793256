const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
};

const getters = {
  darkMode: (state) => state.darkMode,
  sidebarShow: (state) => state.sidebarShow,
  asideShow: (state) => state.asideShow,
  sidebarMinimize: (state) => state.sidebarMinimize,
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : 'responsive';
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : 'responsive';
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  toggle(state, variable) {
    state[variable] = !state[variable];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
