<template>
  <div class="labeled-value-wrapper">
    <label class="text-muted text-small labeled-value-label" :for="valueId">
      {{ label }}
    </label>
    <pre
      v-if="usePreTag"
      :id="valueId"
      class="labeled-value-value"
      :class="{ 'text-muted': empty }"
    >
      <slot>{{value}}</slot>
    </pre>
    <div
      v-else
      :id="valueId"
      class="labeled-value-value"
      :class="{ 'text-muted': empty }"
    >
      <slot>{{ value }}</slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LabeledValue',
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: false,
      default: null,
    },
    empty: {
      type: Boolean,
      required: false,
    },
    usePreTag: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    valueId() {
      return (
        this.id ||
        this.$attrs.id ||
        this._uid ||
        `${this.label}_labeled_value_id`
      );
    },
  },
};
</script>
<style lang="scss">
.labeled-value-wrapper {
  padding: 0.25rem 0.5rem 0 0.25rem;

  label {
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 80%;
  }

  pre.labeled-value-value {
    margin-bottom: 0;
  }
}
</style>
