<template>
  <CFormGroup
    v-bind="{
      append,
      prepend,
      validFeedback,
      invalidFeedback,
      tooltipFeedback,
      description,
      wrapperClasses,
      class: computedClasses,
    }"
  >
    <template #label>
      <slot name="label">
        <label v-if="label" :for="safeId" :class="labelClasses">
          {{ label }}
        </label>
      </slot>
    </template>
    <template #input>
      <VSelect
        ref="vselect"
        v-bind="vSelectProps"
        :value.sync="state"
        :class="inputClasses"
        :get-option-key="getOptionKey"
        :reduce="reduce"
        :selectable="optionSelectable"
        :options="options"
        select-on-tab
        @input="(inp) => onSelect(inp)"
        v-on="$listeners"
      />
    </template>

    <template v-for="slot in $options.slots" #[slot]>
      <slot :name="slot"></slot>
    </template>
  </CFormGroup>
</template>
<script>
import vSelect from 'vue-select';
import {
  sharedComputedProps,
  wrapperComputedProps,
  classesComputedProps,
} from './form-mixins';
const mixins = [
  sharedComputedProps,
  wrapperComputedProps,
  classesComputedProps,
];
import { selectProps } from './form-props';

const props = Object.assign({}, selectProps, {
  optionLabelKey: {
    type: String,
    required: false,
    default: 'label',
  },
  optionValueKey: {
    type: String,
    required: false,
    default: 'value',
  },
  filterable: Boolean,
  clearable: Boolean,
  loading: Boolean,
  selectable: Function,
  taggable: Boolean,
  searchable: {
    type: Boolean,
    default: true,
  },
  keyOnly: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '-- Choose --',
  },
  selectStyle: {
    type: [String, Object],
    default() {
      return null;
    },
  },
});

export default {
  name: 'MSelect',
  components: {
    VSelect: vSelect,
  },
  mixins,
  inheritAttrs: false,
  slots: [
    'prepend',
    'prepend-content',
    'append-content',
    'append',
    'label-after-input',
    'valid-feedback',
    'invalid-feedback',
    'description',
  ],
  props,
  data() {
    return {
      state: this.findOption(this.value, this.options),
    };
  },
  computed: {
    vSelectProps() {
      return {
        filterable: this.filterable,
        clearable: this.clearable,
        loading: this.loading,
        multiple: this.multiple,
        placeholder: this.placeholder,
        searchable: this.searchable,
        selectable: this.selectable,
        inputId: this.safeId,
        disabled: this.disabled,
        label: this.optionLabelKey,
        style: this.selectStyle,
      };
    },
    customSizeClass() {
      if (this.haveCustomSize && !this.haveWrapper) {
        return `${this.custom ? 'custom-select' : 'form-control'}-${this.size}`;
      }
      return null;
    },
    inputClass() {
      const standardClass = `form-control${this.plaintext ? '-plaintext' : ''}`;
      let inputClassStr = this.custom ? 'custom-select' : standardClass;
      if (this.readonly) {
        inputClassStr += ' readonly vs--readonly';
      }
      return inputClassStr;
    },
  },
  watch: {
    value(value) {
      this.state = this.findOption(value, this.options);
    },
    options(values) {
      if (this.value) {
        const self = this;
        this.$nextTick(() => {
          self.state = this.findOption(self.value, values);
        });
      }
    },
  },
  methods: {
    findOption(value, options) {
      if (!options) {
        options = this.options || [];
      }

      if (!value && value !== false && value !== 0) {
        return undefined;
      }

      if (Array.isArray(value) && value.length === 0) {
        return undefined;
      }

      let propVal = this.optionValueKey;
      if (!propVal || propVal === '') {
        propVal = 'value';
      }

      let found = null;
      if (this.$_.isString(value)) {
        found = options.find((o) => {
          if (this.$_.isString(o)) {
            return o === value;
          }
          const id = this.$_.get(o, 'id');
          const key = this.$_.get(o, 'key');
          if (id === value || key === value) {
            return true;
          }

          return this.$_.get(o, propVal, o) === value;
        });
      }

      if (!found && this.$_.isObjectLike(value)) {
        const lookupKey = this.$entities.idOrKey(value);
        found = options.find((o) => {
          if (this.$_.isString(o)) {
            return o === lookupKey;
          } else {
            const id = this.$_.get(o, 'id');
            const key = this.$_.get(o, 'key');
            if (id === lookupKey || key === lookupKey) {
              return true;
            }

            const val = this.$_.get(o, propVal);
            if (val) {
              return this.$entities.idOrKey(val) === lookupKey;
            }

            return false;
          }
        });
      }

      return found || value;
    },
    optionSelectable(option) {
      if (this.$attrs.selectable && !!this.selectable) {
        if (this.$_.isFunction(this.selectable)) {
          return this.selectable(option);
        }
      }

      if (this.$_.get(option, 'disabled', false) === true) {
        return false;
      }

      if (this.$_.get(option, 'selectable', true) === false) {
        return false;
      }

      return true;
    },
    reduce(option) {
      if (!this.$_.isObjectLike(option)) {
        return option;
      }

      if (this.optionValueKey && this.$_.has(option, this.optionValueKey)) {
        return this.$_.get(option, this.optionValueKey);
      }

      return option;
    },
    getOptionKey(option) {
      if (!this.$_.isObjectLike(option)) {
        return option;
      }

      let key = this.$_.get(option, 'id');

      if (!key && this.$_.has(option, 'key')) {
        key = this.$_.get(option, 'key');
      }

      if (!key && this.$_.has(option, 'code')) {
        key = this.$_.get(option, 'code');
      }

      if (!key && this.$_.has(option, 'value')) {
        key = this.$_.get(option, 'value');
        if (this.$_.isObjectLike(key)) {
          return this.getOptionKey(key);
        } else {
          return key;
        }
      }
      if (!key) {
        return this.$_.get(option, 'label', JSON.stringify(option));
      } else {
        return key;
      }
    },
    onSelect(inp) {
      const prv = this.state;
      this.state = inp;
      if (!inp) {
        this.$emit('update:value', inp);
      } else if (
        this.$_.isObjectLike(inp) &&
        this.optionValueKey &&
        !this.$_.isNil(this.$_.get(inp, this.optionValueKey))
      ) {
        this.$emit('update:value', inp[this.optionValueKey]);
      } else {
        this.$emit('update:value', inp);
      }
      this.$emit('change', { current: inp, previous: prv });
    },
  },
};
</script>
