<template>
  <MSelect
    :id="id"
    v-bind="mSelectProps"
    :value.sync="selectedValue"
    :options="currencyOptions"
    :loading="loadingCurrencies"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import MSelect from './MSelect';
import { pickerProps } from './form-props';
import { pickerMixin } from './form-mixins';

export default {
  name: 'MCurrencyPicker',
  components: {
    MSelect,
  },
  mixins: [pickerMixin],
  props: {
    ...pickerProps,
    label: {
      type: String,
      default: 'Currency',
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    defaultValue: {
      type: [String, Object],
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters('kb', ['currencies', 'loadingCurrencies']),
    // Prepare the currencies collection for the select component.
    currencyOptions() {
      if (!this.currencies || this.currencies.length === 0) {
        return [];
      }

      return Object.freeze(
        this.currencies
          .map((c) => {
            return {
              value: c.key,
              label: `${c.currencyCode} - ${c.name}`,
            };
          })
          .sort((a, b) => {
            if (a.label > b.label) {
              return 1;
            } else if (a.label < b.label) {
              return -1;
            } else {
              return 0;
            }
          }),
      );
    },
  },
  watch: {
    defaultValue: {
      immediate: true,
      handler(v) {
        if (v) {
          this.selectedValue = v;
        }
      },
    },
  },
  beforeMount() {
    if (!this.currencies || this.currencies.length === 0) {
      this.$store.dispatch('kb/fetchCurrencies').catch((err) => {
        this.$log.error(err);
      });
    }
  },
};
</script>
