export const buttonProps = {
  id: {
    type: String,
    default: null,
    required: false,
  },
  name: {
    type: String,
    default: null,
    required: false,
  },
  block: {
    type: Boolean,
  },
  shape: {
    type: String,
    validator: (shape) => ['', 'pill', 'square'].includes(shape),
  },
  variant: {
    type: String,
    validator: (val) => ['', 'ghost', 'outline'].includes(val),
  },
  size: {
    type: String,
    validator: (value) => ['', 'sm', 'lg'].includes(value),
  },
  color: String,
  type: {
    type: String,
    required: false,
    default: 'button',
  },
  pressed: {
    type: Boolean,
    default: null,
    required: false,
  },
  alt: {
    type: String,
    required: false,
    default: null,
  },
  title: {
    type: String,
    default: null,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
};

export const mButtonsMixin = {
  props: buttonProps,
  inheritAttrs: false,
  computed: {
    defaultProps() {
      return {
        shape: '',
        variant: '',
      };
    },
    computedClasses() {
      return {};
    },
    cButtonProps() {
      let p = {
        size: this.size || 'sm',
        ...this.defaultProps,
      };
      if (this.pressed || this.pressed === false) {
        p.pressed = true;
      }

      if (this.block) {
        p.block = true;
      }

      if (this.shape) {
        p.shape = this.shape;
      }

      if (this.variant) {
        p.variant = this.variant;
      }

      if (this.color && this.color !== '') {
        p.color = this.color;
      }

      if (this.type) {
        p.type = this.type;
      }

      if (this.alt) {
        p.alt = this.alt;
      }

      if (this.title) {
        p.title = this.title;
      }

      if (this.id && this.id !== '') {
        p.id = this.id;
      }

      if (this.name && this.name !== '') {
        p.name = this.name;
      }

      if (p.disabled !== true && p.disabled !== false) {
        if (this.disabled) {
          p.disabled = true;
        }
      }

      if (this.computedClasses) {
        p.class = { ...this.computedClasses };
      }
      return p;
    },
  },
};
