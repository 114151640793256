var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.wrapperClasses},[_c('div',{staticClass:"step-header"},[_vm._t("nav",[_c('CProgress',{staticClass:"mb-1",attrs:{"color":"info","value":_vm.progress}}),_c('ul',{staticClass:"step-pills"},_vm._l((_vm.unhiddenTabs),function(tab,index){return _c('li',{key:("tab-" + (tab.index)),staticClass:"step-item",class:{
            active: tab.isActive,
            validated: tab.isValidated,
            disabled: tab.disabled,
            invalid: tab.isNotValid,
            valid: tab.isValid,
          },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.selectTab(tab.index)}}},[(
              !tab.disabled && !tab.isActive && tab.index <= _vm.activeTabIndex
            )?_c('a',{staticClass:"step-link",attrs:{"href":"#"}},[_c('span',{staticClass:"tab-status"},[_vm._v(_vm._s(index + 1))]),_c('span',{staticClass:"tab-label"},[_vm._v(_vm._s(tab.title))])]):_c('div',{staticClass:"step-link"},[_c('span',{staticClass:"tab-status"},[_vm._v(_vm._s(index + 1))]),_c('span',{staticClass:"tab-label"},[_vm._v(_vm._s(tab.title))])])])}),0)])],2),_c('div',{staticClass:"step-body"},[_c('div',{tag:"component"},[_vm._t("default")],2)],1),_vm._t("footer",[_c('div',{staticClass:"step-footer"},[_c('div',[(!_vm.submitSuccess)?[_c('CButton',{staticClass:"mr-2",attrs:{"color":"secondary","disabled":_vm.activeTabIndex === 0},on:{"click":_vm.previousTab}},[_vm._v(" Previous ")]),(_vm.activeTabIndex < _vm.totalTabs - 1)?_c('CButton',{attrs:{"color":"primary"},on:{"click":_vm.nextTab}},[_vm._v(" Next ")]):_vm._e(),(_vm.activeTabIndex === _vm.totalTabs - 1)?_c('CButton',{attrs:{"color":"success"},on:{"click":_vm.onSubmit}},[_vm._v(" Submit ")]):_vm._e()]:_vm._e()],2)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }