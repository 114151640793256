import config from './config';
import TaxpayerRegistration from '@/models/TaxpayerRegistration';

const client = config.client;
const baseUrl = `${config.baseUrl}/registrations`;

export default {
  getSupportedCountries() {
    return client.get(`${baseUrl}/countries`).then((res) => res.data);
  },
  getSupportedDivisions(geoKey) {
    return client.get(`${baseUrl}/${geoKey}/divisions`).then((res) => res.data);
  },
  getExtendedValidationCountries() {
    return client.get(`${baseUrl}/countries/extended`).then((res) => res.data);
  },
  getRegistrationFormatsForGeoKey(geoKey) {
    return client.get(`${baseUrl}/${geoKey}/formats`).then((res) => res.data);
  },
  validateRegistration(request) {
    return client.post(`${baseUrl}/validate`, request).then((res) => res.data);
  },
  getAllImportedBatches() {
    return client.get(`${baseUrl}/imports`).then((res) => res.data);
  },
  importBatch(name, file, description) {
    let formData = new FormData();
    formData.append('file', file);
    if (name && name !== '') {
      formData.append('name', name);
    }
    if (description && description !== '') {
      formData.append('description', description);
    }

    return client
      .post(`${baseUrl}/import`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data);
  },
  getBatchResults(
    batchId,
    queryArgs = { page: 0, size: 50, sort: 'taxpayerName' },
  ) {
    const params = new URLSearchParams();
    params.append('page', queryArgs.page || 0);
    params.append('size', queryArgs.size || 50);
    params.append('sort', queryArgs.sort || 'taxpayerName');
    [
      'validationStatus',
      'taxpayerType',
      'taxpayerName',
      'registrationNumber',
      'country',
      'mainDivision',
    ].forEach((key) => {
      if (queryArgs[key]) {
        params.append(key, queryArgs[key]);
      }
    });

    return client
      .get(`${baseUrl}/results/${batchId}`, {
        params,
      })
      .then((res) => {
        return {
          ...res.data,
          content: (res.data.content || []).map((o) => {
            return new TaxpayerRegistration(o);
          }),
        };
      });
  },
  deleteResults(batchId) {
    return client
      .delete(`${baseUrl}/results/${batchId}`)
      .then((res) => res.data);
  },
};
