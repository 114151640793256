<template>
  <CButton
    class="btn-crd btn-import"
    v-bind="cButtonProps"
    @click="(e) => $emit('click', e)"
  >
    <slot><CIcon name="cil-cloud-upload" /></slot>
  </CButton>
</template>
<script>
import { mButtonsMixin } from './mbuttons-mixin';
export default {
  name: 'MImportButton',
  mixins: [mButtonsMixin],
  computed: {
    defaultProps() {
      return {
        variant: 'ghost',
        color: 'dark',
        alt: 'Import Button',
        title: 'Import',
      };
    },
  },
};
</script>
