export const formGroupAlwaysSharedProps = {
  validFeedback: String,
  invalidFeedback: String,
  tooltipFeedback: Boolean,
  description: String,
};
export const formGroupSharedProps = Object.assign(
  {},
  formGroupAlwaysSharedProps,
  {
    append: String,
    prepend: String,
  },
);

export const formGroupProps = Object.assign({}, formGroupSharedProps, {
  wrapperClasses: [String, Array, Object],
});

export const universalProps = {
  name: String,
  inputId: String,
  label: String,
  wasValidated: Boolean,
  isValid: {
    type: [Boolean, Function],
    default: null,
  },
  addInputClasses: [String, Array, Object],
  addLabelClasses: [String, Array, Object],
};
export const props = Object.assign({}, universalProps, {
  horizontal: [Boolean, Object],
  size: {
    type: String,
    validator: (str) => ['', 'sm', 'lg'].includes(str),
  },
  addWrapperClasses: [String, Array, Object],
});
const textInputsProps = {
  readonly: Boolean,
  plaintext: Boolean,
  value: [String, Number],
  lazy: {
    type: [Boolean, Number],
    default: 400,
  },
};

// Html props: disabled, required, accept, id, placeholder
export const inputFileProps = Object.assign(
  {},
  formGroupAlwaysSharedProps,
  props,
  {
    custom: Boolean,
    placeholder: String,
    multiple: Boolean,
  },
);

// Html props: disabled, required, rows, cols, placeholder, id
export const textareaProps = Object.assign(
  {},
  formGroupSharedProps,
  props,
  textInputsProps,
);

// HTML props: disabled, required, placeholder, id
export const inputProps = Object.assign(
  {},
  formGroupSharedProps,
  props,
  textInputsProps,
  {
    type: {
      type: String,
      default: 'text',
    },
  },
);

// Html props: disabled, id required don't use multiple
export const selectProps = Object.assign({}, formGroupSharedProps, props, {
  readonly: Boolean,
  options: Array,
  value: [String, Number, Boolean, Array, Object],
  plaintext: Boolean,
  placeholder: String,
  custom: Boolean,
  multiple: Boolean,
  disabled: Boolean,
});

// Html props: id, disabled, required
export const inputCheckboxProps = Object.assign(
  {},
  formGroupAlwaysSharedProps,
  universalProps,
  {
    checked: Boolean,
    custom: Boolean,
    inline: Boolean,
  },
);

export const pickerProps = Object.assign(
  {},
  formGroupAlwaysSharedProps,
  universalProps,
  {
    clearable: {
      type: Boolean,
      default: true,
    },
    readonly: Boolean,
    disabled: Boolean,
    multiple: Boolean,
    label: String,
    keyOnly: Boolean,
    required: Boolean,
    filterable: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '-- Choose --',
    },
    value: {
      type: [String, Object, Array],
      required: false,
      default() {
        return null;
      },
    },
  },
);

export const clientPickerProps = Object.assign({}, pickerProps, {
  client: {
    type: [String, Object],
    required: false,
    default() {
      return null;
    },
  },
  // When true, allow none to be an option. When selected it's effectively null for the value.
  allowNone: {
    type: Boolean,
    required: false,
    default: false,
  },
  exclude: {
    type: [String, Array],
    required: false,
    default: null,
  },
  keyOnly: {
    type: Boolean,
    required: false,
    default: true,
  },
  id: {
    type: String,
    required: false,
  },
});
