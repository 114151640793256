'use strict';

function ifDefElseTrue(v) {
  if (!!v || v === false) {
    return v === true;
  } else {
    return true;
  }
}

class KeycloakUser {
  /**
   * @constructor
   */
  constructor(o = null) {
    this.username = o?.username || null;
    this.email = o?.email || null;
    this.firstName = o?.firstName || null;
    this.lastName = o?.lastName || null;
    this.fullName = o?.fullName || null;
    this.roles = o?.roles || [];
    this.tenantId = o?.tenantId || null;
    this.tenantName = o?.tenantName || null;
    this.tenantCode = o?.tenantCode || null;
    this.chargebeeCustomerId = o?.chargebeeCustomerId || null;
    this.taxEngines = o?.taxEngines || [];
    this.rapidInsight = ifDefElseTrue(o?.rapidInsight);
    this.rapidTesting = ifDefElseTrue(o?.rapidTesting);
    this.rapidValidate = ifDefElseTrue(o?.rapidValidate);
    this.rapidVerify = ifDefElseTrue(o?.rapidVerify);
    this.pma = ifDefElseTrue(o?.pma);

    this.brandPath = o?.brandPath || o?.tenant?.brandPath || null;
    this.uiConfg = o?.ui || o?.tenant?.uiConfig || {};

    /**
     * @readonly
     */
    this.isAdmin = o?.isAdmin === true;
    /**
     * @readonly
     */
    this.isSystemAdmin = o?.isSystemAdmin === true;
  }

  /**
   * @returns {String}
   */
  get displayName() {
    if (!this.fullName) {
      return this.fullName;
    }

    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }

    return this.username;
  }

  static fromParsedToken(parsedToken) {
    let u = new KeycloakUser();
    u.email = parsedToken.email;
    u.username =
      parsedToken.preferred_username ||
      parsedToken.username ||
      parsedToken.subject;
    u.firstName = parsedToken.given_name;
    u.lastName = parsedToken.family_name;
    u.fullName = parsedToken.name;
    u.chargebeeCustomerId = parsedToken.chargebee_customer_id;
    if (parsedToken.roles && parsedToken.roles.length > 0) {
      u.roles = parsedToken.roles;
    } else {
      u.roles = (parsedToken.realm_access || {}).roles || [];
    }

    u.isSystemAdmin = !!u.roles.find((auth) => {
      return auth === 'SYSADMIN' || auth === 'SYSOP';
    });

    u.isAdmin =
      u.isSystemAdmin ||
      !!u.roles.find((auth) => {
        return auth === 'SYSADMIN' || auth === 'SYSOP' || auth === 'ADMIN';
      });

    u.tenantId = parsedToken.tenant?.id || parsedToken.tenant_id;
    u.tenantName = parsedToken.tenant?.name || parsedToken.tenant_name;
    u.tenantCode =
      parsedToken.tenant?.code ||
      parsedToken.tenant_code ||
      parsedToken.tenant_id;
    u.taxEngines =
      parsedToken.tax_engines ||
      parsedToken.licenses?.taxengines ||
      parsedToken.licenses?.taxEngines ||
      parsedToken.licenses?.tax_engines ||
      [];
    u.rapidInsight =
      (parsedToken.licenses?.rapidinsight ||
        parsedToken.licenses?.rapidInsight ||
        parsedToken.licenses?.rapid_insight) === true;
    u.rapidTesting =
      (parsedToken.licenses?.rapidtesting ||
        parsedToken.licenses?.rapidTesting ||
        parsedToken.licenses?.rapid_testing) === true;
    u.rapidValidate =
      (parsedToken.licenses?.rapidvalidate ||
        parsedToken.licenses?.rapidValidate ||
        parsedToken.licenses?.rapid_validate) === true;
    u.rapidVerify =
      (parsedToken.licenses?.rapidverify ||
        parsedToken.licenses?.rapidVerify ||
        parsedToken.licenses?.rapid_verify) === true;

    u.pma = parsedToken.licenses?.pma === true;

    u.brandPath =
      parsedToken.brandPath ||
      parsedToken.brand_path ||
      parsedToken.tenant?.brandPath ||
      parsedToken.tenant?.brand_path;
    u.uiConfig = parsedToken.tenant?.uiConfig || parsedToken.uiConfig;

    return u;
  }
}

export default KeycloakUser;
