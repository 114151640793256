// Views
const RapidInsightForm = () => import('@/views/rapid-insight/RapidInsightForm');
import store from '@/store';
export default {
  children: [
    {
      path: 'rapid-insight',
      name: 'Rapid Insight',
      component: RapidInsightForm,
      beforeEnter: (_to, _from, next) => {
        if (!store.state.auth.rapidInsight) {
          next({
            name: 'Dashboard',
          });
        } else {
          next();
        }
      },
    },
  ],
};
