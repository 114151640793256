<template>
  <DownloadLink
    :id="`btn-download-test-result-${id || testRunId}`"
    class="btn btn-crd btn-edit"
    :class="buttonClasses"
    :href="`/api/testing/results/${testRunId}/download`"
    :disabled="downloadSuccess"
    :title="downloadError ? 'Retry Download' : 'Download Test Results'"
    @downloaded="handleDownloaded"
    @error="
      () => {
        downloadError = true;
        downloadSuccess = false;
      }
    "
  >
    <template #content-prepend="{ downloading }">
      <span
        v-show="downloading"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </template>
    <template #default="{ downloading }">
      <CIcon v-show="!downloading" :name="iconName" />
    </template>
  </DownloadLink>
</template>
<script>
export default {
  name: 'MTestResultDownloadButton',
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    testRunId: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'sm',
    },
    variant: {
      type: String,
      default: 'outline',
    },
  },
  data() {
    return {
      downloadSuccess: false,
      downloadError: false,
    };
  },
  computed: {
    isDownloadAttempted() {
      return this.downloadSuccess || this.downloadError;
    },
    iconName() {
      if (this.downloadSuccess) {
        return 'cis-check-alt';
      } else if (this.downloadError) {
        return 'cil-exclamation-circle';
      } else {
        return 'cis-cloud-download';
      }
    },
    buttonClasses() {
      let classes = {};
      if (this.size) {
        classes[`btn-${this.size}`] = true;
      }

      let color = 'success';
      if (this.downloadError) {
        color = 'danger';
      }

      if (!this.variant) {
        classes[`btn-outline-${color}`] = true;
      } else {
        classes[`btn-${this.variant}-${color}`] = true;
      }

      return classes;
    },
  },
  methods: {
    handleDownloaded() {
      const self = this;
      this.downloadError = false;
      this.downloadSuccess = true;
      setTimeout(() => {
        try {
          if (self && self.downloadSuccess) {
            self.downloadSuccess = false;
          }
        } catch (err) {
          // ignore
        }
      }, 3500);
    },
  },
};
</script>
