<template>
  <CCard>
    <CCardHeader>
      <CRow>
        <CCol>
          <slot name="card-title"
            ><h3>{{ title }}</h3></slot
          >
        </CCol>
        <CCol class="text-right">
          <slot name="card-controls">
            <slot name="custom-header-action"></slot>
            <CDropdown
              v-if="dropdownEnabled"
              :show.sync="dropdownShow"
              size="sm"
              :split="true"
            >
              <template #toggler>
                <MAddButton v-if="!addDisabled" @click="handleEntryCreate" />
                <button
                  class="btn btn-sm btn-link dropdown-toggle dropdown-toggle-split"
                  @click="() => (dropdownShow = !dropdownShow)"
                />
              </template>
              <CDropdownItem
                v-if="!importDisabled"
                @click="
                  () => {
                    $emit('import');
                  }
                "
                ><CIcon name="cis-cloud-upload" class="mr-2" />
                Import
              </CDropdownItem>
              <CDropdownItem
                v-if="!exportDisabled"
                :disabled="items.length < 1"
                @click="
                  () => {
                    $emit('export');
                  }
                "
                ><CIcon name="cis-cloud-download" class="mr-2" />
                Export
              </CDropdownItem>
            </CDropdown>
            <MAddButton v-else-if="!addDisabled" @click="handleEntryCreate" />
          </slot>
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <MDataTable
        :items="items || []"
        :fields="fields"
        hover
        striped
        sorter
        :pagination="pagination"
        :per-page="perPage"
        :table-filter="tableFilter"
        :table-filter-value.sync="dataTableFilterValue"
        :column-filter="columnFilter"
        :hide-controls="hideControls"
        cleaner
        :loading="loading"
      >
        <template v-for="(_, slot) of $scopedSlots" #[slot]="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>

        <template #actions="{ item }">
          <MDeleteButton
            class="mr-1"
            variant="outline"
            @click="handleEntryDelete(item)"
          />
          <MEditButton
            class="mr-1"
            variant="outline"
            @click="handleEntryEdit(item)"
          />
          <slot name="custom-actions" :item="item"></slot>
        </template>
        <template v-if="$scopedSlots['no-items-view']">
          <slot name="no-items-view" />
        </template>
      </MDataTable>
    </CCardBody>
  </CCard>
</template>
<script>
export default {
  name: 'EntityDataTableCard',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    fields: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    items: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    importDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    exportDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    addDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    columnFilter: Boolean,
    pagination: {
      type: Boolean,
      required: false,
      default: true,
    },
    perPage: {
      type: Number,
      required: false,
      default: 25,
    },
    tableFilter: {
      type: [Boolean, Object],
      required: false,
      default() {
        return true;
      },
    },
    tableFilterValue: {
      type: [String, Object, Array],
      required: false,
      default() {
        return null;
      },
    },
    hideControls: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dropdownShow: false,
      tableFilterValueState: this.tableFilterValue,
    };
  },
  computed: {
    dropdownEnabled() {
      return !(this.importDisabled && this.exportDisabled);
    },
    hasActionSlot() {
      return !!this.$scopedSlots['actions'];
    },
    rawFieldNames() {
      return this.fields.map((f) => f.key);
    },
    dataTableFilterValue: {
      set(v) {
        this.tableFilterValueState = v;
        this.$emit('update:table-filter-value', v);
      },
      get() {
        return this.tableFilterValueState;
      },
    },
  },
  watch: {
    tableFilterValue(v) {
      this.tableFilterValueState = v;
    },
  },
  methods: {
    handleEntryEdit(item) {
      this.$emit('edit', item);
    },
    handleEntryDelete(item) {
      this.$emit('delete', item);
    },
    handleEntryCreate() {
      this.$emit('create');
    },
  },
};
</script>
