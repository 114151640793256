import geo from './geos-api';
import tests from './tests';
import connectivity from './connectivity';
import testing from './testing';
import files from './files';
import rapidInsight from './rapid-insight';
import registrations from './registration-api';
export default {
  connectivity,
  geo,
  tests,
  testing,
  files,
  rapidInsight,
  registrations,
};
