import { get, set, setArray } from './state-mutators';
import api from '@/api';
const state = {
  countries: [],
  extendedCountries: [],
  loadingCountries: false,
};

const getters = {
  countries: get('countries'),
  loadingCountries: get('loadingCountries'),
  extendedCountries: get('extendedCountries'),
};

const mutations = {
  countries: setArray('countries'),
  extendedCountries: setArray('extendedCountries'),
  loadingCountries: set('loadingCountries'),
};

const actions = {
  getOrFetchCountries({ state, commit }) {
    if (state.countries?.length > 0) {
      return Promise.resolve(state.countries);
    } else {
      commit('loadingCountries', true);
      return api.registrations
        .getSupportedCountries()
        .then((data) => {
          commit('countries', data);
          return data;
        })
        .finally(() => {
          commit('loadingCountries', false);
        });
    }
  },
  getOrFetchExtendedCountries({ state, commit }) {
    if (state.extendedCountries?.length > 0) {
      return Promise.resolve(state.extendedCountries);
    } else {
      commit('loadingCountries', true);
      return api.registrations
        .getExtendedValidationCountries()
        .then((data) => {
          commit('extendedCountries', data);
          return data;
        })
        .finally(() => {
          commit('loadingCountries', false);
        });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
