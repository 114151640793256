<template>
  <div class="alert-container">
    <CAlert :show.sync="showToast" :color="toastColor" close-button>
      <slot> {{ computedMessage }} </slot>
    </CAlert>
  </div>
</template>
<script>
import { isBlank } from '@/utils/helpers';

export default {
  name: 'ModalToast',
  props: {
    entityTitle: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: false,
      default: null,
    },
    isDanger: {
      type: Boolean,
      required: false,
      default: false,
    },
    show: {
      type: [Boolean, Number],
      required: false,
      default: false,
    },
  },
  computed: {
    showToast: {
      get() {
        return this.show;
      },
      set(v) {
        this.$emit('update:show', v);
      },
    },
    computedMessage() {
      if (isBlank(this.message)) {
        return this.$t('modal.create.success', { type: this.entityTitle });
      } else {
        return this.message;
      }
    },
    toastColor() {
      if (this.isDanger) {
        return 'danger';
      } else {
        return 'success';
      }
    },
  },
};
</script>
