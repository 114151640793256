<template>
  <CBadge :color="badgeColor">
    {{ label }}
  </CBadge>
</template>
<script>
export default {
  name: 'TaxEngineBadge',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ...this.getValues(this.value),
    };
  },
  watch: {
    value(v) {
      const parsed = this.getValues(v);
      this.badgeColor = parsed.badgeColor;
      this.label = parsed.label;
    },
  },
  methods: {
    getValues(v) {
      let label = 'Unknown';
      let badgeColor = 'secondary';
      if (v) {
        label = this.$t(`taxEngineType.${v.toUpperCase()}`);
        if (label == `taxEngineType.${v.toUpperCase()}`) {
          label = v;
        }

        switch (v.toUpperCase()) {
          case 'AVA':
            badgeColor = 'success';
            break;
          case 'VTX':
            badgeColor = 'primary';
            break;
          case 'SBX':
            badgeColor = 'info';
            break;
          default:
            badgeColor = 'secondary';
            break;
        }
      }

      return {
        label,
        badgeColor,
      };
    },
  },
};
</script>
