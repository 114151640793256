<template>
  <span class="m-check-span" :class="{ enabled: value, disabled: !value }">
    <CIcon
      v-if="value || showFalse"
      :name="value ? 'cis-check-alt' : 'cis-x'"
      :class="{
        'text-success': value,
      }"
    />
  </span>
</template>
<script>
export default {
  name: 'MCheckIcon',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: false,
    },
    showFalse: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>
<style lang="scss">
.m-check-span {
  &.disabled {
    color: #8a93a2;
  }
}
</style>
