import { logger } from './logger';
import {
  VUE_APP_TENANT_API_PATH,
  VUE_APP_KC_IDP_HINT,
  VUE_APP_JWT_AUTH_REALM,
} from '@/EnvVars';

export async function authServerUp(url) {
  return await fetch(url, {
    mode: 'no-cors',
  });
}

export async function getTenantAuthProviders(useTenantApi) {
  if (!useTenantApi) {
    logger.debug('Using tenant API is disabled');
    if (!!VUE_APP_KC_IDP_HINT && !!VUE_APP_JWT_AUTH_REALM) {
      return Promise.resolve([
        {
          authType: 'OAUTH2',
          authData: {
            realm: VUE_APP_JWT_AUTH_REALM,
            params: {
              kc_idp_hint: VUE_APP_KC_IDP_HINT,
            },
          },
        },
      ]);
    }

    return Promise.resolve([]);
  }

  return await fetch(VUE_APP_TENANT_API_PATH, {
    mode: 'no-cors',
  })
    .then((res) => {
      return res.json() || [];
    })
    .catch((err) => {
      logger.error('Error checking for auth providers', err);
      return [];
    });
}
