import _ from 'lodash';

export const makeUid = () => {
  const key = Math.random().toString(36).substring(2);
  return 'uid-' + key;
};

export function trimValue(obj, key) {
  let value = _.get(obj, key);
  if (_.isNumber(value)) {
    return value;
  } else if (_.isObjectLike(value)) {
    value = JSON.stringify(value);
  }

  return _.trim(_.toString(value));
}

export const tableSizes = ['comfortable', 'standard', 'dense'];
export const defaultPageSizes = [5, 10, 25, 50];

export function valueAsBoolean(value) {
  if (value === true || value === false) {
    return value;
  }

  switch (_.lowerCase(_.toString(value))) {
    case 'y':
    case 'yes':
    case 'true':
    case '1':
      return true;
    default:
      return false;
  }
}

export function booleanFilter() {
  return (filterValue, row, key) => {
    return valueAsBoolean(filterValue) === valueAsBoolean(_.get(row, key));
  };
}

export function geoFilter() {
  return (filterValue, row, key) => {
    const v = _.lowerCase(_.trim(filterValue));
    if (!v) {
      return false;
    }

    const geo = _.get(row, key);
    if (!geo) {
      return false;
    }

    const name = _.lowerCase(_.get(geo, 'shortName', ''));
    if (name.includes(v)) {
      return true;
    }

    const display = _.lowerCase(_.get(geo, 'displayName', ''));
    if (display.includes(v)) {
      return true;
    }

    const geoKey = _.lowerCase(_.get(geo, 'geoKey'));
    if (geoKey.includes(v)) {
      return true;
    }

    return false;
  };
}
export function stringFilter() {
  return (filterValue, row, key) => {
    const v = _.lowerCase(_.trim(filterValue));
    if (!v || v === '') {
      return true;
    }
    const rawValue = _.lowerCase(_.trim(_.toString(_.get(row, key))));
    if (!rawValue || rawValue === '') {
      return false;
    }
    return rawValue.includes(v);
  };
}

export function dateFilter() {
  // todo:
  return (filterValue, row, key) => {
    const v = _.lowerCase(_.trim(filterValue));
    if (!v || v === '') {
      return true;
    }
    const rawValue = _.lowerCase(_.trim(_.toString(_.get(row, key))));
    if (!rawValue || rawValue === '') {
      return false;
    }
    return rawValue.includes(v);
  };
}
