<template>
  <CFormGroup
    v-bind="{
      validFeedback,
      invalidFeedback,
      tooltipFeedback,
      description,
      class: computedClasses,
    }"
  >
    <template #input>
      <input
        v-bind="$attrs"
        :id="safeId"
        ref="internalCheckbox"
        type="checkbox"
        :class="inputClasses"
        :checked="state === true"
        v-on="$listeners"
        @change="onChange($event)"
      />
    </template>

    <template #label-after-input>
      <slot name="label">
        <label v-if="label || custom" :for="safeId" :class="labelClasses">
          {{ label }}
        </label>
      </slot>
    </template>

    <template
      v-for="slot in ['valid-feedback', 'invalid-feedback', 'description']"
      #[slot]
    >
      <slot :name="slot"> </slot>
    </template>
  </CFormGroup>
</template>
<script>
/**
 * This is basically a copy of CInputCheckBox except for it supports indeterminate
 * state of checkboxes. Mostly used for a toggle all type function.
 */

const makeUid = () => {
  const key = Math.random().toString(36).substr(2);
  return 'uid-' + key;
};
export default {
  name: 'MInputCheckbox',
  inheritAttrs: false,
  props: {
    validFeedback: {
      type: String,
      required: false,
      default: null,
    },
    invalidFeedback: {
      type: String,
      required: false,
      default: null,
    },
    tooltipFeedback: Boolean,
    description: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default() {
        return ' ';
      },
    },
    wasValidated: Boolean,
    isValid: {
      type: [Boolean, Function],
      default: null,
    },
    addInputClasses: {
      type: [String, Array, Object],
      default() {
        return null;
      },
    },
    addLabelClasses: {
      type: [String, Array, Object],
      default() {
        return null;
      },
    },
    // eslint-disable-next-line vue/require-default-prop
    inputId: String,
    // eslint-disable-next-line vue/require-default-prop
    checked: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    custom: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    inline: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    indeterminate: Boolean,
  },
  data() {
    return {
      state: this.getStateValue(this.checked),
    };
  },
  computed: {
    safeId() {
      if (this.inputId || this.$attrs.inputId) {
        return this.inputId;
      }

      if (this.id || this.$attrs.id) {
        return this.id || this.$attrs.id;
      }
      return makeUid();
    },
    computedClasses() {
      const controlClass = this.custom ? 'custom-control' : 'form-check';
      return {
        [`${controlClass}`]: true,
        [`${controlClass}-inline`]: this.inline,
        [`custom-${this.customType}`]: this.custom,
        'was-validated': this.wasValidated,
      };
    },

    labelClasses() {
      return [
        this.addLabelClasses,
        this.custom ? 'custom-control-label' : 'form-check-label',
      ];
    },

    inputClasses() {
      return [
        this.custom ? 'custom-control-input' : 'form-check-input',
        this.validationClass,
        this.addInputClasses,
      ];
    },
  },
  watch: {
    checked: {
      immediate: true,
      handler(val) {
        const stateValue = this.getStateValue(val);
        this.state = stateValue;
        if (this.$refs['internalCheckbox']) {
          if (this.indeterminate && stateValue === null) {
            this.$refs['internalCheckbox'].indeterminate = true;
          } else {
            this.$refs['internalCheckbox'].indeterminate = false;
          }
        }
      },
    },
  },
  mounted() {
    if (this.indeterminate && this.state === null) {
      if (this.$refs['internalCheckbox']) {
        this.$refs['internalCheckbox'].indeterminate = true;
      }
    }
  },
  methods: {
    getStateValue(val) {
      if (this.indeterminate && val !== true && val !== false) {
        return null;
      } else {
        return val === true;
      }
    },
    onChange(e) {
      this.state = e.target.checked;
      this.$emit('update:checked', e.target.checked, e);
      this.$emit('change', e.target.checked);
      if (this.$refs['internalCheckbox']) {
        this.$refs['internalCheckbox'].indeterminate = false;
      }
    },
  },
};
</script>
