// Views
import store from '@/store';
const TestingDashboard = () => import('@/views/testing/TestingDashboard');
const TestDeckReview = () => import('@/views/testing/decks/TestDeckReview');
const TestResultsReview = () =>
  import('@/views/testing/results/TestResultsReview');

const routeGuard = (_to, _from, next) => {
  if (!store.state.auth.rapidTesting) {
    next({
      name: 'Dashboard',
    });
  } else {
    next();
  }
};

export default {
  children: [
    {
      path: 'testing',
      name: 'Testing',
      component: TestingDashboard,
      beforeEnter: routeGuard,
    },
    {
      path: 'testing/results/:testRunId',
      name: 'TestResults',
      props: true,
      component: TestResultsReview,
      beforeEnter: routeGuard,
    },
    {
      path: 'testing/deck/:testDeckId',
      name: 'TestDeckDetails',
      props: true,
      component: TestDeckReview,
      beforeEnter: routeGuard,
    },
  ],
};
