<template>
  <MSelect
    :id="id"
    :placeholder="hasGeos ? placeholder : 'No Results'"
    :label="label"
    filterable
    :clearable="!required"
    :loading="loading"
    :options="geoOptions"
    :value.sync="selectedValue"
    :invalid-feedback="invalidFeedback"
    :is-valid="isValid"
    :required="required"
    :readonly="readonly"
    :disabled="disabled || !hasGeos"
  />
</template>
<script>
import api from '@/api';
export default {
  name: 'MTaxableDivisionPicker',
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    invalidFeedback: {
      type: String,
      required: false,
      default: null,
    },
    isValid: {
      type: Boolean,
      required: false,
      default: null,
    },
    value: {
      type: [String, Object],
      required: false,
      default() {
        return null;
      },
    },
    entries: {
      type: Array,
      required: false,
      default: null,
    },
    country: {
      type: [String, Object],
      required: false,
      default() {
        return null;
      },
    },
    // When true, show the ISO code before the display name.
    showCode: {
      type: Boolean,
      required: false,
      default: false,
    },
    // When true, only return the geo key instead of the whole object.
    keyOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select a Division',
    },
    label: {
      type: String,
      default: 'Division',
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      hasLoaded: false,
      geoList: [],
      internalValue: this.getGeoKey(this.value),
      countryCode: this.getGeoKey(this.country),
      hasGeos: false,
      originalValue: this.value,
    };
  },
  computed: {
    geoOptions() {
      if (this.disabled === true) {
        return [];
      }

      return this.geoList.map((g) => {
        return Object.freeze({
          id: g.key,
          value: g,
          label: this.makeLabel(g),
        });
      });
    },
    selectedValue: {
      get() {
        return this.internalValue;
      },
      set(v) {
        if (!this.loading && this.hasGeos) {
          if (!v) {
            this.internalValue = null;
            this.$emit('update:value', null);
          } else {
            this.internalValue = v;
            this.$emit('update:value', this.keyOnly ? v.id : v);
          }
        }
      },
    },
  },
  watch: {
    value(v) {
      const geoKey = this.getGeoKey(v);
      if (this.hasGeos) {
        this.internalValue = (this.geoOptions || []).find((o) => {
          return o.id === geoKey;
        });
      } else {
        this.internalValue = geoKey;
      }
    },
    country(v) {
      const newCode = this.getGeoKey(v);
      if (newCode !== this.countryCode) {
        this.selectedValue = null;
        this.countryCode = newCode;
        this.fetchGeos(newCode);
      }
    },
  },
  beforeMount() {
    if (this.countryCode) {
      this.fetchGeos(this.countryCode);
    }
  },
  mounted() {
    this.inputId = this.id ? this.id : `geo_sub_picker_${this._uid}`;
    if (this.value && !this.internalValue) {
      this.internalValue = this.getGeo(this.value);
    }
  },
  methods: {
    makeLabel(v) {
      if (this.showCode === true) {
        return `${v.geoKey} - ${v.displayName}`;
      } else {
        return v.displayName;
      }
    },
    getGeoKey(v) {
      if (!v) {
        return null;
      }

      return v.geoKey || this.$entities.idOrKey(v);
    },
    getGeo(v, options = null) {
      if (!v) {
        return null;
      }
      const geoKey = this.getGeoKey(v);

      if (!geoKey) {
        return null;
      }

      let entries = !options ? this.geoList : options;
      return (entries || []).find((c) => {
        return (
          c.id === geoKey ||
          c.key === geoKey ||
          c.geoKey === geoKey ||
          c.value.geoKey === geoKey
        );
      });
    },
    fetchGeos(v) {
      if (this.entries != null) {
        this.geoList = this.entries.map((o) => Object.freeze(o));
        this.hasLoaded = true;
        this.loading = false;
        return Promise.resolve(this.entries);
      }

      if (!v) {
        this.geoList = [];
        this.hasGeos = false;
        this.hasLoaded = true;
        this.loading = false;
        return Promise.resolve([]);
      }

      const self = this;
      this.loading = true;
      this.hasLoaded = false;
      return api.geo
        .getTaxableChildrenOf(v)
        .then((results) => {
          self.geoList = results.map((r) => Object.freeze(r));

          const hasGeos = results.length > 0;
          self.hasGeos = hasGeos;

          if (!hasGeos) {
            self.$emit('emptyResults', v);
          } else if (self.value) {
            self.internalValue = self.getGeo(
              self.value,
              results.map((o) => {
                return Object.freeze({
                  id: o.key,
                  value: o,
                  label: self.makeLabel(o),
                });
              }),
            );
          }

          return results;
        })
        .finally(() => {
          self.$nextTick(() => {
            self.hasLoaded = true;
            self.loading = false;
          });
        });
    },
  },
};
</script>
