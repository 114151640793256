import {
  cidBuildingBusiness,
  cidExclamationCircle,
  cidPlusCircle,
  cidHamburgerMenu,
  cilExclamationCircle,
  cilExclamation,
  cilFileArchive,
  cilFileExcel,
  cisMediaStopCircle,
  cilTimer,
} from '@coreui/icons-pro';

import {
  cilMinus,
  cilMenu,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowLeft,
  cilArrowRight,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilClipboard,
  cilCloudDownload,
  cilCloudUpload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCopy,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDescription,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEthernet,
  cilEuro,
  cilFilter,
  cilFilterX,
  cilFolder,
  cilGlobeAlt,
  cilGrid,
  cilGroup,
  cilHamburgerMenu,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilReload,
  cilSave,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilXCircle,
  cilWarning,
} from '@coreui/icons';

import {
  cilBeaker,
  cilDocument,
  cilInfoCircle,
  cilInputComponent,
  cilGrip,
  cilGripLines,
  cilMoveVertical,
} from '@coreui/icons-pro';

import {
  cisArrowBottom,
  cisArrowLeft,
  cisArrowRight,
  cisArrowTop,
  cisBan,
  cisClock,
  cisChartPie,
  cisCheckAlt,
  cisCheckCircle,
  cisCheckDouble,
  cisChevronBottom,
  cisChevronRight,
  cisChevronLeft,
  cisCloudDownload,
  cisCloudUpload,
  cisExclamationCircle,
  cisFolderPlus,
  cisInboxIn,
  cisLibraryAdd,
  cisLocationPin,
  cisLockLocked,
  cisPencil,
  cisReport,
  cisSettingsAlt,
  cisStar,
  cisTrashAlt,
  cisWarning,
  cisX,
  cisTimer,
  cisBuildingBusiness,
} from '@coreui/icons-pro';
import { cisArrowCircleRight } from '@coreui/icons-pro/js/solid';

export const iconsSet = Object.assign(
  {
    cidPlusCircle,
    cidExclamationCircle,
    cidBuildingBusiness,
    cidHamburgerMenu,
    cisBuildingBusiness,
  },
  {},
  {
    cilMinus,
    cilMenu,
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilArrowLeft,
    cilArrowRight,
    cilBan,
    cilBasket,
    cilBeaker,
    cilBell,
    cilBold,
    cilBookmark,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilCloudUpload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCircle,
    cilCheckCircle,
    cilClipboard,
    cilCode,
    cilCommentSquare,
    cilCopy,
    cilCreditCard,
    cilCursor,
    cilCursorMove,
    cilDescription,
    cilDocument,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEthernet,
    cilEuro,
    cilExclamation,
    cilExclamationCircle,
    cilFolder,
    cilGlobeAlt,
    cilGrid,
    cilGroup,
    cilHamburgerMenu,
    cilInfoCircle,
    cilInputComponent,
    cilFile,
    cilFileArchive,
    cilFileExcel,
    cilFilter,
    cilFilterX,
    cilFullscreen,
    cilFullscreenExit,
    cilGrip,
    cilGripLines,
    cilHome,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilInputPower,
    cilItalic,
    cilJustifyCenter,
    cilJustifyLeft,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilListRich,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMap,
    cilMoon,
    cilMoveVertical,
    cilNotes,
    cilOptions,
    cilPaperclip,
    cilPaperPlane,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPrint,
    cilPuzzle,
    cilReload,
    cilSave,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
    cilX,
    cilXCircle,
    cilWarning,
    cilTimer,
  },
  {
    // cif
  },
  {
    // cib
  },
  {
    cisArrowBottom,
    cisArrowLeft,
    cisArrowRight,
    cisArrowCircleRight,
    cisArrowTop,
    cisBan,
    cisClock,
    cisChartPie,
    cisCheckAlt,
    cisCheckCircle,
    cisCheckDouble,
    cisChevronBottom,
    cisChevronRight,
    cisChevronLeft,
    cisCloudDownload,
    cisCloudUpload,
    cisExclamationCircle,
    cisFolderPlus,
    cisInboxIn,
    cisLibraryAdd,
    cisLockLocked,
    cisMediaStopCircle,
    cisPencil,
    cisReport,
    cisSettingsAlt,
    cisStar,
    cisTrashAlt,
    cisWarning,
    cisX,
    cisLocationPin,
    cisTimer,
  },
);
