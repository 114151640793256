<template>
  <div :class="wrapperClasses">
    <CToggler
      :in-header="inHeader"
      @click.prevent="
        () => {
          opened = !opened;
        }
      "
    >
      <CIcon :name="opened ? 'cis-chevron-bottom' : 'cis-chevron-left'" />
    </CToggler>
  </div>
</template>
<script>
export default {
  name: 'MCollapseToggler',
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    inHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      openedState: this.value === true,
    };
  },
  computed: {
    wrapperClasses() {
      return {
        'm-collapse-toggler': true,
        'in-header': this.inHeader,
        'is-open': this.opened,
        'is-closed': !this.opened,
      };
    },
    opened: {
      get() {
        return this.openedState;
      },
      set(v) {
        const isOpen = v === true;
        this.openedState = isOpen;
        this.$emit('update:value', isOpen);
        if (!isOpen) {
          this.$emit('close');
        } else {
          this.$emit('open');
        }
      },
    },
  },
  watch: {
    value(v) {
      this.openedState = v === true;
    },
  },
};
</script>
<style lang="scss">
.m-collapse-toggler {
  &.in-header {
    margin-left: auto;
    margin-right: 0;
  }
}
</style>
