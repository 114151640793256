const RegistrationsDashboard = () =>
  import('@/views/rapid-verify/RegistrationValidationDashboard');
const RegistrationsBatchDetails = () =>
  import('@/views/rapid-verify/batch/RegistrationBatchDetails');
export default {
  children: [
    {
      path: 'rapid-verify',
      name: 'RapidVerifyDashboard',
      component: RegistrationsDashboard,
    },
    {
      path: 'rapid-verify/registrations/:batchId',
      name: 'RegistrationValidationBatchDetails',
      component: RegistrationsBatchDetails,
      props: true,
    },
  ],
};
