import config from './config';

const client = config.client;
const baseUrl = config.baseUrl;
const baseGeoUrl = `${baseUrl}/geo`;

export default {
  getCountries() {
    return client.get(`${baseGeoUrl}/countries`).then((res) => res.data);
  },
  getChildrenOfCountry(geoKey) {
    return client
      .get(`${baseGeoUrl}/children`, { params: { geoKey } })
      .then((res) => res.data);
  },
  getCurrencies() {
    return client.get(`${baseGeoUrl}/currencies`).then((res) => res.data);
  },
};
