<template>
  <a
    ref="thisAnchor"
    :class="linkClasses"
    v-bind="anchorProps"
    :data-downloaded="hasDownloaded"
    :data-downloaded-filename="downloadedFilename"
    @click.prevent="handleDownload"
  >
    <slot name="content-prepend" :downloading="isDownloading">
      <span
        v-show="isDownloading"
        class="spinner-border spinner-border-sm mr-2"
        role="status"
        aria-hidden="true"
      >
      </span>
    </slot>
    <slot :downloading="isDownloading"> </slot>
    <slot name="content-append" :downloading="isDownloading"></slot>
  </a>
</template>
<script>
import api from '@/api';
export default {
  name: 'DownloadLink',
  inheritAttrs: false,
  props: {
    disabled: Boolean,
    id: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    href: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isDownloading: false,
      hasDownloaded: false,
      downloadedFilename: null,
    };
  },
  computed: {
    anchorProps() {
      let p = {
        href: this.href,
      };
      if (this.title) {
        p['title'] = this.title;
      }
      if (this.id) {
        p['id'] = this.id;
      }
      return p;
    },
    linkClasses() {
      let classes = {
        disabled: this.disabled,
        downloading: this.isDownloading,
      };

      return classes;
    },
  },
  methods: {
    handleDownload() {
      if (this.isDownloading || this.disabled) {
        return;
      }

      this.isDownloading = true;
      const self = this;
      api.files
        .download(this.href)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.contentType,
          });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;

          link.setAttribute('download', res.filename);
          document.body.appendChild(link);

          link.click();
          this.$emit('downloaded', res.filename);
          link.remove();
          self.hasDownloaded = true;
          self.downloadedFilename = res.filename;
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.$log.error(`Error downloading file at '${this.href}'`, err);
          this.$emit('error', err);
        })
        .finally(() => {
          this.$nextTick(() => {
            this.isDownloading = false;
          });
        });
    },
  },
};
</script>
