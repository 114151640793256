<template>
  <CButtonGroup size="sm" role="group" aria-label="Table Density">
    <CButton
      :disabled="disabled"
      color="control"
      variant="outline"
      :pressed="internalValue === 'comfortable'"
      arial-label="comfortable table density selector"
      title="Comfortable"
      @click="select('comfortable')"
    >
      <CIcon name="cil-minus" />
    </CButton>
    <CButton
      color="control"
      variant="outline"
      :disabled="disabled"
      :pressed="internalValue === 'standard'"
      arial-label="standard table density selector"
      title="Standard"
      @click="select('standard')"
    >
      <CIcon name="cil-grip-lines" />
    </CButton>
    <CButton
      color="control"
      :disabled="disabled"
      :pressed="internalValue === 'dense'"
      arial-label="dense table density selector"
      variant="outline"
      title="Dense"
      @click="select('dense')"
    >
      <CIcon name="cil-menu" />
    </CButton>
    <slot></slot>
  </CButtonGroup>
</template>
<script>
import { densityProps as props } from './props';

export default {
  name: 'MTableDensityPicker',
  props,
  data() {
    return {
      internalValue: this.density || 'standard',
    };
  },
  watch: {
    value(v) {
      if (!v) {
        this.internalValue = 'standard';
      } else {
        switch (v.toLowerCase()) {
          case 'comfortable':
            this.internalValue = 'comfortable';
            break;
          case 'dense':
            this.internalValue = 'dense';
            break;
          default:
            this.internalValue = 'standard';
            break;
        }
      }
    },
  },
  methods: {
    select(value) {
      if (this.internalValue !== value) {
        this.internalValue = value;
        this.$emit('update:density', value);
      }
    },
  },
};
</script>
