<template>
  <CButton
    class="btn-edit btn-crd"
    v-bind="cButtonProps"
    @click="(e) => $emit('click', e)"
  >
    <slot><CIcon name="cis-pencil" /></slot>
  </CButton>
</template>
<script>
import { mButtonsMixin } from './mbuttons-mixin';
export default {
  name: 'MEditButton',
  mixins: [mButtonsMixin],
  computed: {
    defaultProps() {
      return {
        variant: 'ghost',
        color: 'dark',
        alt: 'Edit Button',
        title: 'Edit',
      };
    },
  },
};
</script>
<style lang="scss">
.btn-crd.btn-edit {
  &.btn-outline-light {
    border-color: #636f83;
    color: #636f83;
  }
}

.card-header {
  .btn-crd.btn-edit {
    &.btn-outline-dark:hover,
    &.btn-ghost-dark:hover {
      background-color: #ebedef;
      color: #636f83;
    }
    &.btn-ghost-dark:hover {
      border-color: #ebedef;
    }
  }
}
</style>
