import _ from 'lodash';
import { logger } from '@/utils/logger';

/**
 * Reusable generic property getter
 * @param {String} propName The property name to set.
 * @returns {*} The value of state[propName].
 */
export const get = (propName) => (state) => {
  return _.get(state, propName);
};

export const set = (prop) => (state, val) => {
  _.set(state, prop, val);
};

export const toggle = (prop) => (state) => {
  _.set(state, prop, !_.get(state, prop, false));
};

export const pushTo = (prop) => (state, val) => {
  _.get(state, prop, []).push(val);
};

// Useful for resetting state after logout
export const assignConstant = (data) => (state) => {
  Object.assign(state, data);
};

export const omitFromList = (prop) => (state, item) => {
  let arr = _.get(state, prop, []);
  const index = arr.indexOf(item);
  if (index > -1) {
    state[prop] = arr.splice(index, 1);
  }
};

export const setArray = (prop) => (state, values) => {
  if (!values || _.isEmpty(values)) {
    state[prop] = [];
    return;
  }

  if (!_.isArrayLike(values)) {
    logger.info(`Setting array prop ${prop} with a non-array value`, values);
    state[prop] = [Object.freeze(values)];
    return;
  }

  try {
    // Vue.set(state, prop, Array.of(...values.map((v) => Object.freeze(v))));
    state[prop] = values.map((o) => Object.freeze(o));
  } catch (err) {
    logger.error(`Error setting array values for property '${prop}'`, err);
    state[prop] = values;
  }
};
