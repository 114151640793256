import { get, set } from './state-mutators';
import { keycloak } from '@/AxiosClient';
import KeycloakUser from '@/models/KeycloakUser';
import { logger } from '@/utils/logger';
import {
  VUE_APP_BRAND_PATH,
  VUE_APP_JWT_BRAND_ENABLED,
  VUE_APP_UI_CONFIG,
} from '@/EnvVars';
import { merge } from 'lodash';

/**
 * Parses a given Location to build a redirect logout URI.
 * @param {Location} location Location to parse.
 * @returns {string} URI to redirect to.
 */
function getLogoutUri(location) {
  const base = location.href.split('#')[0];
  let pathPrefix = '/#/';
  if (base.endsWith('/')) {
    pathPrefix = '#/';
  }
  return `${base}${pathPrefix}logged-out`;
}
/**
 * Parses a given Location to build a redirect logout URI.
 * @param {Location} location Location to parse.
 * @returns {string} URI to redirect to.
 */
function getLoginRedirectUri(location) {
  let search = location.search?.trim();
  if (!search || search === '') {
    search = '?logged_in=true';
  } else if (!search.includes('logged_in=')) {
    search = `${search}&logged_in=true`;
  }

  if (!search.startsWith('?')) {
    search = `?${search}`;
  }

  return `${location.href.split('?')[0]}${search}`.replace(
    '/logged-out',
    '/dashboard',
  );
}

function loadLocalStorageState() {
  const userString = localStorage.getItem('user');
  const uiConfigString = localStorage.getItem('uiConfig');

  if (!!userString && userString.length > 0) {
    const user = new KeycloakUser(JSON.parse(userString));
    let uiConfig = VUE_APP_UI_CONFIG;
    if (uiConfigString) {
      try {
        uiConfig = JSON.parse(uiConfigString);
      } catch (_) {
        // Don't do anything
      }
    }

    return {
      user,
      loggedIn: true,
      status: 'LOADED',
      lastActivity: new Date().getTime(),
      brandPath: VUE_APP_BRAND_PATH,
      chargebeeCustomerId: user.chargebeeCustomerId,
      tenant: {
        name: user.tenantName,
        code: user.tenantCode,
        id: user.tenantId,
      },
      taxEngines: user.taxEngines,
      rapidTesting: user.rapidTesting,
      rapidInsight: user.rapidInsight,
      rapidVerify: user.rapidVerify,
      pma: user.pma,
      uiConfig,
    };
  } else {
    return {
      user: new KeycloakUser(),
      loggedIn: false,
      status: '',
      lastActivity: 0,
      tenant: {
        name: null,
        code: null,
        id: null,
      },
      taxEngines: [],
      rapidTesting: false,
      rapidInsight: false,
      rapidVerify: false,
      pma: false,
      brandPath: VUE_APP_BRAND_PATH,
      uiConfig: VUE_APP_UI_CONFIG,
    };
  }
}

const state = {
  ...loadLocalStorageState(),
  showIdlePrompt: false,
};

const getters = {
  brandPath: get('brandPath'),
  lastActivity: get('lastActivity'),
  showIdlePrompt: get('showIdlePrompt'),
  loggedIn: get('loggedIn'),
  getStatus: get('status'),
  user: get('user'),
  taxEngines: get('taxEngines'),
  tenantName: get('tenant.name'),
  rapidTesting: get('rapidTesting'),
  rapidInsight: get('rapidInsight'),
  rapidVerify: get('rapidVerify'),
  pma: get('pma'),
  uiConfig: get('uiConfig'),
  hasSingleTaxEngine: (state) => {
    return state.taxEngines.length < 2;
  },
  defaultTaxEngine: (state) => {
    if (state.taxEngines.length > 0) {
      return state.taxEngines[0];
    }
    return null;
  },
  isAdmin: (state) => {
    if (!state.user) {
      return false;
    }
    return state.user.isAdmin;
  },
  isSystemAdmin: (state) => {
    if (!state.user) {
      return false;
    }
    return state.user.isSystemAdmin;
  },
};

const mutations = {
  brandPath: set('brandPath'),
  showIdlePrompt: set('showIdlePrompt'),
  requestingAuth: (state) => {
    state.status = 'LOADING';
  },
  authError: (state) => {
    state.status = 'ERROR';
  },
  setLoggedIn: (state, payload) => {
    const parsedUser = KeycloakUser.fromParsedToken(payload);
    state.user = parsedUser;
    state.status = 'SUCCESS';
    state.loggedIn = true;
    state.tenant = {
      name: state.user.tenantName,
      code: state.user.tenantCode,
      id: state.user.tenantId,
    };
    let taxEngines = state.user.taxEngines;
    taxEngines.sort((a, b) => {
      if (!a && !b) {
        return 0;
      } else if (!b) {
        return -1;
      } else if (!a) {
        return 1;
      } else if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    state.taxEngines = taxEngines;
    state.rapidInsight = state?.user?.rapidInsight === true;
    state.rapidTesting = state?.user?.rapidTesting === true;
    // TODO: This is because of back and forth on rapidVerify/rapidValidate... Remove when all is settled to rapidVerify.
    state.rapidVerify =
      state?.user?.rapidVerify === true || state?.user?.rapidValidate === true;

    state.pma = state?.user?.pma === true;

    if (VUE_APP_JWT_BRAND_ENABLED) {
      state.brandPath = parsedUser.brandPath || VUE_APP_BRAND_PATH;
    }

    const uiConfig = merge({}, VUE_APP_UI_CONFIG, parsedUser?.uiConfig);
    state.uiConfig = uiConfig;

    localStorage.setItem('user', JSON.stringify(parsedUser));
    localStorage.setItem('uiConfig', JSON.stringify(uiConfig));
  },
  updateLastActivity: (state) => {
    state.lastActivity = new Date().getTime();
  },
  setLoggedOut: (state) => {
    state.user = new KeycloakUser();
    state.loggedIn = false;
    state.status = 'LOGGED_OUT';
    localStorage.removeItem('user');
    localStorage.removeItem('uiConfig');
    keycloak.logout({
      redirectUri: getLogoutUri(window.location),
    });
  },
};

const actions = {
  idlePrompt: ({ commit }, showPrompt = true) => {
    commit('showIdlePrompt', showPrompt);
  },
  updateLastActivity: ({ commit }) => {
    commit('updateLastActivity');
  },
  logout: ({ commit }) => {
    commit('setLoggedOut');
  },
  doLogin: ({ commit }) => {
    const redirectUri = getLoginRedirectUri(window.location);
    logger.debug(
      `Dispatching Keycloak login with redirect uri '${redirectUri}'`,
    );
    return keycloak
      .login({
        idpHint: keycloak.configurationProperties.options.idpHint,
        redirectUri,
      })
      .then((authenticated) => {
        if (authenticated) {
          commit('setLoggedIn', keycloak.tokenParsed);
          Promise.resolve(keycloak.token);
        } else {
          logger.error('Could not authenticate login', authenticated);
          Promise.resolve(false);
        }
      })
      .catch((err) => {
        logger.error('Error occurred performing Keycloak login', err);
        Promise.reject(err);
      });
  },
  refreshToken: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      keycloak
        .updateToken(5)
        .then((refreshed) => {
          if (refreshed || state.status !== 'SUCCESS') {
            commit('setLoggedIn', keycloak.tokenParsed);
          }
          resolve(keycloak.token);
        })
        .catch((err) => {
          // This handles app reloads where we still have a valid token but it
          // was not refreshed because it didn't need to be.
          if (!err) {
            if (state.status !== 'SUCCESS' && !!keycloak.token) {
              logger.debug('Initializing logged in state from previous token');
              commit('setLoggedIn', keycloak.tokenParsed);
            }
            resolve(keycloak.token);
          } else {
            reject(err);
          }
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
