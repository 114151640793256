import MDataTable from './MDataTable';
import MTableDensityPicker from './MTableDensityPicker';
import MTablePaginator from './MTablePaginator';

export const DATA_TABLE_FIELDS = {
  description: {
    key: 'description',
    label: 'Description',
    sorter: false,
    filter: true,
    hidden: false,
    _classes: ['description-cell'],
  },
  notes: {
    key: 'notes',
    label: 'Notes',
    sorter: false,
    filter: true,
    hidden: true,
    _classes: ['notes-cell'],
  },
  actions: {
    key: 'actions',
    label: '',
    sorter: false,
    filter: false,
    _classes: ['action-cell'],
    toggleable: false,
  },
};

export { MDataTable, MTableDensityPicker, MTablePaginator };
