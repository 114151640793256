<template>
  <transition :name="fade ? 'fade' : ''" mode="out-in">
    <div v-if="isActive">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'TabStep',
  inject: ['fade'],
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: null,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      isActive: false,
      isValidated: false,
      validationValue: null,
      index: 0,
    };
  },
  computed: {
    isValid() {
      if (this.valid === false || this.valid === true) {
        return this.valid;
      }

      if (this.isValidated) {
        return this.validationValue === true;
      }

      if (!this.validation) {
        return true;
      }

      return null;
    },
    isNotValid() {
      if (this.valid === false || this.valid === true) {
        return !this.valid;
      }

      if (this.isValidated) {
        return this.validationValue === false;
      }

      return null;
    },
  },
  created() {
    this.isActive = this.selected;
  },
  methods: {
    clearValidations() {
      this.$log.debug(
        `Clearing validations for tab '${this.title}' (${this.index})`,
      );
      this.isValidated = false;
      this.validationValue = null;
    },
    validate() {
      if (this.validation) {
        this.$log.debug(`Validating tab '${this.title}' (${this.index})`);
        const val = this.validation() === true;
        this.isValidated = true;
        this.validationValue = val;
        return val;
      } else {
        this.$log.debug(
          `No validations for tab '${this.title}' (${this.index})`,
        );
        this.isValidated = false;
        this.validationValue = null;
        return null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.fade-enter {
  display: none;
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.3s ease;
}
.fade-leave,
.fade-leave-active {
  position: absolute;
  display: none;
  opacity: 0;
}
</style>
